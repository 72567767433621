import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { NodoService, UserService, AuthenticationService, DocumentoService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';

@Component({
  selector: 'kt-materiales',
  templateUrl: './materiales.component.html',
  styleUrls: ['./materiales.component.scss']
})
export class MaterialesComponent implements OnInit {

  currentUser: any;
  loading = false;
  currentMaterial: any;
  nuevo = true;
  estados = ["PRE-INSCRITA/O","INSCRITA/O","ASIGNABLE","ASIGNADA/O"];
  estadoArray: FormArray;
  error = {estadoSelect: false};
  rows = [];
  nodos = {};
  nodo = [];
  materialForm: FormGroup;
  vistaModal = "aceptado";
  documentsHash = {};

  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('confirmationFileSwal', {static: false}) private confirmationFileSwal: SwalComponent
  @ViewChild('questionDeleteSwal', {static: false}) private questionDeleteSwal: SwalComponent
  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('confirmationDeleteSwal', {static: false}) private confirmationDeleteSwal: SwalComponent

  private unsubscribe: Subject<any>; 

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private fb: FormBuilder,
    private documentoService: DocumentoService,
    private cdr: ChangeDetectorRef,
	  private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) { 
      this.unsubscribe = new Subject();
    }

    loadMaterial() { //sin nodo
      this.documentoService.getAll()
        .subscribe(documents => {
          for(var i = 0; i < documents.length; i++) {
            documents[i].fechaObj = new Date(documents[i].fechaSubida);
            documents[i].fechaSubida = moment(new Date(documents[i].fechaSubida)).format("DD/MM/YYYY");
            this.documentsHash[documents[i].id] = true;
            // this.loadAsistencias(events[i]);
          }
          this.rows = documents;
          this.rows1 = this.rows;
          
          this.cdr.detectChanges();
        });
    }
    
    loadMaterialNodo(){
      this.documentoService.getMaterialByNodo(this.currentUser.nodo)
      .subscribe(data => {
        for(let doc of data) {
          doc.fechaObj = new Date(doc.fechaSubida);
          var fecha = doc.fechaSubida.substring(0, doc.fechaSubida.indexOf('+')) + "Z";
          doc.fechaSubida = moment(fecha).format("DD/MM/YYYY HH:mm");
          this.documentsHash[doc.id] = true;
        }
        this.rowsDoc = data;
        this.rows = this.rowsDoc;
        this.rows1 = this.rows;
        this.cdr.detectChanges();
      });
    }

    loadEstados(){
      this.estadoArray = this.materialForm.get('estadoArray') as FormArray;
      if(this.estadoArray)
        for(var i = 0; i < this.estados.length; i++){
          this.estadoArray.push(this.createItemEst(this.estados[i]));
        }
        this.cdr.detectChanges();
    }

    loadEstadosEdit(id){
      this.estadoArray = this.materialForm.get('estadoArray') as FormArray;
      if(this.estadoArray)
        for(var i = 0; i < this.estados.length; i++){
          this.estadoArray.push(this.createItemEstEdit(this.estados[i], id));
        }
        this.cdr.detectChanges();
    }

    createItemEst(estado1: any): FormGroup {
      var estadoSelect = false;     
      return this.fb.group({
        estado: estado1,
        estadoSelect: estadoSelect
      });
    }

    createItemEstEdit(estado1: any, id: any): FormGroup {
      var estadoSelect = false;
     
      for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].estado && this.rows[i].id == id && this.rows[i].estado.includes(estado1)){
        estadoSelect = true;
      }
      }
      return this.fb.group({
        estado: estado1,
        estadoSelect: estadoSelect
      });
    }


    openCentred(content) {
      this.modalService.open(content, { centered: true, size: 'lg' } ); 
    }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    if(this.currentUser.id != 0){
      this.loadMaterialNodo();
    } else this.loadMaterial();

    this.loadNodos();
  }

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  loadNodos() {
		console.log("loadNodos()");
		this.nodoService.getAll()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""]= nodos[i];
      }
      function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }
      this.nodo = nodos.sort(compare);
			this.cdr.detectChanges();
		});
	}

  openNew(content) {
    this.nuevo = true;
    this.initMaterialForm();
    this.modalService.open(content, { centered: true, size: 'lg' } ); 
  }

  submitted = false;

  initMaterialForm() {
    const reg = /^(http\:\/\/|https\:\/\/)/;
    if(!this.currentUser.nodo){
		this.materialForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      fechaSubida: [null],
      role: ['', Validators.compose([Validators.required])],
      contenido: ['', Validators.compose([Validators.required, Validators.pattern(reg)])],
      nodo: [null],      
      estadoArray: this.fb.array([ ])
		});
  } else {
      this.materialForm = this.fb.group({
        name: ['', Validators.compose([Validators.required])],
        fechaSubida: [null],
        role: ['', Validators.compose([Validators.required])],
        contenido: ['', Validators.compose([Validators.required, Validators.pattern(reg)])],
        nodo: [this.currentUser.nodo],      
        estadoArray: this.fb.array([ ])
    });
  } this.loadEstados();
}

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.materialForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }

  onSubmit() {
		// if(this.loading) return;

		this.submitted = true;
		
		const controls = this.materialForm.controls;

    var estados = [];

      var estadoArray = this.materialForm.value.estadoArray;
      var selectedEstado = false;
      estados = [];
      for(var i = 0; i < estadoArray.length; i++) {
				if(estadoArray[i].estadoSelect) {
					selectedEstado = true;
					estados.push(estadoArray[i].estado);
				}
			}
      if(!selectedEstado) {
				this.error.estadoSelect = true;
			} else {
				this.error.estadoSelect = false;
			}


		// check form
		if (this.materialForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
    
		this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
    var form = this.materialForm.value;
    
    //delete form.fecha;

		// form.nodo = currentUser.nodo;
    // form.visible = true;
    // form.edicion = currentUser.edicion;
    form.fechaSubida = new Date();
    form.fechaSubida = moment(form.fechaSubida).toDate();
    form.estado = JSON.stringify(estados);
    // form.fechaSubida = moment(form.fechaSubida).add(12, 'hours').toDate();

    // console.log(form);
		
		this.documentoService.postMaterial(form)
      .pipe(first())
      .subscribe(
        data => {
          
          this.documentsHash[data.id] = true;

          data.fechaSubida = new Date(data.fechaSubida);
          data.fechaSubida = moment(new Date(data.fechaSubida)).format("DD/MM/YYYY");
          data.estado = form.estado;
          this.rows = [...this.rows, data]; //put object data to the list rows,the same with push!
          this.confirmationFileSwal.fire();
          this.cdr.detectChanges();
          this.loading = false;
          this.modalService.dismissAll();
        },
        error => {
          console.error(error);
					this.loading = false;
        });
  }

  materialObj = null;

  edit(content,id){
    this.nuevo = false;
    for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id == id) {
        var form = this.rows[i];
        this.materialObj = form;
        const reg = /^(http\:\/\/|https\:\/\/)/;
        this.materialForm = this.fb.group({
          name: [form.name, Validators.compose([Validators.required])],
          role: [form.role, Validators.compose([Validators.required])],
          contenido: [form.contenido, Validators.compose([Validators.required, Validators.pattern(reg)])],
          nodo: [form.nodo, Validators.compose([Validators.required])],
          estadoArray: this.fb.array([ ])
        });
        this.loadEstadosEdit(id);
        this.modalService.open(content, { centered: true, size: 'lg' } );
        break;
      }
    }
  }

  editSubmit(){
    if(this.loading) return;

		this.submitted = true;
    var estados = [];

    //if(this.materialForm.value.role == "mentora"){
      var estadoArray = this.materialForm.value.estadoArray;
      var selectedEstado = false;
      estados = [];
      for(var i = 0; i < estadoArray.length; i++) {
				if(estadoArray[i].estadoSelect) {
					selectedEstado = true;
					estados.push(estadoArray[i].estado);
				}
			}
      if(!selectedEstado) {
				this.error.estadoSelect = true;
			} else {
				this.error.estadoSelect = false;
			}
		
		const controls = this.materialForm.controls;

		// check form
		if (this.materialForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		
		this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
    var form = this.materialForm.value;

    form.id = this.materialObj.id;
    form.fechaSubida = new Date();
    form.fechaSubida = moment(form.fechaSubida).toDate();
    form.estado = JSON.stringify(estados);
    
    console.log(form);
		
		this.documentoService.updateMaterial(form)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.materialObj.fechaSubida = moment(new Date(form.fechaSubida)).format("DD/MM/YYYY");
          this.materialObj.name = form.name;
          this.materialObj.role = form.role;
          this.materialObj.nodo = form.nodo;
          this.materialObj.contenido = form.contenido;
          this.materialObj.estado = form.estado;
          
          this.confirmationFileSwal.fire();
          this.cdr.detectChanges();

          console.log("COMPLETADO");
          this.loading = false;
          this.modalService.dismissAll();
        },
        error => {
          console.error(error);
					this.loading = false;
        });
    }
  

  borrar = null;

  borrarMaterial(row: any) {
    this.borrar = row;
    this.questionDeleteSwal.fire();
  }

  borrarMaterial1() {
    this.borrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.documentoService.deleteMaterial(this.borrar.id)
    .subscribe(data => {
      console.log("OK", data);
      for(var i = 0; i < this.rows.length; i++) {
        if(this.rows[i].id == this.borrar.id){
          this.rows.splice(i, 1);
          var datos = [...this.rows];
          this.rows = [];
          this.rows = datos;
          this.cdr.detectChanges();
          break;
        }
      }
      this.confirmationDeleteSwal.fire();
      this.cdr.detectChanges();
    });
  }

  // ------------------- ENLACES -------------------------

  // filesDocs: File[] = [];

  // onSelectDocs(event) {
  //   console.log(event);
  //   //this.files = [];
  //   this.filesDocs.push(...event.addedFiles);

  //   setTimeout(()=>{ 
  //     this.cdr.detectChanges();
  //   }, 200)
  // }
  
  // onRemoveDocs(event) {
  //   console.log(event);
  //   this.filesDocs.splice(this.filesDocs.indexOf(event), 1);
  // }


  rowsDoc = [];

  verDocumento(doc: any){
    //window.open(doc.contenido, "_blank");
    var element = document.createElement('a');
    element.setAttribute('href', doc.contenido);
    element.setAttribute('download', doc.nombre);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  filtrosOpen = false;
  rows1 = [];
  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null,
    nodoFil: "TODOS",
    role: "TODOS"
  }

  filterDatatable(){
    
    // assign filtered matches to the active datatable
    this.rows1 = this.rows.filter(function(item){
      var mostrar = true;
      if(!this.currentUser.nodo && this.nodos[item.nodo]){
        var nodo = this.nodos[item.nodo].name;
        if(this.filters.nodoFil != "TODOS" && nodo != this.filters.nodoFil) mostrar = false;
      }

      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.name.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.role != "TODOS" && item.role != this.filters.role) mostrar = false;
      
      return mostrar;
    }.bind(this));
    
  }

  resetFilters() {

    this.filters = {
      nombre: null,
      nodoFil: "TODOS",
      role: "TODOS"
    }
    this.filterDatatable();
  }

  

}
