import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NodoService, UserService, AuthenticationService, EventoService, InscritoService, CountService, DocumentoService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
// RxJS
import { first } from 'rxjs/operators';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { DataRowOutlet } from '@angular/cdk/table';

@Component({
  selector: 'kt-formaciones',
  templateUrl: './formaciones.component.html',
  styleUrls: ['./formaciones.component.scss']
})
export class FormacionesComponent implements OnInit {

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('questionDeleteSwal', {static: false}) private questionDeleteSwal: SwalComponent
  @ViewChild('confirmationDeleteSwal', {static: false}) private confirmationDeleteSwal: SwalComponent
  @ViewChild('questionDeleteInscripcionSwal', {static: false}) private questionDeleteInscripcionSwal: SwalComponent
  @ViewChild('confirmationDeleteInscripcionSwal', {static: false}) private confirmationDeleteInscripcionSwal: SwalComponent
  @ViewChild('contentFile', {static: false}) private contentFile: NgbModal
  @ViewChild('contentManual', {static: false}) private contentManual: NgbModal

  nodos = {};
  rows = [];
  nodo = [];
  formNodoIds = [];
  items: FormArray;
  error = { nodoSelected: false }
  formacionesHash = {};
  rowsModal = []
  rowsModalAcep = [];
  rowsModalRech = [];
  users = {};
  currentUser: any;
  //selectedValue: string = '';

  loadingVisible = false;

  vistaModal = "aceptado";
  eventForm: FormGroup;

  constructor(private eventoService: EventoService,
    private nodoService: NodoService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private userService: UserService,
    private inscritoService: InscritoService,
    private documentoService: DocumentoService,
    private countService: CountService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
    }

  loadEventos() {
    this.rows = [];
    this.eventoService.getAll()
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fechaObj = new Date(events[i].fecha);
          events[i].fecha = moment(new Date(events[i].fecha)).add(12, 'hours').format("DD/MM/YYYY");
          this.loadAsistencias(events[i]);
        }
        this.rows = events;
        
        this.rows1 = this.rows;
        this.cdr.detectChanges();
      });
  }  

  loadEventosNodo() {
    this.rows = [];
    this.eventoService.getAllAdmin(this.currentUser.nodo, this.currentUser.edicion, 'formacion')
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fechaObj = new Date(events[i].fecha);
          events[i].fecha = moment(new Date(events[i].fecha)).add(12, 'hours').format("DD/MM/YYYY");
          this.formacionesHash[events[i].id] = true;
          this.loadAsistencias(events[i]);
        }
        this.rows = events;
        
        this.eventoService.getAll()
        .subscribe(events => {
          this.eventoService.getAllByNodo(this.currentUser.nodo)
          .subscribe(eventsNodo=> {
            for(let eventNodo of eventsNodo) {    
              for(let event of events){
                if(eventNodo.evento == event.id && !event.nodo) {
                  event.fechaObj = new Date(event.fecha);
                  event.fecha = moment(new Date(event.fecha)).add(12, 'hours').format("DD/MM/YYYY");
                  this.loadAsistencias(event);
                  //this.loadNodosEvent(event);
                  this.rows.push(event)
                  break;
                }                    
              }
            }

            var datos = [...this.rows];
            this.rows = [];
            this.rows = datos;

            this.rows1 = this.rows;
            this.cdr.detectChanges();
          });
        });
        
        //this.rows1 = this.rows;
        //this.cdr.detectChanges();
      });
  }

  loadNodosEvent(event: any) {
    this.eventoService.getAllByEvent(event.id)
      .subscribe(nodosEvento=> {
      });
  }
  
  loadEventosNodos1(){
    this.eventoService.getNodos()
    .subscribe(eventosNodos => {
      this.formNodoIds = eventosNodos;
    });
  }

  loadAsistencias(evento: any) {
    this.countService.getCountAsistencias(evento.id)
    .subscribe(count => {
      evento.asistentes = count;
      this.cdr.detectChanges();
    });
  }

  loadUsers() {
    this.userService.getAllActive()
	    .subscribe(users => {
        for(var i = 0; i < users.length; i++) {
          this.users[users[i].id+""] = users[i];
        }
		});
  }

  loadNodos() {
		this.nodoService.getAllActive()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""]= nodos[i];
      }
      function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }
      this.nodo = nodos.sort(compare);
			this.cdr.detectChanges();

      
      if(this.currentUser.nodo) {
        this.loadEventosNodo();
      } else {
        this.loadEventos();
        this.loadEventosNodos1();
      }
		});
	}

  loadEventosNodos(){
    this.items = this.eventForm.get('items') as FormArray;
    for(var i = 0; i < this.nodo.length; i++){
      this.items.push(this.createItem(this.nodo[i]));
    }
  }

  createItem(nodo: any): FormGroup {
		var nodoSelected = false;
		return this.fb.group({
      nodoName: nodo.name,
			nodo: nodo.id,
			nodoSelected: nodoSelected
		});
	}

  loadEventosNodosEdit(idEvent){
    this.items = this.eventForm.get('items') as FormArray;
    if(this.items) {
      for(var i = 0; i < this.nodo.length; i++){
        this.items.push(this.createItemEdit(this.nodo[i], idEvent));
      } 
    } 
    this.cdr.detectChanges();
  }

  createItemEdit(nodo: any, idEvent: any): FormGroup {
    
    var nodoSelected = false;
    for(var i = 0; i < this.rows.length; i++) {
    if(this.rows[i].nodosForm && this.rows[i].id == idEvent && this.rows[i].nodosForm.includes(nodo.name)){
      nodoSelected = true;
    }
    }
    return this.fb.group({
      nodoName: nodo.name,
      nodo: nodo.id,
      nodoSelected: nodoSelected
    });
  }


  ngOnInit() {
    this.loadNodos();
    this.loadUsers();
    this.currentUser = this.authenticationService.currentUserValue;
  }

  formacionDetalle = null;

  openCentred(content, id) {
    this.rowsModal = []; 
    this.rowsModalAcep = [];
    this.rowsModalRech = [];
    this.modalService.open(content, { centered: true, size: 'xl' } ); 
    this.inscritoService.getByEvento(id)
    .subscribe(ins => {
      for(var i = 0; i < ins.length; i++) {
        ins[i].fechaObj = new Date(ins[i].fecha);
        var fecha = ins[i].fecha.substring(0, ins[i].fecha.indexOf('+')) + "Z";
        ins[i].fecha = moment(fecha).format("DD/MM/YYYY HH:mm");
        //ins[i].fecha = new Date(ins[i].fecha).toLocaleString();
        if(this.users[ins[i].user]) {
          ins[i].nombre = this.users[ins[i].user].name + " " + this.users[ins[i].user].apellidos;
          if(this.users[ins[i].user].role != 'centro'){
            ins[i].email = this.users[ins[i].user].username;
          }else {
            ins[i].email = this.users[ins[i].user].username;
            ins[i].emailcontacto = this.users[ins[i].user].emailContacto;
          }
          if(ins[i].asiste) {
            this.rowsModalAcep.push(ins[i])
            this.loadDocumentosCount(ins[i]);
          } else this.rowsModalRech.push(ins[i]);
        }
        
      }
      this.vistaModal = "aceptado";
      this.rowsModal = this.rowsModalAcep;
      this.cdr.detectChanges();
    });
    for(let form of this.rows){
      if(form.id == id) {
        this.formacionDetalle = form;
        break;
      }
    }
  }

  changeTab(tab: any){
    if(tab == "aceptado"){
      this.vistaModal = "aceptado";
      this.rowsModal = this.rowsModalAcep;
    } else {
      this.vistaModal = "rechazado";
      this.rowsModal = this.rowsModalRech;
    }
  }

  exportexcel(): void 
  {
    var datos = [...this.rowsModalAcep];
    for(var i = 0; i < datos.length; i++){
      var dato:any;
      dato = {...datos[i]};
      delete dato.user;
      delete dato.evento;
      delete dato.asiste;
      delete dato.id;
      datos[i] = dato;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);

    /*var datos1 = [...this.rowsModalRech];
    for(var i = 0; i < datos1.length; i++){
      delete datos1[i].user;
      delete datos1[i].evento;
      delete datos1[i].asiste;
      delete datos1[i].id;
    }
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos1);*/

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Asistencias');
    //XLSX.utils.book_append_sheet(wb, ws1, 'Asist. Rechazadas');

    /* save to file */
    XLSX.writeFile(wb, "Asistencias_" + moment().format("DDMMYYYY-HHmm") + ".xlsx");
  }

  submitted = false;
  loading = false;
  nuevo = true;

  initEventForm() {
		this.eventForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      nameEu: [''],
      fecha: [null, Validators.compose([Validators.required])],
      hora: [null, Validators.compose([Validators.required])],
      codFormacion: ['FORMACION', Validators.compose([Validators.required])],
      role: ['', Validators.compose([Validators.required])],
      plazas: ['', Validators.compose([Validators.required])],
      tipo: ['formacion'],
      lugar: ['', Validators.compose([Validators.required])],
      link: [''],
      items: this.fb.array([ ])
		});
    this.loadEventosNodos();
  }

  openNew(content) {
    this.nuevo = true;
    this.initEventForm();
    this.modalService.open(content, { centered: true, size: 'lg' } ); 
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.eventForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  dataNodo: any;   
    /**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.submitted = true;
    var nodos = [];

    var items = this.eventForm.value.items;
    var selectedNodo = false;
    nodos = [];
    for(var i = 0; i < items.length; i++) {
      if(items[i].nodoSelected) {
        selectedNodo = true;
        nodos.push(items[i].nodoName);
      }
    }
    if(!selectedNodo) {
      this.error.nodoSelected = true;
    } else {
      this.error.nodoSelected = false;
    }

    if(this.currentUser.nodo != 1) {
      
    }
     
		
		const controls = this.eventForm.controls;

		// check form
		if (this.eventForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		
		this.loading = true;
    
		var currentUser = this.authenticationService.currentUserValue;
    var form = this.eventForm.value;
   
    //delete form.fecha;

		// form.nodo = currentUser.nodo;
    form.tipo = "formacion";
    form.visible = true;
    form.edicion = currentUser.edicion;
    form.nodosForm = JSON.stringify(nodos);
    
    form.fecha = moment(form.fecha).add(12, 'hours').toDate();
    
		var formNodo = [];
     
		this.eventoService.post(form)
      .pipe(first())
      .subscribe(
        data => {
          this.formacionesHash[data.id] = true;
          data.asistentes = 0;
          data.fechaObj = new Date(data.fecha);
          data.fecha = moment(new Date(data.fecha)).add(12, 'hours').format("DD/MM/YYYY");
          //data.items = form.items;
          //delete data.nodo;
          this.rows = [...this.rows, data];//put object data to the list rows,the same with push!
          this.confirmationSwal.fire();
          this.cdr.detectChanges();
          for(var i=0; i < form.items.length; i++){
            if(form.items[i].nodoSelected == true)
              formNodo.push(form.items[i].nodo);
            }
            for(var i=0; i < formNodo.length; i++) {
              var dataNodo = { nodo: formNodo[i], evento: data.id }
              this.eventoService.postNodos(dataNodo)
              .pipe(first())
              .subscribe(
                Data => {
                 
                },
                error => {
                  console.error(error);
                });
            }       
          this.loading = false;
          this.modalService.dismissAll();
        },
        error => {
          console.error(error);
					this.loading = false;
        });
 
}
  edicionObj = null;

  edit(content, id) {
    this.nuevo = false;
    for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id == id) {
        var form = this.rows[i];
        this.edicionObj = form;
        this.eventForm = this.fb.group({
          name: [form.name, Validators.compose([Validators.required])],
          nameEu: [form.nameEu],
          fecha: [form.fechaObj, Validators.compose([Validators.required])],
          hora: [form.hora, Validators.compose([Validators.required])],
          codFormacion: [form.codFormacion, Validators.compose([Validators.required])],
          role: [form.role, Validators.compose([Validators.required])],
          plazas: [form.plazas, Validators.compose([Validators.required])],
          tipo: ['formacion'],
          lugar: [form.lugar, Validators.compose([Validators.required])],
          link: [form.link],
          items: this.fb.array([ ])
        });
        this.loadEventosNodosEdit(id);
        this.modalService.open(content, { centered: true, size: 'lg' } ); 
        break;
      }
    }
    
  }

  editSubmit() {
		if(this.loading) return;

		this.submitted = true;
		
    var nodos = [];

    var items = this.eventForm.value.items;
    var selectedNodo = false;
    nodos = [];
    for(var i = 0; i < items.length; i++) {
      if(items[i].nodoSelected) {
        selectedNodo = true;
        nodos.push(items[i].nodoName);
      }
    }
    if(!selectedNodo) {
      this.error.nodoSelected = true;
    } else {
      this.error.nodoSelected = false;
    }
		
		const controls = this.eventForm.controls;

		// check form
		if (this.eventForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
    var form = this.eventForm.value;

    form.id = this.edicionObj.id;

    form.fecha = moment(form.fecha).add(12, 'hours').toDate();
    form.nodosForm = JSON.stringify(nodos);

		var formNodo = [];
		this.eventoService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          this.edicionObj.fechaObj = new Date(data['fecha']);
          this.edicionObj.fecha = moment(new Date(data['fecha'])).add(12, 'hours').format("DD/MM/YYYY");
          this.edicionObj.name = form.name;
          this.edicionObj.nameEu = form.nameEu;
          this.edicionObj.hora = form.hora;
          this.edicionObj.role = form.role;
          this.edicionObj.plazas = form.plazas;
          this.edicionObj.lugar = form.lugar;
          this.edicionObj.link = form.link;
          //this.edicionObj.nodo = +form.nodo;
          this.edicionObj.nodosForm = form.nodosForm;
          this.userService.deleteEventNodos(form.id)
            .pipe(first()).subscribe(data => {
              for(let item of form.items) {
                if(item.nodoSelected == true) {
                  var dataNodo = { nodo: item.nodo, evento: form.id }
                  this.eventoService.postNodos(dataNodo)
                  .pipe(first())
                  .subscribe(
                    Data => {
                      
                    },
                    error => {
                      console.error(error);
                    }); 
                }                
              }
              
              this.confirmationSwal.fire();
              this.cdr.detectChanges();
    
              this.loading = false;
              this.modalService.dismissAll();






            });

          

          /*for(var i=0; i < form.items.length; i++){
            if(form.items[i].nodoSelected == true) formNodo.push(form.items[i].nodo);
          }
          for(var i=0; i < formNodo.length; i++) {
            var dataNodo = { nodo: formNodo[i], evento: form.id }
            this.eventoService.postNodos(dataNodo)
            .pipe(first())
            .subscribe(
              Data => {
    
              },
              error => {
                console.error(error);
              }); 
          } */
          
          
        },
        error => {
          console.error(error);
					this.loading = false;
        });
  }
  
  visibility(visible: any, id: any) {
    if(this.loadingVisible) return;
    this.loadingVisible = true;
    this.cdr.detectChanges();
    var evento = {id: id, visible: visible};
    this.eventoService.update(evento)
      .pipe(first())
      .subscribe(
        data => {
          for(var i = 0; i < this.rows.length; i++) {
            if(this.rows[i].id == id) {
              this.rows[i].visible = visible;
              this.loadingVisible = false;
              this.cdr.detectChanges();
              break;
            }
          }
        });
  }

  borrar = null;

  borrarFormacion(row: any) {
    this.borrar = row;
    this.questionDeleteSwal.fire();
  }

  borrarFormacion1(){
    this.borrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.userService.deleteEventNodos(this.borrar.id)
    .subscribe(Data =>{});
    this.eventoService.delete(this.borrar.id)
    .subscribe(data => {
      for(var i = 0; i < this.rows.length; i++) {
        if(this.rows[i].id == this.borrar.id){
          this.rows.splice(i, 1);
          var datos = [...this.rows];
          this.rows = [];
          this.rows = datos;
          this.cdr.detectChanges();
          break;
        }
      }
      this.confirmationDeleteSwal.fire();
      this.cdr.detectChanges();
    });
  }

  asistencia(asist: any, estado: any) {
    if(asist.loading) return;
    if(estado) asist.loadingTrue = true
    else asist.loadingFalse = true;
    this.cdr.detectChanges();
    var inscrito = {id: asist.id, asistencia: estado};
    this.inscritoService.update(inscrito)
      .pipe(first())
      .subscribe(
        data => {
          asist.asistencia = estado;
          asist.loadingTrue = false;
          asist.loadingFalse = false;
          this.cdr.detectChanges();
        });
  }

  loadDocumentosCount(asist: any) {
    this.countService.getCountDocumentos(asist.evento, asist.user)
    .subscribe(count => {
      asist.documentos = count;
      this.cdr.detectChanges();
    });
  }

  rowsDoc = [];
  asistFile = null;

  loadDocumentos(row: any){
    this.asistFile = row;
    row.loading = true;
    this.cdr.detectChanges();
    this.documentoService.getByFormacionUser(row.evento, row.user)
    .subscribe(data => {
      for(let doc of data) {
        var fecha = doc.fechaSubida.substring(0,doc.fechaSubida.indexOf('+')) + "Z";
        doc.fechaStr = moment(fecha).format("DD/MM/YYYY HH:mm");
      }
      window.scrollTo(0,1);
      this.modalService.open(this.contentFile, { centered: true, size: 'xl' } );
      window.scrollTo(0,0);
      this.rowsDoc = data;
      row.loading = false;
      this.cdr.detectChanges();
    });
  }

  verDocumento(doc: any){
    //window.open(doc.contenido, "_blank");
    var element = document.createElement('a');
    element.setAttribute('href', doc.contenido);
    element.setAttribute('download', doc.name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  loadingComentarios = false;

  guardarComentarios() {
    this.loadingComentarios = true;
    this.cdr.detectChanges();
    for(let doc of this.rowsDoc) {
      var docEdit = {id: doc.id, comentarios: doc.comentarios};
      this.documentoService.updateFormacion(docEdit)
      .subscribe(data => {
        this.loadingComentarios = false;
        this.cdr.detectChanges();
      });
    }
    

  }

  borrarInscripcion(row: any){
    this.borrar = row;
    this.questionDeleteInscripcionSwal.fire();
  }

  borrarInscripcion1(){
    this.borrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.inscritoService.delete(this.borrar.id)
    .subscribe(data => {
      for(var i = 0; i < this.rowsModal.length; i++) {
        if(this.rowsModal[i].id == this.borrar.id){
          this.rowsModal.splice(i, 1);
          var datos = [...this.rowsModal];
          this.rowsModal = [];
          this.rowsModal = datos;

          /*if(this.currentUser.nodo) {
            this.loadEventosNodo();
          } else {
            this.loadEventos();
            this.loadEventosNodos1();
          }*/
          this.loadAsistencias(this.formacionDetalle);

          this.cdr.detectChanges();
          var user = {id: this.borrar.user, formacion: null, codFormacion: null};
          this.userService.update(user)
            .pipe(first())
            .subscribe();
          break;
        }
      }
      this.confirmationDeleteSwal.fire();
      this.cdr.detectChanges();
    });
  }

  mentoras = [];
  centros = [];
  rowsManual = [];
  rowsManualOriginal = [];
  filtersManual = {nombre: "", email: ""};

  filterManual(){
    // assign filtered matches to the active datatable
    this.rowsManual = this.rowsManualOriginal.filter(function(item){
      var mostrar = true;

      var nombre = item.name + " " + (item.apellidos || '');
      
      if(this.filtersManual.nombre && this.filtersManual.nombre != null && this.filtersManual.nombre != "") {
        if(!nombre.toLowerCase().includes(this.filtersManual.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filtersManual.email && this.filtersManual.email != null && this.filtersManual.email != "") {
        if(!item.username.toLowerCase().includes(this.filtersManual.email.toLowerCase())) mostrar = false;
      }

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }

  loadMentoras() {
    this.userService.getByRol('mentora')
	    .subscribe(users => {
        this.rowsManualOriginal = users;
        this.filterManual();
        this.cdr.detectChanges();
		});
  }  

  loadCentros(){
    this.userService.getByRol('centro')
    .subscribe(users => {
      this.rowsManualOriginal = users;
      this.filterManual();
      this.cdr.detectChanges();
    });
  }

  currentModal = null;

  inscribirManual(){
    if(this.formacionDetalle.role == "mentora") {
      this.loadMentoras();
    } else if(this.formacionDetalle.role == "centro") {
      this.loadCentros();
    }
    this.currentModal = this.modalService.open(this.contentManual, { centered: true, size: 'lg' } );
  }

  loadingManual = false;

  addManual(row: any){
    //Borrado de todas las inscripciones anteriores
    this.loadingManual = true;
    this.currentModal.close();
    this.inscritoService.getByUserAsiste(row.id)
    .subscribe(inscripciones => {
      for(let ins of inscripciones) {
        if(this.formacionesHash[ins.evento] && ins.evento == this.formacionDetalle.id) this.inscritoService.delete(ins.id).pipe(first()).subscribe();  
      }
      //Añadimos la inscripción nueva
      var nuevo = {user: row.id, evento: this.formacionDetalle.id, fecha: new Date(), asiste: true};
      this.inscritoService.post(nuevo)
      .pipe(first())
      .subscribe(
        data => {
          data.fechaObj = new Date(data.fecha);
          var fecha = data.fecha.substring(0, data.fecha.indexOf('+')) + "Z";
          data.fecha = moment(fecha).format("DD/MM/YYYY HH:mm");
          //ins[i].fecha = new Date(ins[i].fecha).toLocaleString();
          if(this.users[data.user]) {
            data.nombre = this.users[data.user].name + " " + this.users[data.user].apellidos;
            data.email = this.users[data.user].username;
          }



          this.rowsModal = [...this.rowsModal, data];
          //Añadimos la formación en el perfil de usuario (Posibilidad de hacer join 'manual')
          var fechaEs = moment(this.formacionDetalle.fecha).format("DD/MM/YYYY");
          var user = {id: row.id, formacion: this.formacionDetalle.lugar + " - " + fechaEs + " " + this.formacionDetalle.hora, codFormacion: this.formacionDetalle.codFormacion};
          this.userService.update(user)
            .pipe(first())
            .subscribe(
              data1 => {
                this.loadingManual = false;
                this.cdr.detectChanges();
                //this.loadEventosNodo();

                this.loadAsistencias(this.formacionDetalle);
              },
              error => {
                console.error(error);
              });
          
        },
        error => {
          console.error(error);
					this.loading = false;
        });


    });
    
  }

  filtrosOpen = false;
  rows1 = [];
  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null,
    nodoFil: "TODOS",
    role: "TODOS"
  }

  filterDatatable(){
    
    // assign filtered matches to the active datatable
    this.rows1 = this.rows.filter(function(item){
      var mostrar = true;
      if(!this.currentUser.nodo && this.nodos[item.nodo]){
        var nodo = this.nodos[item.nodo].name;
        if(this.filters.nodoFil != "TODOS" && nodo != this.filters.nodoFil) mostrar = false;
      }

      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.name.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.role != "TODOS" && item.role != this.filters.role) mostrar = false;
      
      return mostrar;
    }.bind(this));
    
  }

  resetFilters() {
    
    this.filters = {
      nombre: null,
      nodoFil: "TODOS",
      role: "TODOS"
    }
    this.filterDatatable();
  }

}
