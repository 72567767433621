import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DataRowOutlet } from '@angular/cdk/table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { AsignacionService, AuthenticationService, EdicionService, NodoService, UserService } from '../../../_services';
import moment from 'moment';
import { first } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import{ GlobalVariables } from '../../../_common/global-variables';
import * as logoInspira from './inspira.js';
import * as logoDeusto from './deusto.js';
import * as firma from './firma.js';
import { Router } from '@angular/router';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";

declare const jsPDF : any;

@Component({
  selector: 'kt-asignaciones',
  templateUrl: './asignaciones.component.html',
  styleUrls: ['./asignaciones.component.scss']
})
export class AsignacionesComponent implements OnInit {
  
  @ViewChild('questionDeleteSwal', {static: false}) private questionDeleteSwal: SwalComponent
  @ViewChild('errorEdicionExisteSwal', {static: false}) private errorEdicionExisteSwal: SwalComponent

  
  rows = [];
  rows1 =[];
  currentUser: any;
  nuevo = true;
  asignacionForm: FormGroup;
  estadoArray: FormArray;
  estados = ["PRE-INSCRITA/O","INSCRITA/O","ASIGNABLE","ASIGNADA/O"];
  nodos = {};
  nodo = [];
  mentoras = [];
  mentorasHash = {};
  edicionesHash = {};
  elementoExportar = {};
  ediciones = [];
  centros = [];
  centrosHash = {};
  numGrupos = [1,2,3,4,5]
  idiomas = ["Castellano", "Euskara", "Catalán", "Inglés"]
  generos = ["Chicas", "Chicos", "Mixto"]  
  loading = false;
  modalRef2: NgbModalRef;
  modalRef1: NgbModalRef;
  mentoraSeleccionada = null;
  borrar = null;
  numeroSeleccionado = 1;
  hoy = {};
  loadingExport = false;
  loadingExcel = false;
  loadingPDF = false;
  

  mentorasFiltradas: any[];

  filtroMentoras = new FormControl('');

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService : AuthenticationService,
    private nodoService : NodoService,
    private userService: UserService,
    private edicionService: EdicionService,
    private asignacionService: AsignacionService,
    private router: Router,
  ) {
    this.filtroMentoras.valueChanges.subscribe(value => {
      this.filtrarMentoras(value);
    });
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.loadRows();
    this.loadNodos();
    this.loadCentrosNodo();
    this.loadMentorasNodo();
    this.loadEdiciones();
    this.loadFechaActual();
    this.cdr.detectChanges();
  }

  

  loadFechaActual(){
    const fechaActual = new Date();
    const mesCastellano = fechaActual.toLocaleString('es-ES', {month: 'long'})

    let mesActual = new Date().getMonth();
    let mesEuskera;
    switch (mesActual) {
      case 0:
        mesEuskera = "Urtarrila";
        break;
      case 1:
        mesEuskera = "Otsaila";
        break;
      case 2:
        mesEuskera = "Martxoa";
        break;
      case 3:
        mesEuskera = "Apirila";
        break;
      case 4:
        mesEuskera = "Maiatza";
        break;
      case 5:
        mesEuskera = "Ekaina";
        break;
      case 6:
        mesEuskera = "Uztaila";
        break;
      case 7:
        mesEuskera = "Abuztua";
        break;
      case 8:
        mesEuskera = "Iraila";
        break;
      case 9:
        mesEuskera = "Urria";
        break;
      case 10:
        mesEuskera = "Azaroa";
        break;
      case 11:
        mesEuskera = "Abendua";
        break;
      default:
        mesEuskera = "";
    }
    

    const dia = fechaActual.getDate();
    this.hoy = {
      mesCastellano: mesCastellano,
      mesEuskera: mesEuskera,
      dia: dia,
    }
  }


  onNumeroSeleccionado(number: any){
    this.asignacionForm.get('numGrupos').valueChanges.subscribe((numGrupos: number) => {
      for (let i = 1; i <= 5; i++) {
        const idiomaControl = this.asignacionForm.get(`idioma${i}`);
        const generoControl = this.asignacionForm.get(`genero${i}`);
        if (i <= numGrupos) {
          idiomaControl.setValidators([Validators.required]);
          generoControl.setValidators([Validators.required]);
        } else {
          idiomaControl.clearValidators();
          generoControl.clearValidators();
        }
    
        idiomaControl.updateValueAndValidity();
        generoControl.updateValueAndValidity();
      }
    });
  }

  loadRows(){
    this.asignacionService.getAll()
    .subscribe(data => {
      this.rows = data;
      this.cdr.detectChanges();
      this.filterDatatable();
    })
  }

  borrarAsignacion(row: any){
    this.borrar = row;
    this.questionDeleteSwal.fire();
  }

  borrarAsignacion1(){
    this.borrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.asignacionService.delete(this.borrar.id)
    .subscribe(data => {
      for(var i = 0 ; i < this.rows.length; i++){
        if(this.rows[i].id == this.borrar.id){
          this.rows.splice(i, 1);
          var datos = [...this.rows];
          this.rows = [];
          this.rows = datos;
          this.filterDatatable();
          this.cdr.detectChanges();
          break;
        }
      }
    })
  }


  filtrarMentoras(filtro: string) {
    if(filtro != ""){
      this.mentorasFiltradas = this.mentoras.filter(mentora =>
        mentora.name.toLowerCase().includes(filtro.toLowerCase()) ||
        mentora.apellidos.toLowerCase().includes(filtro.toLowerCase())
      );
    }
    else{
      this.mentorasFiltradas = this.mentoras;
    }
    
  }

  selectItem(row: any){
  }

  abrirMentorasModal(content: any){
    this.modalRef2 = this.modalService.open(content, { centered: true, size: 'lg' });
  }

  loadCentrosNodo() {
    this.userService.getByRolNodo('centro', this.currentUser.nodo)
	    .subscribe(users => {
        //this.centros = users;
        this.centros.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        for(let centro of users){
          if(centro.estado == "ASIGNABLE"){
            this.centrosHash[centro.id+""] = centro;
            this.centros.push(centro);
          }
        }
        this.cdr.detectChanges();
		});
  }

  loadEdiciones(){
    this.edicionService.getAll()
    .subscribe(ediciones => {
      for(let edicion of ediciones){
        this.edicionesHash[edicion.id+""] = edicion;
      }
      this.ediciones = ediciones;
      this.cdr.detectChanges();
    })
  }

  loadNodos() {
		this.nodoService.getAll()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""]= nodos[i];
      }
      function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }
      this.nodo = nodos.sort(compare);
			this.cdr.detectChanges();
		});
	}

  loadMentorasNodo() {
    this.userService.getByRolNodo('mentora', this.currentUser.nodo)
	    .subscribe(users => {
        this.mentoras = users;
        this.mentorasFiltradas = users;
        for(let user of this.mentoras) {
          this.mentorasHash[user.id+""] = user;
          //this.loadInscripcion(user);
          if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
        }
        this.cdr.detectChanges();
		});
  }

  openNew(content) {
    this.nuevo = true;
    this.initMaterialForm();
    this.modalRef1 = this.modalService.open(content, { centered: true, size: 'lg' } ); 
  }

  edicionObj = null;
  edit(content, id) {
    this.nuevo = false;
    for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id == id) {
        var form = this.rows[i];
        this.edicionObj = form;
        this.mentoraSeleccionada = this.mentorasHash[form.mentora]
        this.asignacionForm = this.fb.group({
          edicion: [this.edicionesHash[form.edicion].id, Validators.compose([Validators.required])],
          mentoraSeleccionada: [this.mentorasHash[form.mentora].id, Validators.compose([Validators.required])],
          centro:  [this.centrosHash[form.centro].id, Validators.compose([Validators.required])],
          idioma:  [form.idioma, Validators.compose([Validators.required])],
          numGrupos :  [form.num_grupos, Validators.compose([Validators.required])],
          grupo: [form.grupo, Validators.compose([Validators.required])],
          comentarios : [form.comentarios],
        });
        this.modalService.open(content, { centered: true, size: 'lg' } );
        this.cdr.detectChanges(); 
        break;
      }
    }
  }

  group(content, id){
    for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id == id) {
        var form = this.rows[i];
        this.edicionObj = form;
        this.numeroSeleccionado = form.num_grupos;
        this.asignacionForm = this.fb.group({
          numGrupos: [form.num_grupos, Validators.compose([Validators.required])],
          idioma1: [form.idioma1, Validators.compose([Validators.required])],
          idioma2: [form.idioma2, Validators.compose([Validators.required])],
          idioma3: [form.idioma3, Validators.compose([Validators.required])],
          idioma4: [form.idioma4, Validators.compose([Validators.required])],
          idioma5: [form.idioma5, Validators.compose([Validators.required])],
          
          genero1: [form.genero1, Validators.compose([Validators.required])],
          genero2: [form.genero2, Validators.compose([Validators.required])],
          genero3: [form.genero3, Validators.compose([Validators.required])],
          genero4: [form.genero4, Validators.compose([Validators.required])],
          genero5: [form.genero5, Validators.compose([Validators.required])],
        });
        this.modalService.open(content, { centered: true, size: 'lg' } );
        this.cdr.detectChanges(); 
        break;
      }
    }
  }

  editGroups(){
    if(this.loading) return;
    this.submitted = true;

    const controls = this.asignacionForm.controls;
    if (this.asignacionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
    this.cdr.detectChanges();

    this.loading = true;
    var form = this.asignacionForm.value;
    form.id = this.edicionObj.id;
    form.num_grupos = form.numGrupos;
    if(form.num_grupos == 1){
      form.genero2 = '';
      form.idioma2 = '';
      form.genero3 = '';
      form.idioma3 = '';
      form.genero4 = '';
      form.idioma4 = '';
      form.genero5 = '';
      form.idioma5 = '';
    } 
    else if(form.num_grupos == 2){
      form.genero3 = '';
      form.idioma3 = '';
      form.genero4 = '';
      form.idioma4 = '';
      form.genero5 = '';
      form.idioma5 = '';
    }
    else if(form.num_grupos == 3){
      form.genero4 = '';
      form.idioma4 = '';
      form.genero5 = '';
      form.idioma5 = '';
    }
    else if(form.num_grupos == 4){
      form.genero5 = '';
      form.idioma5 = '';
    }
    this.asignacionService.update(form)
    .pipe(first())
    .subscribe(data => {
      this.loading = false;
      for(let i = 0 ; i < this.rows.length;i++){
        if(this.rows[i].id == this.edicionObj.id){
          this.rows[i] = data.body;
          this.rows = [...this.rows]
          this.cdr.detectChanges();
        }
      }
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    },
    error => {
      console.error(error);
      this.loading = false;
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    })
  }

  editSubmit(){
    if(this.loading) return;
    this.submitted = true;

    this.loading = true;
    var form = this.asignacionForm.value;
    let asignacionesMentora = [];

    for(let i = 0 ; i < this.rows1.length; i++){
      if(this.rows1[i].mentora == form.mentoraSeleccionada){
        asignacionesMentora.push(this.rows[i]);
      }
    }
    console.log(asignacionesMentora)
    for(let i = 0 ; i < asignacionesMentora.length ; i++){
      if(asignacionesMentora[i].edicion == form.edicion){
        this.errorEdicionExisteSwal.fire();
        this.loading = false;
        this.cdr.detectChanges();
        return;
      }
    }


    form.id = this.edicionObj.id;

    form.fechaCreacion = new Date();
    form.mentora = this.mentoraSeleccionada.id;

    this.asignacionService.update(form)
    .pipe(first())
    .subscribe(data => {
      this.loading = false;
      for(let i = 0 ; i < this.rows.length;i++){
        if(this.rows[i].id == this.edicionObj.id){
          this.rows[i] = data.body;
          this.rows = [...this.rows];
          this.filterDatatable();
          this.cdr.detectChanges();
        }
      }
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    },
    error => {
      console.error(error);
      this.loading = false;
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    })
  }
  

  initMaterialForm() {
    const reg = /^(http\:\/\/|https\:\/\/)/;
    this.mentoraSeleccionada = null;
    if(!this.currentUser.nodo){
		this.asignacionForm = this.fb.group({
      edicion: ['', Validators.compose([Validators.required])],
      mentoraSeleccionada: ['', Validators.compose([Validators.required])],
      centro:  ['', Validators.compose([Validators.required])],
      numGrupos :  ['', Validators.compose([Validators.required])],
      comentarios : [''],
      idioma1: ['', Validators.compose([Validators.required])],
      idioma2: [''],
      idioma3: [''],
      idioma4: [''],
      idioma5: [''],

      genero1: ['',Validators.compose([Validators.required])],
      genero2: [''],
      genero3: [''],
      genero4: [''],
      genero5: [''],
      /*
      name: ['', Validators.compose([Validators.required])],
      fechaSubida: [null],
      role: ['', Validators.compose([Validators.required])],
      contenido: ['', Validators.compose([Validators.required, Validators.pattern(reg)])],
      nodo: [null],      
      estadoArray: this.fb.array([ ])*/
		});
    
    } else {
        this.asignacionForm = this.fb.group({
          edicion: ['', Validators.compose([Validators.required])],
          mentoraSeleccionada: ['', Validators.compose([Validators.required])],
          centro:  ['', Validators.compose([Validators.required])],
          numGrupos :  ['', Validators.compose([Validators.required])],
          comentarios: [''],

          idioma1: ['', Validators.compose([Validators.required])],
          idioma2: [''],
          idioma3: [''],
          idioma4: [''],
          idioma5: [''],

          genero1: ['', Validators.compose([Validators.required])],
          genero2: [''],
          genero3: [''],
          genero4: [''],
          genero5: [''],
          /*
          name: ['', Validators.compose([Validators.required])],
          fechaSubida: [null],
          role: ['', Validators.compose([Validators.required])],
          contenido: ['', Validators.compose([Validators.required, Validators.pattern(reg)])],
          nodo: [this.currentUser.nodo],      
          estadoArray: this.fb.array([ ])*/
      });
    } this.loadEstados();
  }

 
  async exportar(row: any){
    if(row.loadingTrue) return;
    row.loadingTrue = true;
    this.cdr.detectChanges();
    this.elementoExportar = row;
    this.cdr.detectChanges();

    const doc = new jsPDF();
    doc.addImage(logoInspira.base64, 125, 15, 50, 30);
    doc.addImage(logoDeusto.base64, 30, 30, 60, 15);
    doc.addImage(firma.base64, 95, 230, 30, 20);
    const content = document.getElementById('pdfContent').innerHTML;
    const content1 = document.getElementById('pdfContent1').innerHTML;
    const content4 = document.getElementById('pdfContent4').innerHTML;
    const content5 = document.getElementById('pdfContent5').innerHTML;
    const content6 = document.getElementById('pdfContent6').innerHTML;
    const informa1 = document.getElementById('informa1').innerHTML;
    const informa2 = document.getElementById('informa2').innerHTML;
    const final1 = document.getElementById('final1').innerHTML;
    const final2 = document.getElementById('final2').innerHTML;

    doc.rect(30, 60, 78, 155);
    doc.rect(108, 60, 78, 155);
    doc.fromHTML(content, 34, 63, {
      'width': 70,
      'align': 'justify',
      'lineHeight': 1.5
    });
    doc.fromHTML(content1, 113, 63, {
      'width': 70,
      'align': 'justify',
      'lineHeight': 3
    });

    doc.fromHTML(informa1, 34, 89, {
      'width': 70
    })
    doc.fromHTML(final1, 34, 97, {
      'width': 70
    })

    doc.fromHTML(informa2, 113, 88, {
      'width': 70
    })
    doc.fromHTML(final2, 113, 97, {
      'width': 70
    })

    //FIRMA Y NOMBRE
    doc.fromHTML(content4, 87, 253);
    doc.fromHTML(content5, 71, 260);
    doc.fromHTML(content6, 73, 267);
    
    doc.save(this.mentorasHash[row.mentora].name + " " + this.mentorasHash[row.mentora].apellidos + " - " + moment().format("DDMMYYYYHHmmss") + '.pdf');


    row.loadingTrue = false;
    this.cdr.detectChanges();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.asignacionForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }


  submitted = false;
  onSubmit(){
    this.submitted = true;
    const controls = this.asignacionForm.controls;

    // check form
		if (this.asignacionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

    this.loading = true;
    var form = this.asignacionForm.value;
    
    let asignacionesMentora = [];

    for(let i = 0 ; i < this.rows1.length; i++){
      if(this.rows1[i].mentora == form.mentoraSeleccionada.id){
        asignacionesMentora.push(this.rows[i]);
      }
    }
    for(let i = 0 ; i < asignacionesMentora.length ; i++){
      if(asignacionesMentora[i].edicion == form.edicion){
        this.errorEdicionExisteSwal.fire();
        this.loading = false;
        this.cdr.detectChanges();
        return;
      }
    }


    form.mentora = form.mentoraSeleccionada.id,
    form.num_grupos = form.numGrupos;
    form.fecha_creacion = new Date();

    this.asignacionService.post(form)
    .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.rows = [...this.rows, data];
          this.filterDatatable();
          this.cdr.detectChanges();
          this.mentoraSeleccionada = null;
          this.numeroSeleccionado = 1;
          this.modalService.dismissAll();
        },
        error => {
          console.error(error);
					this.loading = false;
        });
  }

  resizeTextarea() {
    const textarea = document.querySelector('textarea');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  loadEstados(){
    this.estadoArray = this.asignacionForm.get('estadoArray') as FormArray;
    if(this.estadoArray)
      for(var i = 0; i < this.estados.length; i++){
        this.estadoArray.push(this.createItemEst(this.estados[i]));
      }
      this.cdr.detectChanges();
  }

  createItemEst(estado1: any): FormGroup {
    var estadoSelect = false;     
    return this.fb.group({
      estado: estado1,
      estadoSelect: estadoSelect
    });
  }

  // ------- FILTROS -------------

  filtrosOpen = false;

  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    //GlobalVariables.filters[this.router.url].open = this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    edicion: "",
    mentora: "",
    centro: "",
  }

  resetFilters() {
    
    this.filters = {
      edicion: "",
      mentora: "",
      centro: "",
    }
    this.filterDatatable();
  }

  filterDatatable(){
    // assign filtered matches to the active datatable
    this.rows1 = this.rows.filter(function(item){
      var mostrar = true;

      if(this.filters.edicion && this.filters.edicion != null && this.filters.edicion != "") {
        if(!this.edicionesHash[item.edicion].name.toLowerCase().includes(this.filters.edicion.toLowerCase())) mostrar = false;
      }

      if(this.filters.mentora && this.filters.mentora != null && this.filters.mentora != "") {
        if(!(this.mentorasHash[item.mentora].name + " " + this.mentorasHash[item.mentora].apellidos).toLowerCase().includes(this.filters.mentora.toLowerCase())) mostrar = false;
      }

      if(this.filters.centro && this.filters.centro != null && this.filters.centro != "") {
        if(!this.centrosHash[item.centro].name.toLowerCase().includes(this.filters.centro.toLowerCase())) mostrar = false;
      }

      //if(this.filters.role != "TODOS" && item.role != this.filters.role) mostrar = false;
      return mostrar;
    }.bind(this));
  }

  exportAllPdf(){
    this.loadingPDF = true;
    this.cdr.detectChanges();
    const doc = new jsPDF();
    for(let i = 0 ; i < this.rows1.length ; i++){
      if(i != 0) doc.addPage();
      this.elementoExportar = this.rows1[i];
      this.cdr.detectChanges();

      doc.addImage(logoInspira.base64, 125, 15, 50, 30);
      doc.addImage(logoDeusto.base64, 30, 30, 60, 15);
      doc.addImage(firma.base64, 95, 230, 30, 20);
      const content = document.getElementById('pdfContent').innerHTML;
      const content1 = document.getElementById('pdfContent1').innerHTML;
      const content4 = document.getElementById('pdfContent4').innerHTML;
      const content5 = document.getElementById('pdfContent5').innerHTML;
      const content6 = document.getElementById('pdfContent6').innerHTML;
      const informa1 = document.getElementById('informa1').innerHTML;
      const informa2 = document.getElementById('informa2').innerHTML;
      const final1 = document.getElementById('final1').innerHTML;
      const final2 = document.getElementById('final2').innerHTML;

      doc.rect(30, 60, 78, 155);
      doc.rect(108, 60, 78, 155);
      doc.fromHTML(content, 34, 63, {
        'width': 70,
        'align': 'justify',
        'lineHeight': 1.5
      });
      doc.fromHTML(content1, 113, 63, {
        'width': 70,
        'align': 'justify',
        'lineHeight': 3
      });

      doc.fromHTML(informa1, 34, 89, {
        'width': 70
      })
      doc.fromHTML(final1, 34, 97, {
        'width': 70
      })

      doc.fromHTML(informa2, 113, 88, {
        'width': 70
      })
      doc.fromHTML(final2, 113, 97, {
        'width': 70
      })

      //FIRMA Y NOMBRE
      doc.fromHTML(content4, 87, 253);
      doc.fromHTML(content5, 71, 260);
      doc.fromHTML(content6, 73, 267);
    }
    doc.save(moment().format("DDMMYYYYHHmmss") + '.pdf');
    this.loadingPDF = false;
    this.cdr.detectChanges();
  }

  exportexcel(): void {
    if(this.loadingExcel) return;
    this.loadingExcel = true;
    this.cdr.detectChanges();


    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Informe de Asignaciones');
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(6).width = 10;
    worksheet.getColumn(7).width = 10;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 10;
    worksheet.getColumn(10).width = 10;
    worksheet.getColumn(11).width = 10;
    worksheet.getColumn(12).width = 10;
    worksheet.getColumn(13).width = 10;

    let logo = workbook.addImage({
      base64: logoInspira.base64,
      extension: 'png',
    });
    var image = worksheet.addImage(logo, {
      tl: { col: 0.5, row: 1.5 },
      ext: { width: 250, height: 130 },
      editAs: 'absolute'
    });

    const cell = worksheet.getCell('C2');
    cell.value = 'Informe - Asignaciones INSPIRA';
    cell.font = { size: 16, bold: true };

    worksheet.getCell('C4').value = 'Fecha Informe: ';
    worksheet.getCell('C4').font = { bold: true };
    worksheet.getCell('D4').value = moment().format("DD/MM/YYYY HH:mm");

    if((this.filters.edicion && this.filters.edicion != null && this.filters.edicion != "") || (this.filters.mentora && this.filters.mentora != null && this.filters.mentora != "") || (this.filters.centro && this.filters.centro != null && this.filters.centro != "")){

      worksheet.getCell('C6').value = 'Edición: ';
      worksheet.getCell('C6').font = { bold: true };
      worksheet.getCell('D6').value = this.filters.edicion;

      worksheet.getCell('C7').value = 'Centro: ';
      worksheet.getCell('C7').font = { bold: true };
      worksheet.getCell('D7').value = this.filters.centro;

      worksheet.getCell('C8').value = 'Mentor(a): ';
      worksheet.getCell('C8').font = { bold: true };
      worksheet.getCell('D8').value = this.filters.mentora;

      for(let i = 0; i < 1; i ++){
        worksheet.addRow([]);
      }
    }

    else{
      for(let i = 0; i < 5; i ++){
        worksheet.addRow([]);
      }
    }


    let titleRow = worksheet.addRow(['Edición', 'Mentor(a)', 'Centro', 'Comentarios', 'Número de grupos', 'Idioma 1', 'Género 1', 'Idioma 2', 'Género 2', 'Idioma 3', 'Género 3', 'Idioma 4', 'Género 4', 'Idioma 5', 'Género 5']);
    titleRow.font = { bold: true };
  

    if(this.rows1.length == 1){
      let row = worksheet.addRow([this.edicionesHash[this.rows1[0].edicion].name, this.mentorasHash[this.rows1[0].mentora].name + " " + this.mentorasHash[this.rows1[0].mentora].apellidos, this.centrosHash[this.rows1[0].centro].name, this.rows1[0].comentarios, this.rows1[0].num_grupos, this.rows1[0].idioma1, this.rows1[0].genero1, this.rows1[0].idioma2, this.rows1[0].genero2, this.rows1[0].idioma3, this.rows1[0].genero3, this.rows1[0].idioma4, this.rows1[0].genero4, this.rows1[0].idioma5, this.rows1[0].genero5]);
      completar(this);
      this.cdr.detectChanges();
    }
    else if (this.rows1.length == 0){
      this.loadingExcel = false;
      this.cdr.detectChanges();
      return;
    }
    else{
      for(let i = 0 ; i < this.rows1.length; i++){
        if(this.mentorasHash[this.rows1[i].mentora])
          worksheet.addRow([this.edicionesHash[this.rows1[i].edicion].name, this.mentorasHash[this.rows1[i].mentora].name + " " + this.mentorasHash[this.rows1[i].mentora].apellidos, this.centrosHash[this.rows1[i].centro].name, this.rows1[i].comentarios, this.rows1[i].num_grupos, this.rows1[i].idioma1, this.rows1[i].genero1, this.rows1[i].idioma2, this.rows1[i].genero2, this.rows1[i].idioma3, this.rows1[i].genero3, this.rows1[i].idioma4, this.rows1[i].genero4, this.rows1[i].idioma5, this.rows1[i].genero5]);
        if(i == this.rows1.length - 1){
          completar(this);
          this.cdr.detectChanges();
        }
      }
    }
    
    function completar(this1){
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var element = document.createElement('a');
        element.href = window.URL.createObjectURL(blob);
        element.setAttribute('download', 'Informe-Asignaciones' + moment().format("DDMMYYYYHHmmss") + '.xlsx');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this1.loadingExcel = false;
        this1.cdr.detectChanges();
      });
    }
  }
}