// Spain
export const locale = {
	lang: 'es',
	data: {
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MODAL: {
			CERRAR: 'Cerrar'
		},
		MENU: {
			START: 'Formulario inicial',
			HOME: 'Inicio',
			MATERIAL: 'Material',
			MATERIALES: 'Materiales',
			FORMACION: 'Formación',
			FORMACION_CENTRO: 'Eventos',
			PERFIL: 'Mi Perfil',
			MENTORAS: 'Mentoras',
			CENTROS: 'Centros',
			EVENTOS: 'Eventos (Inicio)',
			FORMACIONES: 'Formaciones/Eventos',
			ASIGNACIONES: 'Asignaciones',
			LOGS: 'Registro de Cambios',
			GRUPOS: 'Grupos'
		},
		BREADCRUMBS: {
			START: {
				TITLE: 'Formulario inicial',
				DESC: 'Algunos datos más sobre tu participación en Inspira STEAM'
			},
			HOME: {
				TITLE: "Inicio",
				DESC: "Te damos la bienvenida a la Intranet de Inspira STEAM"
			},
			MATERIAL: {
				TITLE: "Material",
				DESC: "Aquí encontrarás toda la documentación necesaria para Inspira STEAM"
			},
			MATERIALES: {
				TITLE: "Materiales",
				DESC: "Detalle y gestión de los materiales disponibles para las mentoras y centros."
			},
			FORMACION: {
				TITLE: "Formación",
				DESC: "Aquí encontrarás las formaciones disponibles. Debes seleccionar una."
			},
			FORMACION_CENTRO: {
				TITLE: "Eventos",
				DESC: "Aquí encontrarás los eventos disponibles. Debes seleccionar uno."
			},
			MENTORAS: {
				TITLE: "Mentoras",
				DESC: "Listado completo de las mentoras registradas en Inspira STEAM."
			},
			MENTORA: {
				TITLE: "Mentora",
				DESC: "Detalle y gestión de mentora."
			},
			CENTROS: {
				TITLE: "Centros",
				DESC: "Listado completo de centros registrados en Inspira STEAM."
			},
			CENTRO: {
				TITLE: "Centro",
				DESC: "Detalle y gestión de centro."
			},
			EVENTOS: {
				TITLE: "Eventos",
				DESC: "Gestión de los eventos de Inspira STEAM."
			},
			FORMACIONES: {
				TITLE: "Formaciones",
				DESC: "Gestión de las formaciones de Inspira STEAM."
			},
			ASIGNACIONES: {
				TITLE: "Asignaciones",
				DESC: "Detalle y gestión de las asignaciones disponibles para las mentoras y centros."
			},
			PERFIL: {
				TITLE: "Mi Perfil",
				DESC: "Revisa tus datos antes de participar en Inspira STEAM."
			},
			LOGS: {
				TITLE: "Registro de cambios",
				DESC: "Registro de cambios realizados en la plataforma por Mentoras y Centros."
			},
			GRUPOS: {
				TITLE: "Grupos",
				DESC: "Listado de los grupos que rellenan los cuestionarios en Inspira STEAM."
			},
			CONTROLPANEL: {
				TITLE: "Panel de Control",
				DESC: "Gestión de admins, nodos, ediciones."
			},
			GESTIONNODO: {
				TITLE: "Gestión del nodo",
				DESC: "Gestión de zonas, subzonas, idiomas y entidades de Mentoras y Centros."
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				SIGNIN_BUTTON: 'Inicia Sesión',
				YES_ACCOUNT: '¿Ya tienes una cuenta?',
				NO_ACCOUNT: '¿Aún no tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: '¿Olvidaste tu contraseña?',
				BACK_BUTTON: 'Volver'
			},
			LOGIN: {
				TITLE: 'Inicia sesión en la Intranet',
				BUTTON: 'Iniciar Sesión',
			},
			FORGOT: {
				TITLE: '¿Olvidaste tu contraseña?',
				DESC: 'Introduzca su correo electrónico para restablecer su contraseña',
				SUCCESS: 'Se ha enviado un correo de restablecimiento a su cuenta. Siga las instrucciones indicadas en el mismo.'
			},
			REGISTER: {
				TITLE: 'Registro en la Intranet',
				SUCCESS: 'Tu cuenta ha sido creada correctamente.'
			},
			INPUT: {
				EMAIL: 'Email',
				CONFIRM_EMAIL: 'Confirmar Email',
				FULLNAME: 'Nombre',
				APELLIDO: 'Apellidos',
				CENTERNAME: 'Nombre del centro',
				PASSWORD: 'Contraseña',
				CONFIRM_PASSWORD: 'Confirmar Contraseña',
				USERNAME: 'Usuario',
				NODO: 'Nodo',
				SOY: 'Soy...',
				MENTORA: 'Mentora / Mentor',
				CENTRO: 'Centro',
				ACEPTO: 'Acepto la',
				LOPD: 'Ley Orgánica de Protección de Datos (LOPD)',
				LISTAMENTORAS: 'Autorizo a ser añadida a la lista de Mentoras',
				LISTACENTROS: 'Autorizo a ser añadido a la lista de Centros',
				SELECTNODO: 'Selecciona el nodo...',
				LOADNODO: 'Cargando nodos...'
			},
			VALIDATION: {
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				MATCH_EMAIL: 'El email y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		},
		FORMACION: {
			BOTON_NO: 'Pulsa aquí si ya has asistido a una formación de Inspira STEAM en los últimos 4 años',
			CONFIR_NO: 'Has confirmado la asistencia a una formación en los últimos 4 años',
			FECHA: 'Fecha',
			NOMBRE: 'Nombre',
			LUGAR: 'Lugar',
			HORA: 'Hora',
			ESTADO: 'Estado',
			DISPONIBLE: 'DISPONIBLE',
			COMPLETA: 'COMPLETA',
			INSCRIBIRME: 'INSCRIBIRME',
			ULTIMAS: 'ÚLTIMAS PLAZAS',
			CONFIRM_PREGUNTA_TITLE: 'Confirmación de Formación',
			CONFIRM_PREGUNTA_TEXT: '¿Confirmas que has asistido a una formación en los últimos 4 años?',
			CONFIRM_BTNOK: 'Si, confirmo',
			CONFIRM_CORRECT_TITLE: 'Confirmación Correcta',
			CONFIRM_CORRECT_TEXT: 'Hemos guardado correctamente tu confirmación de asistencia a formación en los últimos 4 años.',
			CONFIRM_ERROR_TITLE: 'Error en la inscripción',
			CONFIRM_ERROR_TEXT: 'La formación seleccionada está completa.',
			CONFIRM_CANCEL_TITLE: 'Cancelación de Inscripción',
			CONFIRM_CANCEL_TEXT: '¿Confirmas la cancelación de tu inscripción a este evento?',
			CONFIRM_CANCELED_TITLE: 'Cancelación Correcta',
			CONFIRM_CANCELED_TEXT: 'Se ha cancelado tu inscripción al evento.',
			INSCRITA: 'Formación inscrita',
			CANCELAR: 'CANCELAR ASISTENCIA',
			INFO: 'Las formaciones están dirigidas a mentoras y mentores nuevos, o que hayan realizado la formación hace más de 4 años. Se dará prioridad a estas personas, en caso de haber plazas disponibles podrán participar el resto de mentoras y mentores.',
			INFO_CENTRO: '<b>Reunión de coordinación obligatoria para centros participantes en Inspira STEAM.</b> Es necesario que al menos una persona de cada centro acuda a la reunión de coordinación con centros que se celebrará para dar a conocer los detalles del programa, lo que implica participar con los grupos de estudiantes, responsabilidades en las diferentes etapas del proceso, materiales, etc.',
			ASISTENCIA_SI: 'Has asistido al evento',
			ASISTENCIA_NO: 'No has asistido al evento',
			NO_EVENTOS:'No hay eventos disponibles' 
		},
		MATERIAL: {
			BOTON_LECTURA_MENT: 'Confirmo que he leído el Kit de Información de Mentoras',
			CONFIR_LECTURA_MENT: 'Has confirmado la lectura del Kit de Información de Mentoras',
			BOTON_LECTURA_CENT: 'Confirmo que he leído el Kit de Información de Centros',
			CONFIR_LECTURA_CENT: 'Has confirmado la lectura del Kit de Información de Centros',
			CONFIRMACION: '¿Confirmas la lectura del documento?',
			KIT_MENT: 'Kit de Información de Mentoras',
			KIT_MENT_DESC: 'Este documento contiene información operativa para que las mentoras y mentores del programa Inspira STEAM conozcan las bases del programa, lo que implica participar en él y los pasos a seguir. Pretende dar respuesta, de forma rápida y ágil, a las dudas que van surgiendo a lo largo del proceso.',
			KIT_CENT: 'Kit de Información de Centros',
			KIT_CENT_DESC: 'Este documento contiene información operativa para que los centros del programa Inspira STEAM conozcan las bases del programa, lo que implica participar en él y los pasos a seguir. Pretende dar respuesta, de forma rápida y ágil, a las dudas que van surgiendo a lo largo del proceso.',
			NODISPO: 'No hay materiales disponibles por el momento.',
			DESCARGARTEXT: 'Para descargar el material de las sesiones entra aqui: ',
			SESIONES:'Material sesiones',
			IMPORTANTE:'IMPORTANTE',
			COMPLETAR_PRE: 'completar 3-4 días antes de que el primer grupo haya realizado la sesión 1 de Inspira STEAM.',
			COMPLETAR_POST: 'completar 3-4 días después de que el último grupo experimental haya finalizado la sesión 6 de Inspira STEAM.',
			PRE_BLOQUE: 'Cuestionario PRE, bloque',
			POST_BLOQUE: 'Cuestionario POST, bloque',
			BLOQUE2: 'A completar unas horas o un día más tarde que el bloque 1.'

		},
		HOME: {
			INSCRIPCION: 'Inscripción {{edicion}}',	//NOTA: En 'edición' va la fecha: ej. 2020-2021
			INSCRIPCION_TEXT: '¿Deseas inscribirte en la nueva edición de Inspira STEAM {{nodo}}? Revisa que tu perfil es correcto antes de la inscripción',
			INSCRIPCION_TEXT_AS: 'Para inscribirte en la próxima edición de Inspira STEAM {{nodo}} es necesario que hayas finalizado la anterior, las 6 sesiones en el aula y completado los cuestionarios finales. Si no es así, espera a haberlo hecho para inscribirte. ¡Te esperamos! Si ya has terminado ¡Bienvenida!',
			INSCRIPCION_BTNOK: 'Si, inscribirme',
			INSCRIBIRME: 'Inscribirme',
			BTNCANCEL: 'Cancelar',
			INSCRIPCION_CONFIRM: 'Inscripción correcta',
			INSCRIPCIÓN_CONFIRM_TEXT: 'Te has inscrito correctamente a la edición {{edicion}} de Inspira STEAM {{nodo}}',
			EVENTO: '¿Deseas inscribirte al evento?',
			EVENTO_BTNOK: 'Si, inscribirme',
			EVENTO_CONFIRM: 'Inscripción correcta',
			EVENTO_CONFIRM_TEXT: 'Te has inscrito correctamente al evento.',
			EVENTO_RECH: '¿Confirmas que no vas a asistir al evento?',
			EVENTO_RECH_BTNOK: 'Si, confirmo',
			EVENTO_RECH_CONFIRM: 'Respuesta Guardada',
			EVENTO_RECH_CONFIRM_TEXT: 'Has indicado correctamente que no vas a asistir al evento: {{evento}}',
			INSTR_CERT_TITLE: 'Certificado de delitos de naturaleza sexual',
			INSTR_CERT_1: 'Todas las mentoras y mentores que participan en el programa deben entregar, cada edición, el Certificado de Delitos de Naturaleza Sexual para comprobar que no tienen antecedentes. Este es un requisito de obligado cumplimiento en España para todas las personas que trabajan con menores. Este certificado se deberá entregar en las dos semanas siguientes a realizar la formación. ',
			INSTR_CERT_2: 'Se puede solicitar online o acudiendo directamente a las oficinas correspondientes.',
			INSTR_CERT_3: 'ONLINE',
			INSTR_CERT_4: 'La opción más rápida para obtener el certificado es solicitarlo a través de la web: {{web}} (con el DNI electrónico o con clave)',
			INSTR_CERT_5: 'Para más información consulte directamente la web de la Gerencia Territorial del Ministerio de Justicia de tu territorio o la web del Ministerio de Justicia: {{web}}',
			INSTR_CERT_6: 'PRESENCIAL',
			INSTR_CERT_7: '1. Rellenar este formulario {{formulario}} y entregarlo en la gerencia territorial del Ministerio de Justicia de tu comunidad junto con el DNI (lista de gerencias territoriales por comunidades: {{gerencias}} )',
			INSTR_CERT_8: '2. Presentar la solicitud impresa junto con el DNI en el juzgado de tu ciudad y ellos lo mandarán al Ministerio de Justicia en Madrid (tarda unas 2 semanas).',
			INSTR_CERT_9: 'Más información en la web del Ministerio de Justicia: {{web}}',
			EVENTO_TITLE: 'Detalles del evento',
			EVENTO_FECHA: 'Fecha',
			EVENTO_DESCR: 'Descripción',
			EVENTO_LUGAR: 'Lugar',
			EVENTO_LINK: 'Link',
			EVENTO_INFORMACION: 'Más información acerca del evento',
			EVENTO_CONFIRMADA: 'ASISTENCIA CONFIRMADA',
			EVENTO_CONFIRMAR: 'CONFIRMAR ASISTENCIA',
			EVENTO_RECHAZAR: 'NO ASISTIRÉ',
			EDICION_DISP: 'Edición {{edicion}} disponible',
			EDICION_DISP_TEXT: 'Inscríbete para comenzar una nueva edición de Inspira STEAM',
			CERT_AVISO: 'Debes adjuntar el <b>Certificado de Delitos de Naturaleza Sexual</b> antes de participar en Inspira STEAM en la sección "Mi Perfil"',
			CERT_INSTR: 'Cómo solicitar el certificado',
			FORMACION_MENTORAS: 'Formación de Mentoras',
			FORMACION_CENTROS: 'Eventos disponibles',
			FORMACION_CENTROS_INS: 'Evento inscrito',
			FORMACION_TEXT: 'Debes inscribirte a una de las fechas de evento disponibles',
			FORMACION_DETALLE: 'Detalle',
			MATERIAL: 'Material',
			MATERIAL_TEXT: 'Revisa la documentación disponible',
			PERFIL: 'Mi perfil',
			PERFIL_TEXT: 'Ver mis datos personales',
			INSCRIPCION_NO_MENT: 'INSCRIPCIÓN NO DISPONIBLE: La inscripción de Mentoras no se encuentra abierta en este momento.',
			INSCRIPCION_NO_CENT: 'INSCRIPCIÓN NO DISPONIBLE: La inscripción de Centros no se encuentra abierta en este momento.',
			LINK_SOCIAL: 'Pulsa <a href="http://social.inspirasteam.net/" target="_blank"> aqui</a> para acceder a la <a href="http://social.inspirasteam.net/" target="_blank"> Plataforma Social Inspira STEAM</a>.'
		},
		PERFIL: {
			MENU: {
				EMAIL: 'Email',
				TELEFONO: 'Teléfono',
				NOM_CONT: 'Nombre contacto',
				EMAIL_CONT: 'Email contacto',
				INFORMACION_CENTRO: 'Información del Centro',
				INFORMACION_PERSON: 'Información Personal',
				PASSWORD: 'Cambiar contraseña',
				BAJA: 'Dar de baja',
				CERTIFICADOS: 'Certificados',
				GUARDAR_OK: 'Datos Guardados',
				GUARDAR_OK_TEXT: 'Los datos se han guardado correctamente',
				DATOS_WEB: 'Datos públicos para la web ',
				ASIGNACIONES: 'Asignaciones',
				MENTORASCENTRO: 'Mentoras'
			},
			CERTIFICADOS: {
				CERT_DEL: 'Certificado de Delitos de Naturaleza Sexual',
				INSTR: 'Cómo solicitar el certificado',
				MENSAJE_OK: 'El certificado se ha subido correctamente',
				MENSAJE_NO: 'Pendiente subir el certificado',
				ARRASTRA: 'Arrastra o pulsa aquí para subir tu certificado (PDF)',
				SUBIR: 'Subir Certificado',
				SUBIR_OK: 'Certificado Guardado',
				SUBIR_OK_TEXT: 'El certificado se ha guardado correctamente',
				SUBIR_ERR: 'Formato Incorrecto',
				SUBIR_ERR_TEXT: 'El certificado debe subirse en formato PDF',
				SUBIR_ERR2: 'Archivo no seleccionado',
				SUBIR_ERR2_TEXT: 'Debes seleccionar o arrastrar un archivo en el cuadro de la zona superior'
			},
			COMPROMISOS: {
				COMP_ENT: 'Compromiso de entidades',
				COMP_INFO: 'Qué es el Compromiso de entidades',
				COMP_TEXT1: 'En algunos casos, las organizaciones en las que trabajan las mentoras quieren tener un compromiso más profundo con el proyecto, reconocer y valorar la labor que están realizando estas mujeres y facilitar su participación en el proyecto. Para ello, hemos elaborado el Compromiso Inspira STEAM, un documento donde estas organizaciones se comprometen a: ',
				COMP_BUL1: 'Asumir la dedicación de la mentora a Inspira STEAM dentro de su jornada laboral.',
				COMP_BUL2: 'Facilitar su asistencia a la formación, a las sesiones en los centros escolares y a los actos de difusión que considere relevantes.',
				COMP_BUL3: 'Trabajar para difundir y sensibilizar a su comunidad de los temas y problemática que aborda el programa.',
				COMP_TEXT2: 'Como reconocimiento a este compromiso, el programa visibiliza a estas entidades en la web del proyecto: <a href="https://inspirasteam.net/entidades-compromiso-inspira/" target="_blank"> https://inspirasteam.net/entidades-compromiso-inspira/</a>',
				COMP_DAT: 'Si tu entidad quiere firmar este compromiso, completa los siguientes pasos:',
				TEMP_COMP_TEXT: 'Descarga la plantilla del Compromiso de entidades.',
				SUBIR_TEXT: 'Una vez cumplimentado y firmado por la persona responsable de tu entidad, sube aquí el Compromiso de entidades.',
				MENSAJE_OK: 'El documento se ha subido correctamente',
				MENSAJE_NO: 'Pendiente subir el documento',
				ARRASTRA: 'Arrastra o pulsa aquí para subir el Compromiso de entidades (PDF)',
				SUBIR: 'Subir Compromiso de entidades',
				SUBIR_OK: 'Documento Guardado',
				SUBIR_OK_TEXT: 'El documento se ha guardado correctamente',
				SUBIR_ERR: 'Formato Incorrecto',
				SUBIR_ERR_TEXT: 'El documento debe subirse en formato PDF',
				MAS_INFO: 'Completa los siguientes datos para visibilizar a tu entidad en la <a href="https://inspirasteam.net/entidades-compromiso-inspira/" target="_blank"> web.</a>',
				SUBIR_LOGO: 'Sube el logo de tu entidad a cualquier enlace público (web, drive, dropbox...) y pásanos el enlace.',
				LINK_ENT: 'Pásanos el enlance de la página web de tu entidad.'
			},
			DATOSWEB: {
				SUBTITLE: 'Formulario de recogida de datos para la web: <a href="https://inspirasteam.net/mentoras/" target="_blank"> inspirasteam.net</a> (si ya has sido mentora/mentor revisa tus datos actuales y rellena este formulario solo si deseas cambiarlos)',
				CHARACTER: 'carácteres',
				BIO: 'Bio' ,
				BIO_TEXT: 'Resumen público de tu trayectoria (uno, dos párrafos). Aparecerá junto con las redes sociales cuando alguien pulse tu foto en la web.',
				FOTO: 'Foto',
				FOTO_TEXT: 'Sube tu foto que quieras que aparezca a cualquier enlace público (web, drive, dropbox...) y pásanos el enlace. Editaremos tu foto dentro de un círculo (puedes ver ejemplos en la <a href="https://inspirasteam.net/mentoras/" target="_blank"> web de Inspira STEAM</a>). Si lo dejas vacío, entenderemos que quieres que aparezca el logotipo de InspiraSTEAM en lugar de tu foto.',
				SOCIAL: 'Redes sociales',
				SOCIAL_TEXT: 'Indica tus perfiles en redes sociales (el/los enlaces a tus perfiles): facebook, twitter, linkedin...',
				NAME: 'Nombre',
				NAME_TEXT: 'Tal y como quieres que aparezca en la web.'
			},
			PASSWORD: {
				ACTUAL: 'Contraseña actual',
				NUEVA: 'Nueva contraseña',
				CONFIRM: 'Confirmar contraseña',
				ERROR: 'La contraseña actual introducida no es correcta' ,
				CAMBIO_OK: 'Contraseña Cambiada',
				CAMBIO_OK_TEXT: 'La contraseña se ha modificado correctamente'
			},
			BAJA: {
				AVISO: 'ATENCIÓN: Esta acción es irreversible. Si borras tus datos tendrás que registrarte de nuevo para volver a participar en Inspira STEAM.',
				INFO: 'Si das de baja la cuenta de Inspira STEAM confirmas que no vas a participar en el mentoring. Todos tus datos serán borrados del sistema.',
				BTN: 'BAJA DE INSPIRA STEAM',
				CONFIRMA: '¿Confirmas darte de baja y borrar todos tus datos de Inspira STEAM?',
				CONFIRMA_BTNOK: 'Si, darme baja',
				BAJA_OK: 'Cuenta eliminada',
				BAJA_OK_TEXT: 'Esta cuenta y todos sus datos asociados se han borrado correctamente.'
			}
		},
		FORMULARIOS: {
			MENTORAS: {
				TITULO: 'Registro de Mentora/Mentor',
				NOMBRE: 'Nombre',
				APELLIDOS: 'Apellidos',
				TELEFONO: 'Teléfono',
				ESTUDIOS: 'Estudios o relación con las STEAM',
				ENTIDAD: 'Entidad',
				OPCIONAL: 'Opcional',
				OTRA: 'Otra',
				IDIOMAS: 'En qué idioma(s) puedes hacer las sesiones de Inspira STEAM',
				PROVINCIAS: 'Provincia(s) de preferencia para participar en Inspira STEAM',
				PROVINCIAS_DESC: 'Esta selección es orientativa. Posteriormente se concretará la zona de participación.',
				ZONA: 'Zona, barrio o población de preferencia para participar en Inspira STEAM',
				SESIONESCON: 'Estoy dispuesta a tener sesiones Inspira STEAM con:',
				SESIONESCON_DESC: 'La primera y última sesión son conjuntas, chicas y chicos juntos. De la sesión dos a la cinco se realizan chicas y chicos por separado. Selecciona si de la sesión dos a la cinco quieres trabajar con chicas, con chicos, o con cualquiera de los dos.',
				GRUPOS: 'Estoy dispuesta a realizar las sesiones de Inspira STEAM con más de un grupo',
				GRUPOS_CENTRO: 'del mismo centro',
				GRUPOS_NUMERO: 'Cada grupo son 6 horas',
				EXPERIENCIA: '¿Has sido ya mentora en Inspira STEAM?',
				EDICIONES: 'Ediciones en las que has sido mentora',
				COMENTARIOS: 'Otros comentarios',
				SI: 'Sí',
				NO: 'No',
				NINAS: 'Niñas',
				NINOS: 'Niños',
				GENERO: 'Género',
				GENEROFEM: 'Femenino',
				GENEROMAS: 'Masculino',
				GENEROTRO: 'Otro',
				LOCALIDAD: 'Localidad(es) de preferencia para participar en Inspira STEAM',
				RESIDENCIA: 'Localidad de residencia/trabajo',
				ZONASEUS: 'Zona(s) de preferencia para participar en Inspira STEAM. Por favor, selecciona todas las opciones que puedas.',
				SUBZONASEUS: 'Subzona(s) de preferencia para participar en Inspira STEAM. Por favor, selecciona todas las opciones que puedas.',
				REPETIR: '¿Te gustaría repetir en el mismo centro? Indícanos cuál es: '
			},
			CENTROS: {
				TITULO: 'Registro de Centro',
				NOMBRE: 'Nombre del centro',
				POBLACION: 'Población',
				CURSO: 'Curso',
				EP6: '6º E.P.',
				ESO1: '1º ESO', 
				CONT: 'Persona de contacto',
				CONT_CARGO: 'Cargo de persona de contacto',
				CONT_NOMBRE: 'Nombre y apellidos de persona de contacto',
				CONT_EMAIL: 'Email de persona de contacto',
				CONT_TELEFONO: 'Teléfono de persona de contacto',
				IDIOMAS: 'En qué idioma queréis las sesiones de Inspira STEAM',
				IDIOMAS_DESC: 'Si seleccionas más de un idioma y quieres indicar un orden de preferencia, por favor indícalo en comentarios. Se intenta cumplir este criterio, aunque en ocasiones no es posible por el desequilibrio entre la demanda de los centros y la realidad idiomática de las mentoras. En caso de que solo se haya marcado un idioma y no sea posible cubrir con las mentoras disponibles, el centro puede quedar fuera del programa.',
				GRUPOS: 'Número de grupos',
				GRUPO_NOMBRE: 'Grupo {{num}}. Nombre',
				GRUPO_CHICAS: 'Grupo {{num}}. Nº de Chicas',
				GRUPO_CHICOS: 'Grupo {{num}}. Nº de Chicos',
				COMENTARIOS: 'Otros comentarios',
				PROVINCIA: 'Provincia',
				EXPERIENCIA_CENTRO: '¿Ha participado el centro en ediciones anteriores de Inspira STEAM?',
				EDICIONES_CENTRO: 'Ediciones en las que ha participado',
				DIRECCION: 'Dirección del centro (Tipo de vía, Nombre de la vía, Número, Código Postal, Localidad)',
				WEB: 'Página web del centro (si existe)'
			}
		},
		TOPBAR: {
			SALUDO: 'Hola',
			PERFIL: 'Mi Perfil',
			PERFIL_DETALLE: 'Datos personales y Certificados',
			LOGOUT: 'Cerrar Sesión'
		},

		INPUT: {
			VALIDATION: {
				REQUIRED_FIELD: 'Debes rellenar este campo'
			},
			SUBMIT: 'Guardar Información'
		}
	}
};
