// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, Register, User } from '../../../core/auth/';
import { Subject } from 'rxjs';

import { UserService, AuthenticationService, EdicionService, GestionnodoService} from '../../../_services';

@Component({
  selector: 'kt-startmentora',
  templateUrl: './startmentora.component.html',
  styleUrls: ['./startmentora.component.scss']
})
export class StartmentoraComponent implements OnInit {
  startForm: FormGroup;
	loading = false;
	errors: any = [];
  submitted = false;
  ediciones = [];
  experiencia = {2016: false, 2017: false, 2018: false, 2019: false}
  items: FormArray;
  error = {idioma: false, participa: false, sesiones: false, provincia: false, desplaza: false}
  currentUser: any;
//   localidades = ["Avilés", "Campomanes", "Ceceda", "Gijón", "Grado", "Jarrio", "La Barganiza", "La Pereda", "Langreo", "Lugones", "Navia", "Oviedo", "Proaza", "San Cucao", "Trubia", "Urbanización La Fresneda", "Viella"];
  localidadesItems: FormArray;
  sublocalidadesItems: FormArray;
//   localidadesEus = ["Aramaio", "Murgia", "Santa Cruz de Campezo", "Laudio-Llodio", "Vitoria – Gasteiz", "Abanto-Zierbena", "Balmaseda",
//   "Ortuella", "Sodupe", "Amorebieta-Etxano", "Berriz", "Bakio", "Barakaldo", "Portugalete", "Santurtzi", "Sestao", "Berriatua",
//   "Ondarroa", "Bilbao", "Erandio", "Bakio", "Gernika", "Arratzu", "Getxo", "Leioa", "Sopela",
//   "Loiu", "Aduna", "Beasain", "Tolosa", "Urnieta", "Villabona", "Donostia-San Sebastián", "Errenteria", "Irun", "Orio","Zarautz", "Zumaia"];
//   localidadesEusItems: FormArray;
  zonas = [];
  subzonas = [];
  idiomas = [];
  entidades = [];
  subzonasArray: FormArray;

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private edicionService: EdicionService,
		private userService: UserService,
		private gestionNodoService: GestionnodoService,
		private authenticationService: AuthenticationService
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.currentUser = this.authenticationService.currentUserValue;
		this.initStartForm();
		this.loadZonas();
    	this.loadIdiomas();
    	this.loadEntidades();
	}

	loadEdiciones() {
		console.log("loadEdiciones()");
		this.edicionService.getAllInactive()
	    .subscribe(ediciones => {
			console.log(ediciones);
			this.ediciones = ediciones;
			this.cdr.detectChanges();
			this.items = this.startForm.get('items') as FormArray;
			for(var i = 0; i < this.ediciones.length; i++){
				this.items.push(this.createItem(this.ediciones[i]));
			}
		});
	}

// 	loadPoblaciones() {
// 		this.localidadesItems = this.startForm.get('localidadesItems') as FormArray;
//     if(this.localidadesItems)
//     for(var i = 0; i < this.localidades.length; i++){
// 			this.localidadesItems.push(this.createItemLoc(this.localidades[i]));
// 		}
// 	}
//   loadPoblacionesEus(){
//     this.localidadesEusItems = this.startForm.get('localidadesEusItems') as FormArray;
//     if(this.localidadesEusItems)
//       for(var i = 0; i < this.localidadesEus.length; i++){
//         this.localidadesEusItems.push(this.createItemLoc(this.localidadesEus[i]));
//       }
//       this.cdr.detectChanges();
//   }
zonasName = [];
zonasSel = {};
zonaSeleccionada = false;
  loadZonas(){
	this.gestionNodoService.getZonasByNodo(this.currentUser.nodo)
    .subscribe(zonas => {
      this.zonas = zonas;
      for(var i = 0; i < zonas.length; i++) {
        this.zonasName[zonas[i].id+""] = zonas[i];
      }
	  this.localidadesItems = this.startForm.get('localidadesItems') as FormArray;
		if(this.localidadesItems) {
			for(var i = 0; i < this.zonas.length; i++) {
				this.localidadesItems.push(this.createItemLoc(this.zonas[i].nombre, null, this.zonas[i].id));
			}
		}

		this.startForm.get("localidadesItems").valueChanges.subscribe(x => {

			this.zonaSeleccionada = false;
			for(var i = 0; i < x.length; i++) {
				this.zonasSel[x[i].zonaId + ""] = x[i].desplaza;
				if(x[i].desplaza ) {
					this.zonaSeleccionada = true;
				}
			}
			this.cdr.detectChanges();
		 })
      	this.cdr.detectChanges();
    });
  }
 
  loadSubzonas(){
	//return;	//Temporalmente desactivado
	this.gestionNodoService.getSubzonasByNodo(this.currentUser.nodo)
    .subscribe(subzonas => {
		this.subzonas = subzonas;
		this.sublocalidadesItems = this.startForm.get('sublocalidadesItems') as FormArray;
		if(this.sublocalidadesItems) {
			for(var i = 0; i < this.subzonas.length; i++) {
				this.sublocalidadesItems.push(this.createItemLoc(this.subzonas[i].nombre, this.subzonas[i].zona, null));
			}
		}
		
      	this.cdr.detectChanges();
    });
	
  }

  loadIdiomas(){
	this.gestionNodoService.getIdiomasByNodo(this.currentUser.nodo)
    .subscribe(idiomas => {
      this.idiomas = idiomas;
      this.cdr.detectChanges();
    });
  }

  loadEntidades(){
	this.gestionNodoService.getEntidadesByNodo(this.currentUser.nodo)
    .subscribe(entidades => {
      this.entidades = entidades;
      this.cdr.detectChanges();
    });
  }


	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  	}

	createItem(edicion: any): FormGroup {
		return this.fb.group({
			edicion: edicion.name,
			participa: false
		});
	}

	createItemLoc(localidad: any, zona: any, zonaId: any): FormGroup {
		return this.fb.group({
			nombre: localidad,
			desplaza: false,
			zona: zona,
			zonaId: zonaId
		});
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initStartForm() {
		if(this.currentUser.nodo == 1) {	//Euskadi
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				entidad: [''],
				entidadopt: [''],
				idiomaEu: [false],
				idiomaEs: [false],
				idiomaEn: [false],
				sesionesNinas: [false],
				sesionesNinos: [false],
				provAra: [false],
				provBiz: [false],
				provGip: [false],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				repCent: [''],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 3){	//Asturies
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				entidad: [''],
				entidadopt: [''],
				sesionesNinas: [false],
				sesionesNinos: [false],
				zona: ['', Validators.compose([Validators.required])],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				items: this.fb.array([ ]),
				repCent: [''],
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		}else if(this.currentUser.nodo == 7 || this.currentUser.nodo == 8 || this.currentUser.nodo == 9 || this.currentUser.nodo == 11 || this.currentUser.nodo == 12) { //Murcie, Jaen, Txile y Pontevedra
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				entidad: [''],
				entidadopt: [''],
				sesionesNinas: [false],
				sesionesNinos: [false],
				//zona: ['', Validators.compose([Validators.required])],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				repCent: [''],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 4 || this.currentUser.nodo == 5) {	//Barcelona y Tarragona
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				entidad: [''],
				entidadopt: [''],
				idiomaEs: [false],
				idiomaEn: [false],
				idiomaCat: [false],
				sesionesNinas: [false],
				sesionesNinos: [false],
				//zona: ['', Validators.compose([Validators.required])],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				repCent: [''],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 6) {	//Canarias
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				entidad: [''],
				entidadopt: [''],
				sesionesNinas: [false],
				sesionesNinos: [false],
				provTen: [false],
				provGca: [false],
				//zona: ['', Validators.compose([Validators.required])],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				repCent: [''],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 2) {	//Madrid
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				entidad: [''],
				entidadopt: [''],
				idiomaEs: [false],
				idiomaEn: [false],
				sesionesNinas: [false],
				sesionesNinos: [false],
				//zona: ['', Validators.compose([Validators.required])],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				repCent: [''],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 10) {	//Inspira STEAM +
			this.startForm = this.fb.group({
				telefono: ['', Validators.compose([Validators.required])],
				estudios: ['', Validators.compose([Validators.required])],
				idiomaEs: [false],
				idiomaEn: [false],
				sesionesNinas: [false],
				sesionesNinos: [false],
				prov: ['', Validators.compose([Validators.required])],
				masGrupos: ['', Validators.compose([Validators.required])],
				expMentora: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				genero: ['', Validators.compose([Validators.required])],
				repCent: [''],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		}	else if(this.currentUser.nodo != 1 && this.currentUser.nodo != 2 &&this.currentUser.nodo != 3 &&this.currentUser.nodo != 4 && this.currentUser.nodo != 5 && 
			this.currentUser.nodo != 6 &&this.currentUser.nodo != 7 && this.currentUser.nodo != 8 && this.currentUser.nodo != 9 && this.currentUser.nodo != 10 && this.currentUser.nodo != 11 && this.currentUser.nodo != 12){
				this.startForm = this.fb.group({
					telefono: ['', Validators.compose([Validators.required])],
					estudios: ['', Validators.compose([Validators.required])],
					entidad: [''],
					entidadopt: [''],
					sesionesNinas: [false],
					sesionesNinos: [false],
					zona: [''],
					masGrupos: ['', Validators.compose([Validators.required])],
					expMentora: ['', Validators.compose([Validators.required])],
					comentarios: [''],
					genero: ['', Validators.compose([Validators.required])],
					items: this.fb.array([ ]),
					repCent: [''],
					localidadesItems: this.fb.array([ ]),
					sublocalidadesItems: this.fb.array([ ])
				});
				
		}
		this.loadEdiciones();
		this.loadSubzonas();
		// this.loadPoblaciones();
		// this.loadPoblacionesEus();

  	}

	/**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.submitted = true;

		const controls = this.startForm.controls;

		console.log(controls);

		if(this.currentUser.nodo == 1 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaEu) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}
		if((this.currentUser.nodo == 4 || this.currentUser.nodo == 5) && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaCat) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}
		if(this.currentUser.nodo == 2 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if(!this.startForm.value.sesionesNinas && !this.startForm.value.sesionesNinos) {
			this.error.sesiones = true;
		} else {
			this.error.sesiones = false;
		}

		if(this.currentUser.nodo == 1) {
			if(!this.startForm.value.provAra && !this.startForm.value.provBiz && !this.startForm.value.provGip) {
				this.error.provincia = true;
			} else {
				this.error.provincia = false;
			}
		}
		if(this.currentUser.nodo == 6) {
			if(!this.startForm.value.provTen && !this.startForm.value.provGca) {
				this.error.provincia = true;
			} else {
				this.error.provincia = false;
			}
		}

		var ediciones = [];

		if(this.startForm.value.expMentora == "si") {
			console.log(this.startForm.value);
			var items = this.startForm.value.items;
			var selected = false;
			ediciones = [];
			for(var i = 0; i < items.length; i++) {
				if(items[i].participa) {
					selected = true;
					ediciones.push(items[i].edicion); //ya ha elegido edicion
				}
			}
			if(!selected) {
				this.error.participa = true;
			} else {
				this.error.participa = false;
			}
		} else {
			this.error.participa = false;
		}

		var localidades = [];

	// 	if(this.currentUser.nodo == 3) {
	// 		var localidadesItems = this.startForm.value.localidadesItems;
	// 		var selectedLoc = false;
	// 		localidades = [];
	// 		for(var i = 0; i < localidadesItems.length; i++) {
	// 			if(localidadesItems[i].desplaza) {
	// 				selectedLoc = true;
	// 				localidades.push(localidadesItems[i].nombre);
	// 			}
	// 		}
	// 		if(!selectedLoc) {
	// 			this.error.desplaza = true;
	// 		} else {
	// 			this.error.desplaza = false;
	// 		}
	// 	} else {
	// 		this.error.desplaza = false;
	// 	}

    	if(this.zonas.length != 0) {
			var localidadesItems = this.startForm.value.localidadesItems;
			var selectedLoc = false;
			localidades = [];
			for(var i = 0; i < localidadesItems.length; i++) {
				if(localidadesItems[i].desplaza) {
					selectedLoc = true;
					localidades.push(localidadesItems[i].nombre);
				}
			}
			if(!selectedLoc) {
				this.error.desplaza = true;
			} else {
				this.error.desplaza = false;
			}
		} else {
			this.error.desplaza = false;
		}


		var subzonas = [];

		if(this.subzonas.length != 0) {
			var sublocalidadesItems = this.startForm.value.sublocalidadesItems;
			subzonas = [];
			for(var i = 0; i < sublocalidadesItems.length; i++) {
				if(sublocalidadesItems[i].desplaza) {
					subzonas.push(sublocalidadesItems[i].nombre);
				}
			}
		}



		// check form
		if (this.startForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if(this.error.idioma || this.error.participa || this.error.sesiones || this.error.provincia || this.error.desplaza){
			console.log(this.error);
			return;
		}

		this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
		var form = this.startForm.value;
		form.localidades = JSON.stringify(localidades);
		form.subzonas = JSON.stringify(subzonas);
		if(form.expMentora == "si") {
			form.expMentora = true;
			form.ediciones = JSON.stringify(ediciones);
		} else {
			form.expMentora = false;
			form.ediciones = null;
		}
		if(form.masGrupos == "si") form.masGrupos = true;
		else form.masGrupos = false;
		if(form.entidadopt != null && form.entidadopt != "") {
			if(form.entidadopt != "Otra") form.entidad = form.entidadopt;
		}

		if(form.prov == "Madrid") form.provMad = true
		else if(form.prov == "Barcelona") form.provBcn = true;

		form.id = currentUser.id;
		form.estado = "PRE-INSCRITA";

		form.nuevoUsuario = true;

		console.log(form);

		this.userService.update(form)
            .pipe(first())
            .subscribe(
                data => {
					console.log("OK", data);
					currentUser.comentarios = form.comentarios;
					currentUser.ediciones = form.ediciones;
					currentUser.estado = form.estado;
					currentUser.estudios = form.estudios;
					currentUser.expMentora = form.expMentora;
					if(form.idiomaEn) currentUser.idiomaEn = form.idiomaEn;
					if(form.idiomaEs) currentUser.idiomaEs = form.idiomaEs;
					if(form.idiomaEu) currentUser.idiomaEu = form.idiomaEu;
					if(form.idiomaCat) currentUser.idiomaCat = form.idiomaCat;
					if(form.entidad) currentUser.entidad = form.entidad;
					if(form.entidadopt) currentUser.entidadopt = form.entidadopt;
					if(form.provAra) currentUser.provAra = form.provAra;
					if(form.provBiz) currentUser.provBiz = form.provBiz;
					if(form.provGip) currentUser.provGip = form.provGip;
					if(form.provTen) currentUser.provTen = form.provTen;
					if(form.provGca) currentUser.provGca = form.provGca;
					if(form.provMad) currentUser.provMad = form.provMad;
					if(form.provBcn) currentUser.provBcn = form.provBcn;
					currentUser.masGrupos = form.masGrupos;
					currentUser.sesionesNinas = form.sesionesNinas;
					currentUser.sesionesNinos = form.sesionesNinos;
					currentUser.telefono = form.telefono;
					currentUser.nuevoUsuario = true;
					currentUser.genero = form.genero;
					if(form.localidades) currentUser.localidades = form.localidades;
					if(form.subzonas) currentUser.subzonas = form.subzonas;
					if(form.zona) currentUser.zona = form.zona;
					if(form.preFin) currentUser.preFin = form.preFin;
					if (form.repCent) currentUser.repCent = form.repCent;

					this.authenticationService.changeUser(currentUser);






					this.router.navigate(['/home']);
                },
                error => {
                    console.error(error);
					//this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
					this.loading = false;
                });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.startForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
	}
}
