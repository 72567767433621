import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
 
@Injectable()
export class InscritoService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/inscritos`);
    }

    getByEvento(evento: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/inscritos?evento=${evento}`);
    }

    getByUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/inscritos?user=${user}`);
    }

    getByUserAsiste(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/inscritos?asiste=1&user=${user}`);
    }
    
    getByUserAsistencia(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/inscritos?asistencia=1&user=${user}`);
    }

    getById(id: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/inscritos/${id}`);
    }

    post(ins: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/inscritos`, ins);
    }

    update(ins: any) {
        return this.http.put(`${environment.apiUrl}/api/inscritos/${ins.id}`, ins, {observe: 'response'});
    }
    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/inscritos/${id}`);
    }

    /*getAllPublished(tipo: string) {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1&type=' + tipo);
    }

    getAllPublished1() {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1');
    }
 
    getById(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges/' + id);
    }

    getByCreator(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id);
    }

    getByCreatorOtherPublic(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=public&published=1');
    }

    getByCreatorOtherOfficial(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=official&published=1');
    }
 
    
 
    update(challenge: Challenge) {
        return this.http.put(this.api + '/api/challenges/' + challenge.id, challenge);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/challenges/' + id);
    }*/
}