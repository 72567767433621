import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, EdicionService, NodoService, DocumentoService, LogService } from '../../../_services';

import { ConfirmPasswordValidator } from './confirm-password.validator';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';

@Component({
  selector: 'kt-controlpanel',
  templateUrl: './controlpanel.component.html',
  styleUrls: ['./controlpanel.component.scss']
})
export class ControlpanelComponent implements OnInit {
  passwordForm: FormGroup;
  edicionForm: FormGroup;
  nodoForm: FormGroup;
  adminForm: FormGroup;
  vista = "nuevonodo";
  loading = false;
  loadingAdmin = false;
  loadingNodo = [];
  submitted = false;
  currentUser: any;
  admins = [];
  rows = [];
  rowsNodos = [];
  selectedValue: any;
  selectedEdicion: any;
  selectedNodo: any;
  selectedIndex: any;

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('certificadoSwal', {static: false}) private certificadoSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('compromisoSwal', {static: false}) private compromisoSwal: SwalComponent
  @ViewChild('errorCompFileSwal', {static: false}) private errorCompFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('bajaSwal', {static: false}) private bajaSwal: SwalComponent
  @ViewChild('bajaConfirmSwal', {static: false}) private bajaConfirmSwal: SwalComponent
  @ViewChild('changeSwal', {static: false}) private changeSwal: SwalComponent

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private userService: UserService,
    private documentoService: DocumentoService,
	  private fb: FormBuilder,
	  private translate: TranslateService,
    private cdr: ChangeDetectorRef,
	  private modalService: NgbModal,
	  private logService: LogService,
    private router: Router) {
      this.unsubscribe = new Subject();
    }

    openCentred(content) {
      this.modalService.open(content, { centered: true, size: 'lg' } );
    }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getAdmins();
    this.loadEdiciones();
    this.loadNodos();
    this.initEdicionForm();
    this.initNodoForm();
    this.initPasswordForm();
    this.initAdminForm();
  }

  cambioVista(vista: any) {
    this.vista = vista;
  }
  
	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  edicionActive: any;
  loadEdiciones(){
    this.edicionService.getAll()
      .subscribe(ediciones => {
        this.rows = ediciones;
        this.cdr.detectChanges();
      });
    this.edicionService.getActive()
    .subscribe(data => {
      for(let edicionActive of data)
      this.edicionActive = edicionActive.id;
        this.cdr.detectChanges();
    });
  }

  loadNodos(){
    this.nodoService.getAll()
      .subscribe(nodos => {
        this.rowsNodos = nodos;
        this.cdr.detectChanges();
      });
  }

  getAdmins() {
    this.userService.getByRol('admin')
      .subscribe(admins =>{
        this.admins = admins;
        this.cdr.detectChanges();
      });
  }

  initNodoForm() {
    this.nodoForm = this.fb.group({

      name: ['']
      //isActive: [false, Validators.compose([Validators.required])],
      //registroActivoMent: [false, Validators.compose([Validators.required])],
      //registroActivoCent: [false, Validators.compose([Validators.required])]
    });
  }

  crearNodo() {
    if(this.loading) return;
  
    console.log("crearNodo()");
  
    this.submitted = true;
  
    // const controls = this.passwordForm.controls;
  
    // check form
    // if (this.passwordForm.invalid) {
    //   Object.keys(controls).forEach(controlName =>
    //     controls[controlName].markAsTouched()
    //   );
    //   return;
    // }
    var newnodo = this.nodoForm.value;
    newnodo.edicion = this.edicionActive;

    this.loading = true;
    console.warn(newnodo);
    this.nodoService.post(newnodo)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          this.loading = false;
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
        });
  }

  changeNodoSettings(nodo){
    this.selectedIndex = nodo.id;
    this.loadingNodo[this.selectedIndex] = true;
    this.selectedNodo = nodo;

    var formNodo = {id: this.selectedNodo.id, edicion: this.selectedNodo.edicion, isActive: JSON.parse(this.selectedNodo.isActive), registroActivoMent: JSON.parse(this.selectedNodo.registroActivoMent), registroActivoCent: JSON.parse(this.selectedNodo.registroActivoCent) };
    
    this.nodoService.update(formNodo)
    .subscribe(
      data =>{
        this.loadingNodo[this.selectedIndex] = false;
        this.cdr.detectChanges;
      },
			error => {
				console.error(error);
			});
  }

  initEdicionForm() {
    this.edicionForm = this.fb.group({

      name: ['', Validators.compose([Validators.required])],
      isActive: ['', Validators.compose([Validators.required])]
    });
  }

  crearEdicion() {
    if(this.loading) return;
  
    console.log("crearEdicion()");
  
    this.submitted = true;
  
    // const controls = this.passwordForm.controls;
  
    // check form
    // if (this.passwordForm.invalid) {
    //   Object.keys(controls).forEach(controlName =>
    //     controls[controlName].markAsTouched()
    //   );
    //   return;
    // }
    var edicion = this.edicionForm.value;

    this.loading = true;
  
    this.edicionService.post(edicion)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          this.loading = false;
        },
        error => {
          console.error(error);
        });
  }
  //currentRowNumber = [];
  changeEdicionActiva(edicion){
    //this.currentRowNumber[edicion.id] = true;
    this.selectedEdicion = edicion;

    var formEdicion = {id: this.selectedEdicion.id, isActive: JSON.parse(this.selectedEdicion.isActive)};
    
    this.edicionService.update(formEdicion)
    .subscribe(
      data =>{
        //this.currentRowNumber[formEdicion.id] = false;
        this.cdr.detectChanges;
      },
			error => {
        this.cdr.detectChanges();
				console.error(error);
			});
  }

  initPasswordForm() {
    this.passwordForm = this.fb.group({

      id:[null, Validators.compose([Validators.required])],
      oldpassword: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required,Validators.minLength(4),Validators.maxLength(100)])],
      confirmPassword: ['', Validators.compose([Validators.required,Validators.minLength(4),Validators.maxLength(100)])]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }
  
  
    errorPass = null;
  
    changePassword(){
      if(this.loading) return;
    
      console.log("changePassword()");
    
      this.submitted = true;
    
      const controls = this.passwordForm.controls;
    
      // check form
      if (this.passwordForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        return;
      }
      var user = this.passwordForm.value;
    
      if(user.oldpassword == user.password){
        this.errorPass = "La contraseña antigua y la nueva no pueden ser iguales";
        return;
      }
    
      this.loading = true;
    
      //user.id = JSON.parse(user.admin);
    
      this.userService.changepassword(user)
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);
            this.loading = false;
            if(data["error"] == "PASSWORD"){
              console.log("ERROR!!");
              this.errorPass = this.translate.instant('PERFIL.PASSWORD.ERROR');
              this.cdr.detectChanges();
            } else {
              this.errorPass = null;
              this.initPasswordForm();
              this.changeSwal.fire();
              this.logService.post({tipo: "PASSWORD-CHANGE", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
                .pipe(first()).subscribe();
                this.cdr.detectChanges();
            }
          },
          error => {
            console.error(error);
          });
    }
  
    isControlPasswordHasError(controlName: string, validationType: string): boolean {
    const control = this.passwordForm.controls[controlName];
    if (!control || !this.submitted) {
      return false;
    }
  
    if(!validationType) {
      const result = control.invalid && (control.dirty || control.touched);
      return result;
    } else {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
  }

  initAdminForm() {

    this.adminForm = this.fb.group({
      nodo: ['', Validators.compose([Validators.required])],
      name: ['', Validators.compose([Validators.required])],
			username: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(100)
			])
			]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  crearAdmin(){
    if(this.loadingAdmin) return;
  
    console.log("crearAdmin()");

    const controls = this.adminForm.controls;

		// check form
		if (this.adminForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loadingAdmin = true;

    var user = this.adminForm.value;
    //this.userObj = user;
    user.apellidos = 'ADMIN';
    user.nodo = this.adminForm.value.nodo;
    //user.name = this.rowsNodos[this.adminForm.value.nodo].name;
    user.lang = 'es';
    user.estado = 'ADMIN';
    user.role = 'admin';

    this.userService.register(user)
      .pipe(first())
      .subscribe(
          data => {
            console.log("OK", data);
            this.userUpdate(data);
            this.loadingAdmin = false;
            this.cdr.detectChanges();
            
          },
          error => {
            console.error(error);
          });
  }

  userUpdate(user){
    var userEstado = {id: user.id, estado: 'ADMIN'};
    this.userService.update(userEstado)
    .pipe(first())
      .subscribe();

  }
  /**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */

  isControlAdminHasError(controlName: string, validationType: string): boolean {
		const control = this.adminForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
	}

}
