import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    register(user: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded'
          })
        };
        const body = new HttpParams()
            .set('username', user.username)
            .set('password', user.password)
            .set('name', user.name)
            .set('apellidos', user.apellidos)
            .set('lang', user.lang)
            .set('nodo', user.nodo)
            .set('role', user.role);
        return this.http.post(`${environment.apiUrl}/register`, body.toString(), httpOptions);
    }

    changepassword1(user: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded'
          })
        };
        const body = new HttpParams()
            .set('id', user.id)
            .set('password', user.password)
            .set('oldpassword', user.oldpassword);
        return this.http.post(`${environment.apiUrl}/changepassword`, body.toString(), httpOptions);
    }

    changepassword(user: any) {
        return this.http.post(`${environment.apiUrl}/changepassword`, user);
    }

    recoverpassword(email: any) {
        return this.http.get(`${environment.apiUrl}/recovery?email=${email}`);
    }

    changepasswordrec(user: any) {
        return this.http.post(`${environment.apiUrl}/changepasswordrec`, user);
    }

    update(user: any) {
        //console.log("UPDATE", `${environment.apiUrl}/api/users/${user.id}`, user);
        return this.http.put(`${environment.apiUrl}/api/users/${user.id}`, user, {observe: 'response'});
    }

    getUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/usuario/${user}`);
    }

    getByRol(rol: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/usuariosrol/${rol}`);
    }

    getByRolNodo(rol: any, nodo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/usuariosrol/${rol}/${nodo}`);
    }

    getAllActive() {
        return this.http.get<any[]>(`${environment.apiUrl}/usuariosactivos`);
    }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/users`);
    }
    
    deleteEventNodos(evento: any) {
        return this.http.post(`${environment.apiUrl}/deleteeventnodos/${evento}`, evento);
    }

    mailEdicion(edicion: any) {
        return this.http.post(`${environment.apiUrl}/inscripcionedicion`, edicion);
    }

    mailEdicionCentro(edicion: any) {
        return this.http.post(`${environment.apiUrl}/inscripcionedicioncentro`, edicion);
    }

    mailEdicionNuevo(edicion: any) {
        return this.http.post(`${environment.apiUrl}/inscripcionedicionnuevo`, edicion);
    }

    /*getAll() {
        return this.http.get<any[]>(this.api + '/usuarios');
    }

    getByGroup(grupo: number) {
        return this.http.get<any[]>(this.api + '/usuariosgrupo/' + grupo);
    }
 
    getById(id: number) {
        return this.http.get<any>(this.api + '/usuario/' + id);
    }
 
    

    tos(user: User) {
        return this.http.put(this.api + '/api/users/' + user.id, {tos: true});
    }
 
    update(user: any) {
        return this.http.put(this.api + '/api/users/' + user.id, user);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/users/' + id);
    }*/
}