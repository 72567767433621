import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
 
@Injectable()
export class GruposService {
    constructor(private http: HttpClient) { }

    getByCuestionario(cuest: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/${cuest}`);  
    }
    getByCuestionario1(){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est1s`);  
    }
    getByCuestionario2(){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est2s`);  
    }
    getByTipo(cuest: any,tipo: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/${cuest}?tipo=${tipo}`);  
    }
    getByCentro(cuest: any, tipo: any, centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/${cuest}?tipo=${tipo}&centro=${centro}`);  
    }
    getOneByTipo(tipo: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est1s?tipo=${tipo}`);  
    }
    getTwoByTipo(tipo: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est2s?tipo=${tipo}`);  
    }
    getOnePreByCentro(centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est1s?pre&centro=${centro}`);  
    }
    getTwoPreByCentro(centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est2s?pre&centro=${centro}`);  
    }
    getOnePostByCentro(centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est1s?post&centro=${centro}`);  
    }
    getTwoPostByCentro(centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/api/cuest_est2s?post&centro=${centro}`);  
    }
}