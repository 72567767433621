// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, Register, User } from '../../../core/auth/';
import { Subject } from 'rxjs';

import { UserService, AuthenticationService, EdicionService } from '../../../_services';

@Component({
  selector: 'kt-startcentro',
  templateUrl: './startcentro.component.html',
  styleUrls: ['./startcentro.component.scss']
})
export class StartcentroComponent implements OnInit {

  startForm: FormGroup;
	loading = false;
	errors: any = [];
  submitted = false;
  ediciones = [];
  items: FormArray;
  edicionesItem: FormArray;
  errorGrupos = [];
  error = {idioma: false, participa: false}
  currentUser: any;
  
  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private edicionService: EdicionService,
		private userService: UserService,
		private authenticationService: AuthenticationService
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.currentUser = this.authenticationService.currentUserValue;
		this.initStartForm();
	}

	loadEdiciones() {
		console.log("loadEdiciones()");
		this.edicionService.getAllInactive()
	    .subscribe(ediciones => {
			console.log(ediciones);
			this.ediciones = ediciones;
			this.cdr.detectChanges();
			this.edicionesItem = this.startForm.get('edicionesItem') as FormArray;
			for(var i = 0; i < this.ediciones.length; i++){
				this.edicionesItem.push(this.createItemEdicion(this.ediciones[i]));
			}
		});
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }
  
  createItem(): FormGroup {
    return this.fb.group({
		nombre: [null, Validators.compose([Validators.required])],
		chicas: [null, Validators.compose([Validators.required])],
		chicos: [null, Validators.compose([Validators.required])],
    });
  }
  createItemEdicion(edicion: any): FormGroup {
	return this.fb.group({
	edicion: edicion.name,
	participa: false
	});
}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initStartForm() {
		if(this.currentUser.nodo == 1) {
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				curso:  ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				provincia: ['', Validators.compose([Validators.required])],
				/*idiomaEu: [false],
				idiomaEs: [false],
				idiomaEn: [false],*/
				idioma: [''],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		} else if(this.currentUser.nodo == 3) {	//Asturies
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		} else if(this.currentUser.nodo == 4 || this.currentUser.nodo == 5 || this.currentUser.nodo == 10) {	//Barcelona y Plus
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				curso:  ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				provincia: ['', Validators.compose([Validators.required])],
				idiomaCat: [false],
				idiomaEs: [false],
				idiomaEn: [false],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		} else if(this.currentUser.nodo == 6 || this.currentUser.nodo == 7 || this.currentUser.nodo == 8) {	//Canarias, Murtxia, Jaén
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				curso:  ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				provincia: ['', Validators.compose([Validators.required])],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
			
		} else if(this.currentUser.nodo == 11 || this.currentUser.nodo == 12) {	//Pontevedra y Ourense
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				curso:  ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				zona: ['', Validators.compose([Validators.required])],
				expCentro: ['', Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		} else if(this.currentUser.nodo == 2) {	//Madrid
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				curso:  ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				provincia: ['', Validators.compose([Validators.required])],
				idiomaEs: [false],
				idiomaEn: [false],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		} else if(this.currentUser.nodo == 9) {	//Txile
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				provincia: ['', Validators.compose([Validators.required])],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		} else if(this.currentUser.nodo != 1 && this.currentUser.nodo != 2 &&this.currentUser.nodo != 3 &&this.currentUser.nodo != 4 && this.currentUser.nodo != 5 && 
			this.currentUser.nodo != 6 && this.currentUser.nodo != 7 && this.currentUser.nodo != 8 && this.currentUser.nodo != 9 && this.currentUser.nodo != 10 && this.currentUser.nodo != 11 && this.currentUser.nodo != 12) {
			this.startForm = this.fb.group({
				poblacion: ['', Validators.compose([Validators.required])],
				curso:  ['', Validators.compose([Validators.required])],
				personaContacto: ['', Validators.compose([Validators.required])],
				nombreContacto: ['', Validators.compose([Validators.required])],
				emailContacto: ['', Validators.compose([Validators.required])],
				telefono: ['', Validators.compose([Validators.required])],
				provincia: ['', Validators.compose([Validators.required])],
				idiomaEs: [false],
				idiomaEn: [false],
				grupos: ['', Validators.compose([Validators.required])],
				comentarios: [''],
				items: this.fb.array([ ]),
				dirCentro: ['', Validators.compose([Validators.required])],
				webCentro: ['']
			});
		}
		if (this.currentUser.nodo == 11|| this.currentUser.nodo == 12){
			this.loadEdiciones();
		}
    	this.items = this.startForm.get('items') as FormArray;
  	}

  gruposCambiados() {
    var grupos = this.startForm.value.grupos;
    console.log(grupos);
    if(grupos < this.items.length) {
      var size = this.items.length;
      for(var i = size; i > grupos; i--){
        this.items.removeAt(i-1);
      }
    } else if(grupos > this.items.length) {
      for(var i = this.items.length; i < grupos; i++){
        this.items.push(this.createItem());
      }
    }


  }

	/**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.submitted = true;
		
		const controls = this.startForm.controls;

		if(this.currentUser.nodo == 1 && !this.startForm.value.idioma/*En && !this.centroForm.value.idiomaEs && !this.centroForm.value.idiomaEu*/) {
			this.error.idioma = true;
		} else if((this.currentUser.nodo == 4 || this.currentUser.nodo == 5 || this.currentUser.nodo == 10) && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaCat) {
			this.error.idioma = true;
		} else if(this.currentUser.nodo == 2 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		var ediciones = [];

		if((this.currentUser.nodo == 11 || this.currentUser.nodo == 12) && this.startForm.value.expCentro == "si") {
			console.log(this.startForm.value);
			var edicionesItem = this.startForm.value.edicionesItem;
			var selected = false;
			ediciones = [];
			for(var i = 0; i < edicionesItem.length; i++) {
				if(edicionesItem[i].participa) {
					selected = true;
					ediciones.push(edicionesItem[i].edicion); //ya ha elegido edicion
				}
			}
			if(!selected) {
				this.error.participa = true;
			} else {
				this.error.participa = false;
			}
		} else {
			this.error.participa = false;
		}

		// check form
		if (this.startForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if(this.error.idioma || this.error.participa){
			return;
		}
		
    	this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
		var form = this.startForm.value;
		form.id = currentUser.id;
		form.estado = "PRE-INSCRITO";
		form.grupos = parseInt(form.grupos);
		form.gruposArray = JSON.stringify(form.items);
		if(form.expCentro == "si") {
			form.expCentro = true;
			form.ediciones = JSON.stringify(ediciones);
		} else {
			form.expCentro = false;
			form.ediciones = null;
		}
		
		this.userService.update(form)
		.pipe(first())
		.subscribe(
        data => {
          console.log("OK", data);
          
			currentUser.comentarios = form.comentarios;
			if(form.curso) currentUser.curso = form.curso;
			currentUser.estado = form.estado;
			currentUser.grupos = form.grupos;
			if(form.idiomaEn) currentUser.idiomaEn = form.idiomaEn;
			if(form.idiomaEs) currentUser.idiomaEs = form.idiomaEs;
			if(form.idiomaEu) currentUser.idiomaEu = form.idiomaEu;
			if(form.idiomaCat) currentUser.idiomaCat = form.idiomaCat;
			if(form.idioma) currentUser.idioma = form.idioma;
			currentUser.gruposArray = form.gruposArray;
			currentUser.personaContacto = form.personaContacto;
			currentUser.nombreContacto = form.nombreContacto;
			currentUser.emailContacto = form.emailContacto;
			currentUser.poblacion = form.poblacion;
			if(form.provincia) currentUser.provincia = form.provincia;
			currentUser.telefono = form.telefono;
			if(form.ediciones) currentUser.ediciones = form.ediciones;
			if(form.expCentro) currentUser.expCentro = form.expCentro;
			if(form.zona) currentUser.zona = form.zona;
			if(form.dirCentro) currentUser.dirCentro = form.dirCentro;
			if(form.webCentro) currentUser.webCentro = form.webCentro;
			
			this.authenticationService.changeUser(currentUser);

			this.router.navigate(['/home']);
        },
        error => {
          console.error(error);
          //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
          this.loading = false;
        });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.startForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  isControlHasErrorGrupos(num: any, controlName: string, validationType: string): boolean {
		const control = this.startForm.controls.items['controls'][num].controls[controlName];
		if (!control || !this.submitted) {
			return false;
    	}
    
		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType);
			return result;
		}
	}
}
