import { Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import {CdkAccordionModule} from '@angular/cdk/accordion';

import { UserService, AuthenticationService, EdicionService, NodoService, DocumentoService, LogService, GestionnodoService } from '../../../_services';


@Component({
  selector: 'kt-gestionnodo',
  templateUrl: './gestionnodo.component.html',
  styleUrls: ['./gestionnodo.component.scss']
})
export class GestionnodoComponent implements OnInit {
  currentUser: any;
  zonas = [];
  subzonas = [];
  idiomas = [];
  entidades = [];
  panelExpandedZonas = false;
  panelExpandedSubzonas = false;
  panelExpandedIdiomas = false;
  panelExpandedEntidades = false;

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private userService: UserService,
	  private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private gestionNodoService: GestionnodoService,
    private router: Router) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getZonas();
    this.getSubzonas();
    this.getIdiomas();
    this.getEntidades();
  }
  
  zonasName = [];
  getZonas(){
    this.gestionNodoService.getZonasByNodo(this.currentUser.nodo)
    .subscribe(zonas => {
      this.zonas = zonas;
      for(var i = 0; i < zonas.length; i++) {
        this.zonasName[zonas[i].id+""] = zonas[i];
      }
      this.cdr.detectChanges();
    });
  }
  crearZona(zona){
    var newZona = {nombre: zona, nodo: this.currentUser.nodo}
    this.gestionNodoService.postZona(newZona)
    .pipe(first())
    .subscribe(
      data=> {
        console.log(data);
        this.cdr.detectChanges();
        this.getZonas();
      });
  }

  borrarZona(zona){
    this.gestionNodoService.deleteZona(zona.id)
    .subscribe(data => {
      for(var i = 0; i < this.zonas.length; i++){
        if(this.zonas[i].id == zona.id){
          this.zonas.splice(i, 1)
          var datosZonas = [...this.zonas];
          this.zonas = [];
          this.zonas = datosZonas;
          this.cdr.detectChanges();
          break;
      }
    }
    });
  }

  getSubzonas(){
    this.gestionNodoService.getSubzonasByNodo(this.currentUser.nodo)
    .subscribe(subzonas => {
      this.subzonas = subzonas;
      this.cdr.detectChanges();
    });
  }
  selectedOption: any;
  crearSubzona(subzona){
    var newSubzona = {nombre: subzona, nodo: this.currentUser.nodo, zona: this.selectedOption}
    this.gestionNodoService.postSubzona(newSubzona)
    .pipe(first())
    .subscribe(
      data=> {
        console.log(data);
        this.selectedOption = null;
        this.cdr.detectChanges();
        this.getSubzonas();
      });
  }

  borrarSubzona(subzona){
    this.gestionNodoService.deleteSubzona(subzona.id)
    .subscribe(data => {
      for(var i = 0; i < this.subzonas.length; i++){
        if(this.subzonas[i].id == subzona.id){
          this.subzonas.splice(i, 1)
          var datosSubzonas = [...this.subzonas];
          this.subzonas = [];
          this.subzonas = datosSubzonas;
          this.cdr.detectChanges();
          break;
      }
    }
    });
  }

  getIdiomas(){
    this.gestionNodoService.getIdiomasByNodo(this.currentUser.nodo)
    .subscribe(idiomas => {
      this.idiomas = idiomas;
      this.cdr.detectChanges();
    });
  }
  crearIdioma(idioma){
    var newIdioma = {idioma: idioma, nodo: this.currentUser.nodo}
    this.gestionNodoService.postIdioma(newIdioma)
    .pipe(first())
    .subscribe(
      data=> {
        console.log(data);
        this.cdr.detectChanges();
        this.getIdiomas();
      });
  }

  borrarIdioma(idioma){
    this.gestionNodoService.deleteIdioma(idioma.id)
    .subscribe(data => {
      for(var i = 0; i < this.idiomas.length; i++){
        if(this.idiomas[i].id == idioma.id){
          this.idiomas.splice(i, 1)
          var datosIdiomas = [...this.idiomas];
          this.idiomas = [];
          this.idiomas = datosIdiomas;
          this.cdr.detectChanges();
          break;
      }
    }
    });
  }

  getEntidades(){
    this.gestionNodoService.getEntidadesByNodo(this.currentUser.nodo)
    .subscribe(entidades => {
      this.entidades = entidades;
      this.cdr.detectChanges();
    });
  }
  crearEntidad(entidad){
    var newEntidad = {entidad: entidad, nodo: this.currentUser.nodo}
    this.gestionNodoService.postEntidad(newEntidad)
    .pipe(first())
    .subscribe(
      data=> {
        console.log(data);
        this.cdr.detectChanges();
        this.getEntidades();
      });
  }

  borrarEntidad(entidad){
    this.gestionNodoService.deleteEntidad(entidad.id)
    .subscribe(data => {
      for(var i = 0; i < this.entidades.length; i++){
        if(this.entidades[i].id == entidad.id){
          this.entidades.splice(i, 1)
          var datosEntidades = [...this.entidades];
          this.entidades = [];
          this.entidades = datosEntidades;
          this.cdr.detectChanges();
          break;
      }
    }
    });
  }

}
