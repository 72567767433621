import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private router: Router,
        private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        //this.logout();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    changeUser(user: any) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/login_check`, { username: username, password: password })
            .pipe(map(user => {
                console.log(user);
                if (user && user.token) {

                    if(user.user.isActive) {
                        user.user.token = user.token;
                        user = user.user;
                        user.password = password;
                        delete user.user;
                        
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    } else {
                        return "inactive";
                    } 
                    
                }
 
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    }
}