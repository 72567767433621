// Spain
export const locale = {
	lang: 'eu',
	data: {
		TRANSLATOR: {
			SELECT: 'Aukeratu hizkuntza',
		},
		MODAL: {
			CERRAR: 'Itxi',
		},
		MENU: {
			START: 'Hasierako inprimakia',
			HOME: 'Hasiera',
			MATERIAL: 'Materiala',
			FORMACION: 'Prestakuntza',
			FORMACION_CENTRO: 'Ekitaldiak',
			PERFIL: 'Nire profila',
			MENTORAS: 'Aholkulariak',
			CENTROS: 'Ikastetxeak',
			EVENTOS: 'Ekitaldiak',
			FORMACIONES: 'Prestakuntzak',
			ASIGNACIONES: 'Esleipenak',
			GRUPOS: 'Taldeak'
		},
		BREADCRUMBS: {
			START: {
				TITLE: 'Hasierako inprimakia',
				DESC: 'Hasierako inprimakia'
			},
			HOME: {
				TITLE: "Hasiera",
				DESC: "Ongi etorri Inspira STEAMen intranetera"
			},
			MATERIAL: {
				TITLE: "Materiala",
				DESC: "Hemen aurkituko duzu Inspira STEAMerako behar duzun dokumentazio guztia"
			},
			FORMACION: {
				TITLE: "Prestakuntzak",
				DESC: "Hemen aurkituko dituzu egin ditzakezun prestakuntzak. Bat aukeratu behar duzu."
			},
			FORMACION_CENTRO: {
				TITLE: "Ekitaldiak",
				DESC: "Hemen aurkituko dituzu eskuragai dauden ekitaldiak. Bat aukeratu behar duzu."
			},
			MENTORAS: {
				TITLE: "Aholkulariak",
				DESC: "Inspira STEAMen erregistratuta dauden aholkularien zerrenda osoa."
			},
			CENTROS: {
				TITLE: "Ikastetxeak",
				DESC: "Inspira STEAMen erregistratuta dauden ikastetxeen zerrenda osoa."
			},
			EVENTOS: {
				TITLE: "Ekitaldiak",
				DESC: "Inspira STEAMen nodo bakoitzeko prestakuntza eta bileren kudeaketa."
			},
			FORMACIONES: {
				TITLE: "Prestakuntzak",
				DESC: "Inspira STEAMen prestakuntzen kudeaketa."
			},
			ASIGNACIONES: {
				TITLE: "Esleipenak",
				DESC: "Tutoreentzako eta zentroentzako erabilgarri dauden esleipenen xehetasuna eta kudeaketa."
			},
			PERFIL: {
				TITLE: "Nire profila",
				DESC: "Berrikusi zure datuak Inspira STEAMen parte hartu aurretik."
			},
			LOGS: {
				TITLE: "Aldaketen erregistroa",
				DESC: "Aldaketen erregistroa: Plataforman aholkulariek eta ikastetxeek egin dituzten aldaketen erregistroa."
			},
			GRUPOS: {
				TITLE: "Taldeak",
				DESC: "Inspira STEAM-en galde-sortak betetzen dituzten taldeen zerrenda."
			},
			CONTROLPANEL: {
				TITLE: "Panel de Control",
				DESC: "Gestión de admins, nodos, ediciones."
			},
			GESTIONNODO: {
				TITLE: "Gestión del nodo",
				DESC: "Gestión de zonas, subzonas, idiomas y entidades de Mentoras y Centros."
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Bidali',
				SIGNIN_BUTTON: 'Hasi saioa',
				YES_ACCOUNT: 'Baduzu konturik?',
				NO_ACCOUNT: 'Baduzu konturik?',
				SIGNUP_BUTTON: 'Erregistratu zaitez',
				FORGOT_BUTTON: 'Pasahitza ahaztu zaizu?',
				BACK_BUTTON: 'Atzera'
			},
			LOGIN: {
				TITLE: 'Hasi saioa intranetean',
				BUTTON: 'Hasi saioa',
			},
			FORGOT: {
				TITLE: 'Pasahitza ahaztu zaizu?',
				DESC: 'Idatzi zure helbide elektronikoa pasahitza berrezartzeko',
				SUCCESS: 'Pasahitza berrezartzeko posta mezu bat bidali dizugu kontura. Jarraitu bertan ageri zaizkizun instrukzioei.'
			},
			REGISTER: {
				TITLE: 'Erregistratu intranetean',
				SUCCESS: 'Zure kontua zuzen sortu da.'
			},
			INPUT: {
				EMAIL: 'Helbide elektronikoa',
				CONFIRM_EMAIL: 'Berretsi helbide elektronikoa',
				FULLNAME: 'Izena',
				APELLIDO: 'Abizenak',
				CENTERNAME: 'Ikastetxearen izena',
				PASSWORD: 'Pasahitza',
				CONFIRM_PASSWORD: 'Berretsi pasahitza',
				USERNAME: 'Erabiltzailea',
				NODO: 'Nodoa',
				SOY: '... naiz',
				MENTORA: 'Aholkularia',
				CENTRO: 'Ikastetxea',
				ACEPTO: 'Onartzen dut',
				LOPD: 'Datuak Babesteko Lege Organikoa (LOPD)',
				LISTAMENTORAS: 'Baimena ematen dut nire izena aholkularien zerrendan sartzeko',
				LISTACENTROS: 'Baimena ematen dut ikastetxearen izena ikastetxeen zerrendan sartzeko',
				SELECTNODO: 'Aukeratu nodoa',
				LOADNODO: 'Nodoak kargatzen'
			},
			VALIDATION: {
				INVALID_LOGIN: 'Idatzi dituzun datuak ez dira zuzenak. Saiatu berriro.',
				REQUIRED_FIELD: 'Bete egin behar duzu eremu hau',
				MIN_LENGTH_FIELD: 'Eremu horren gutxieneko tamaina hau da:',
				MAX_LENGTH_FIELD: 'Eremu horren gehieneko tamaina hau da:',
				INVALID_FIELD: 'Eremua hau ez da zuzena',
				MATCH: 'Pasahitza eta berrespena ez dira berdinak',
				MATCH_EMAIL: 'Helbide elektronikoa eta berrespena ez dira berdinak',
				INVALID_REGISTER: 'Sartu duzun helbide elektronikoa erregistratuta dago. Saiatu berriro edo hasi saioa.'
			}
		},
		FORMACION: {
			BOTON_NO: 'Egin klik hemen Inspira STEAMen prestakuntza azken 4 urteotan egin baduzu',
			CONFIR_NO: 'Azken 4 urteotan prestakuntzara joan izana berretsi duzu',
			FECHA: 'Data’',
			NOMBRE: 'Izena',
			LUGAR: 'Lekua',
			HORA: 'Ordua',
			ESTADO: 'Egoera',
			DISPONIBLE: 'TOKIAK LIBRE',
			COMPLETA: 'BETERIK',
			INSCRIBIRME: 'IZENA EMAN',
			ULTIMAS: 'AZKENEKO TOKIAK',
			CONFIRM_PREGUNTA_TITLE: 'Prestakuntza berretsi',
			CONFIRM_PREGUNTA_TEXT: 'Prestakuntza azken 4 urteetan egin duzula berresten duzu?',
			CONFIRM_BTNOK: 'Bai, berresten dut',
			CONFIRM_CORRECT_TITLE: 'Berrespena zuzen egin da',
			CONFIRM_CORRECT_TEXT: 'Egoki gorde dugu azken 4 urteetan prestakuntza egin izanaren berrespena.',
			CONFIRM_ERROR_TITLE: 'Errorea inskripzioan',
			CONFIRM_ERROR_TEXT: 'Aukeratutako prestakuntzan ez dago tokirik.',
			CONFIRM_CANCEL_TITLE: 'Inskripzioa deuseztatzea',
			CONFIRM_CANCEL_TEXT: 'Ekitaldi honetako inskripzioa deuseztatu egin nahi duzula berresten duzu?',
			CONFIRM_CANCELED_TITLE: 'Egoki deuseztatu da',
			CONFIRM_CANCELED_TEXT: 'Ekitaldirako inskripzioa deuseztatu egin da.',
			INSCRITA: 'Prestakuntzan inskripzioa egin duzu',
			CANCELAR: 'DEUSEZTATU ASISTENTZIA',
			INFO: 'Prestakuntzak aholkulari berrientzat dira, edo azken 4 urteetan prestakuntzarik egin ez dutenentzat. Lehentasuna emango zaie persona horiei. Tokirik egonez gero, gainerako aholkulariek parte hartu ahal izango dute.',
			INFO_CENTRO: '<b>Nahitaezko koordinazio-bilera Inspira STEAM programan parte hartzen duten ikastetxeentzat.</b> Nahitaezkoa da ikastetxe bakoitzeko pertsona bat joatea, gutxienez, programaren xehetasunak ezagutzera emateko, ikastetxeekin egingo den koordinazio bilerara. Han azalduko da ikasle taldeekin parte hartzeak zer esan nahi duen, prozesuaren etapetan ardurak zeintzuk diren, materialak zeintzuk diren eta abar',
			ASISTENCIA_SI: 'Ekitaldian parte hartu duzu',
			ASISTENCIA_NO: 'Ez duzu ekitaldian parte hartu',
			NO_EVENTOS:'Ez dago ekitaldirik eskuragai'
		},
		MATERIAL: {
			BOTON_LECTURA_MENT: 'Irakurri dut Aholkularientzako Informazio Kit-a',
			CONFIR_LECTURA_MENT: 'Aholkularientzako Informazio Kit-a irakurri duzula berretsi duzu',
			BOTON_LECTURA_CENT: 'Irakurri dut Ikastetxeentzako Informazio Kit-a',
			CONFIR_LECTURA_CENT: 'Ikastetxeentzako Informazio Kit-a irakurri duzula berretsi duzu',
			CONFIRMACION: 'Irakurri duzu dokumentua?',
			KIT_MENT: 'Aholkularientzako Informazio Kit-a',
			KIT_MENT_DESC: 'Dokumentu honek informazio operatiboa du, Inspira STEAM programako aholkulariek programaren oinarriak ezagutu ditzaten, parte hartzeak zer esan nahi duen eta jarraitu beharreko urratsak zeintzuk diren jakin dezaten. Arin eta erraz erantzun nahi die prozesuan sortzen diren zalantzei.',
			KIT_CENT: 'Ikastetxeentzako Informazio Kit-a',
			KIT_CENT_DESC: 'Dokumentu honek informazio operatiboa du, Inspira STEAM programako ikastetxeek programaren oinarriak ezagutu ditzaten, parte hartzeak zer esan nahi duen eta jarraitu beharreko urratsak zeintzuk diren jakin dezaten. Arin eta erraz erantzun nahi die prozesuan sortzen diren zalantzei.',
			NODISPO: 'Ez dago materialik eskura oraingoz.',
			DESCARGARTEXT: 'Saioetako materiala jaisteko, sartu hemen: ',
			SESIONES:'Saioetako materiala',
			IMPORTANTE:'GARRANTZITSUA',
			COMPLETAR_PRE: 'Bete lehenengo taldeak Inspira STEAMeko 1. saioa egin baino 3-4 egun arinago.',
			COMPLETAR_POST: 'Bete azken talde esperimentalak Inspira STEAMeko 6. saioa egin eta 3-4 egun geroago.',
			PRE_BLOQUE: 'AURRETIKO galde-sorta, blokea',
			POST_BLOQUE: 'ONDORENGO galde-sorta, blokea',
			BLOQUE2: 'Bete 1. blokea amaitu eta ordu batzuetara edo hurrengo egunean.'
		},
		HOME: {
			INSCRIPCION: '{{edicion}} edizioan izena ematea', //NOTA: En 'edición' va la fecha: ej. 2020-2021
			INSCRIPCION_TEXT: 'Inspira STEAM {{nodo}}ko edizioan izena eman nahi duzu? Begiratu zure profila zuzena den izena eman aurretik',
			INSCRIPCION_TEXT_AS: 'Para inscribirte en la próxima edición de Inspira STEAM {{nodo}} es necesario que hayas finalizado la anterior, las 6 sesiones en el aula y completado los cuestionarios finales. Si no es así, espera a haberlo hecho para inscribirte. ¡Te esperamos! Si ya has terminado ¡Bienvenida!',
			INSCRIPCION_BTNOK: 'Bai, izena eman nahi dut',
			INSCRIBIRME: 'Izena eman',
			BTNCANCEL: 'Utzi',
			INSCRIPCION_CONFIRM: 'Zuzen eman duzu izena',
			INSCRIPCIÓN_CONFIRM_TEXT: 'Zuzen eman duzu izena Inspira STEAM {{nodo}}ko {{edicion}} edizioan',
			EVENTO: 'Eman nahi duzu izena ekitaldirako?',
			EVENTO_BTNOK: 'Bai, izena eman nahi dut',
			EVENTO_CONFIRM: 'Zuzen eman duzu izena',
			EVENTO_CONFIRM_TEXT: 'Zuzen eman duzu izena (ekitaldian).',
			EVENTO_RECH: 'Ekitaldira ez zarela etorriko berretsi nahi duzu?',
			EVENTO_RECH_BTNOK: 'Bai, hala berresten dut',
			EVENTO_RECH_CONFIRM: 'Erantzuna gordeta',
			EVENTO_RECH_CONFIRM_TEXT: 'Zuzen adierazi duzu ez zarela ekitaldi honetara etorriko: {{evento}}',
			INSTR_CERT_TITLE: 'Sexu izaerako delituen ziurtagiria',
			INSTR_CERT_1: 'Programan parte hartzen duten aholkulari guztiek Sexu izaerako delituen ziurtagiria aurkeztu behar dute zigor aurrekaririk ez dutela ziurtatzeko. Eskakizun hau nahitaez bete beharrekoa da Espainian, adingabeekin lan egiten duten pertsonen kasuan. Ziurtagiria prestakuntza egin aurreko bi asteetan aurkeztu behar da. ',
			INSTR_CERT_2: 'Online eskatu daiteke edo behar den bulegora joanda egin daiteke.',
			INSTR_CERT_3: 'ONLINE',
			INSTR_CERT_4: 'Ziurtagiria eskuratzeko aukerarik arinena webetik eskatzea da. Hemen egin daiteke:  {{web}} (NAN elektronikoarekin edo klabearekin)',
			INSTR_CERT_5: 'Argibide gehiago zure Autonomia Erkidegoko Justizia Ministerioaren lurraldeko Gerentziaren webean edo Justizia Ministerioaren webean: {{web}}',
			INSTR_CERT_6: 'PRESENTZIALA',
			INSTR_CERT_7: '1. Bete inprimaki hau: {{formulario}}. Gero, entregatu zure autonomia erkidegoko Justizia Ministerioaren lurraldeko kudeaketa sailean NANarekin batera (lurraldeko kudeaketa sailen zerrenda autonomia erkidegoka: {{gerencias}})',
			INSTR_CERT_8: 'Eskaera inprimatua aurkeztu, NANarekin batera, zure herriko epaitegian eta haiek bidaliko dute Madrilgo Justizia Ministeriora (2 aste inguru tardatzen du).',
			INSTR_CERT_9: 'Argibide gehiago Justizia Ministerioaren webean: {{web}}',
			EVENTO_TITLE: 'Ekitaldiari buruzko xehetasunak',
			EVENTO_FECHA: 'Data',
			EVENTO_DESCR: 'Deskribapena',
			EVENTO_LUGAR: 'Lekua',
			EVENTO_LINK: 'Esteka',
			EVENTO_INFORMACION: 'Argibide gehiago ekitaldiari buruz',
			EVENTO_CONFIRMADA: 'PARTE HARTZEA BERRETSITA',
			EVENTO_CONFIRMAR: 'PARTE HARTUKO DUT',
			EVENTO_RECHAZAR: 'EZ DUT PARTE HARTUKO',
			EDICION_DISP: '{{edicion}} edizioa prest',
			EDICION_DISP_TEXT: 'Eman izena Inspira STEAMen beste edizio batean hasteko',
			CERT_AVISO: 'Erantsi <b>Sexu izaerako delituen ziurtagiria</b>, Inspira STEAMen parte hartu aurretik, “Nire profila” atalean',
			CERT_INSTR: 'Zelan eskatu ziurtagiria',
			FORMACION_MENTORAS: 'Aholkularien prestakuntza',
			FORMACION_CENTROS: 'Ikastetxeen ekitaldiak',
			FORMACION_CENTROS_INS: 'Hartutako ekitaldia',
			FORMACION_TEXT: 'Ekitaldia eskainiko den data batean eman behar duzu izena',
			FORMACION_DETALLE: 'Xehetasunak',
			MATERIAL: 'Materiala',
			MATERIAL_TEXT: 'Aztertu dokumentazioa',
			PERFIL: 'Nire profila',
			PERFIL_TEXT: 'Ikusi nire datuak',
			INSCRIPCION_NO_MENT: 'INSKRIPZIOA EZ DAGO ESKURAGARRI: Aholkularien inskripzioa ez dago zabalik une honetan.',
			INSCRIPCION_NO_CENT: 'INSKRIPZIOA EZ DAGO ESKURAGARRI: Ikastetxeen inskripzioa ez dago zabalik une honetan.',
			LINK_SOCIAL: '<a href="http://social.inspirasteam.net/" target="_blank"> Egin klik Inspira STEAMen Plataforma Sozialera </a> sartzeko.'
		},
		PERFIL: {
			MENU: {
				EMAIL: 'Helbide elektronikoa',
				TELEFONO: 'Telefonoa',
				NOM_CONT: 'Harremanetarako pertsonaren izena',
				EMAIL_CONT: 'Harremanetarako helbide elektronikoa',
				INFORMACION_CENTRO: 'Ikastetxearen informazioa',
				INFORMACION_PERSON: 'Informazio pertsonala',
				PASSWORD: 'Aldatu pasahitza',
				BAJA: 'Eman baja',
				CERTIFICADOS: 'Ziurtagiriak',
				GUARDAR_OK: 'Datuak gordeta',
				GUARDAR_OK_TEXT: 'Datuak gordeta daude',
				DATOS_WEB: 'Mentoreen datu publikoak'
			},
			CERTIFICADOS: {
				CERT_DEL: 'Sexu izaerako delituen ziurtagiria',
				INSTR: 'Zelan eskatu ziurtagiria',
				MENSAJE_OK: 'Ziurtagiria zuzen igo da',
				MENSAJE_NO: 'Ziurtagiria igo gabe',
				ARRASTRA: 'Arrastatu edo klikatu hemen ziurtagiria (PDFn) igotzeko',
				SUBIR: 'Igo ziurtagiria',
				SUBIR_OK: 'Ziurtagiria gordeta',
				SUBIR_OK_TEXT: 'Ziurtagiria zuzen gorde da',
				SUBIR_ERR: 'Formatu okerra',
				SUBIR_ERR_TEXT: 'Ziurtagiria PDF formatuan igo behar da',
				SUBIR_ERR2: 'Fitxategia ez da aukeratu',
				SUBIR_ERR2_TEXT: 'Fitxategi bat aukeratu edo arrastatu behar duzu goi aldeko koadrora'
			},
			COMPROMISOS: {
				COMP_ENT: 'Erakundeen konpromisoa',
				COMP_INFO: 'Zer da erakundeen konpromisoa?',
				COMP_TEXT1: 'Kasu batzuetan, aholkulariak lan egiten duen erakundeak konpromiso sakonagoa izan nahi du proiektuarekin, emakume horrek egiten duen lana aitortu eta balioetsi nahi du eta proiektuan parte hartzea erraztu. Horretarako, Inspira STEAM Konpromisoa prestatu dugu. Dokumentu horren bidez, erakundeek konpromiso hauek hartzen dituzte: ',
				COMP_BUL1: 'Aholkulariak Inspira STEAMen egiten duen lana lanaldiaren barruan sartzen dute.',
				COMP_BUL2: 'Prestakuntza saiora, ikastetxeetako saioetara eta garrantzizkotzat jotzen dituzten zabalkundeko ekitaldietara joatea errazten dute.',
				COMP_BUL3: 'Programak jorratzen dituen gaiak eta arazoak komunitatean zabaltzeko eta sentsibilizatzeko lan egiten dute.',
				COMP_TEXT2: 'Konpromiso hori aitortzeko, programak ikusgai jartzen ditu erakunde horiek proiektuaren webean: <a href="https://inspirasteam.net/entidades-compromiso-inspira/" target="_blank"> https://inspirasteam.net/entidades-compromiso-inspira/</a>',
				COMP_DAT: 'Zure erakundeak konpromisoa sinatu nahi badu, bete urrats hauek:',
				TEMP_COMP_TEXT: 'Jaitsi erakundeen konpromisoaren txantiloia.',
				SUBIR_TEXT: 'Erakundeko arduradunak bete eta sinatu ondoren, igo hona erakundeen konpromisoa.',
				MENSAJE_OK: 'Dokumentua ondo igo da',
				MENSAJE_NO: 'Dokumentua igotzeko zain',
				ARRASTRA: 'Arrastatu edo egin klik hemen erakundeen konpromisoa (PDFan) igotzeko',
				SUBIR: 'Igo erakundeen konpromisoa',
				SUBIR_OK: 'Dokumentua gordeta',
				SUBIR_OK_TEXT: 'Dokumentua ondo gorde da',
				SUBIR_ERR: 'Formatu desegokia',
				SUBIR_ERR_TEXT: 'Dokumentua PDF formatuan igo behar da',
				MAS_INFO: 'Bete datu hauek, zure erakundea <a href="https://inspirasteam.net/eu/entidades-compromiso-inspira/" target="_blank"> webean ikusgai jartzeko.</a>',
				SUBIR_LOGO: 'Igo erakundearen logoa edozein esteka publikotara (weba, Drive, Dropbox...) eta pasatu guri esteka.',
				LINK_ENT: 'Pasatu zure erakundearen web orriaren esteka.'
			},
			DATOSWEB: {
				SUBTITLE: 'Mentoreen datuak jasotzeko inprimakia weberako:<a href="https://inspirasteam.net/eu/mentoras/" target="_blank"> inspirasteam.net</a> (mentorea izan bazara, begiratu zure datuak eta bete inprimakia, aldatu nahi badituzu soilik).',
				CHARACTER: 'carácteres',
				BIO: 'Biografia' ,
				BIO_TEXT: 'Ibilbidearen laburpen publikoa (lerroalde bat edo bi). Gizarte sareetako estekekin batera agertuko da, norbaitek webeko argazkian klik egiten badu.',
				FOTO: 'Argazkia',
				FOTO_TEXT: 'Igo edozein esteka publikotan (web, drive, dropbox, etab.) agertzea nahi duzun argazkia eta pasa iezaguzu esteka. Argazkia zirkulu batean editatuko dugu (<a href="https://inspirasteam.net/eu/mentoras/" target="_blank"> Inspira STEAM webean</a> ikus ditzakezu adibide batzuk). Hutsik uzten baduzu, argazkiaren ordez, InspiraSTEAM logotipoa agertzea nahi duzula ulertuko dugu.',
				SOCIAL: 'Gizarte sareak',
				SOCIAL_TEXT: 'Adierazi zure gizarte sareen profilak (agertzea nahi duzun esteka bakoitza): facebook, twitter, linkedin',
				NAME: 'Izena',
				NAME_TEXT: 'Webean agertzea nahi duzun bezala.'
			},
			PASSWORD: {
				ACTUAL: 'Uneko pasahitza',
				NUEVA: 'Pasahitz berria',
				CONFIRM: 'Berretsi pasahitza',
				ERROR: 'Idatzi duzun uneko pasahitza ez da zuzena' ,
				CAMBIO_OK: 'Pasahitza aldatuta',
				CAMBIO_OK_TEXT: 'Pasahitza zuzen aldatu da'
			},
			BAJA: {
				AVISO: 'KONTUZ: Ekintza hau atzeraezina da. Zure datuak ezabatzen badituzu, berriro erregistratu beharko duzu Inspira STEAMen berriro parte hartzeko.',
				INFO: 'Inspira STEAMen baja ematen baduzu, aholkularitzan ez duzula parte hartuko berretsiko duzu. Zure datu guztiak ezabatuko dira sistematik.',
				BTN: 'INSPIRA STEAMen BAJA EMATEA',
				CONFIRMA: 'Ziur zaude baja eman eta zure datu guztiak ezabatu nahi dituzula Inspira STEAMetik?',
				CONFIRMA_BTNOK: 'Bai, baja eman nahi dut',
				BAJA_OK: 'Kontua ezabatu da',
				BAJA_OK_TEXT: 'Kontua eta lotuta zituen datu guztiak zuzen ezabatu dira.'
			}
		},
		FORMULARIOS: {
			MENTORAS: {
				TITULO: 'Aholkulariaren erregistroa',
				NOMBRE: 'Izena',
				APELLIDOS: 'Abizenak',
				TELEFONO: 'Telefonoa',
				ESTUDIOS: 'Ikasketak edo STEAMekin duzun harremana',
				ENTIDAD: 'Erakundea',
				OPCIONAL: 'Aukerakoa',
				OTRA: 'Beste bat',
				IDIOMAS: 'Zein hizkuntzatan eman ditzakezu Inspira STEAMen saioak?',
				PROVINCIAS: 'Zein probintziatan hartu nahi duzu parte Inspira STEAMen?',
				PROVINCIAS_DESC: 'Hautaketa hau gutxi gorabeherakoa da. Geroago zehaztuko da zein tokitan parte hartuko duzun.',
				ZONA: 'Inspira STEAMen parte hartzeko lehenetsitako gunea, auzoa edo herria',
				SESIONESCON: 'Prest nago Inspira STEAM saioak ...ekin egiteko',
				SESIONESCON_DESC: 'Lehenengo eta azken saioak batera egiten dituzte neskek eta mutilek. Bigarren saiotik bosgarrenera banan egiten dira. Aukeratu bigarren saiotik bosgarrenera neskekin, mutilekin edo batzuekin nahiz besteekin lan egin nahi duzun.',
				GRUPOS: 'Prest nago Inspira STEAMeko saioak ikastetxeko talde batekin baino gehiagorekin egiteko',
				GRUPOS_CENTRO: '',
				EXPERIENCIA: 'Inspira STEAMen aholkulari izan zara?',
				EDICIONES: 'Zein ediziotan izan zara aholkulari?',
				COMENTARIOS: 'Beste ohar batzuk',
				SI: 'Bai',
				NO: 'Ez',
				NINAS: 'Neskekin',
				NINOS: 'Mutilekin',
				GENERO: 'Generoa',
				GENEROFEM: 'Femeninoa',
				GENEROMAS: 'Maskulinoa',
				GENEROTRO: 'Bestelakoa',
				LOCALIDAD: 'Inspira STEAMen parte hartzeko lehenetsitako herria(k)',
				RESIDENCIA: 'Bizi zaren/Lan egiten duzun herria',
				ZONASEUS: 'Inspira STEAMen parte hartzeko lehenetsitako gunea(k). Mesedez, hautatu ahal dituzun aukera guztiak.',
				SUBZONASEUS: 'Inspira STEAMen parte hartzeko lehenetsitako azpieremua(k). Mesedez, hautatu ahal dituzun aukera guztiak.',
				REPETIR: '¿Te gustaría repetir en el mismo centro? Indícanos cuál es: '
			},
			CENTROS: {
				TITULO: 'Ikastetxeen erregistroa',
				NOMBRE: 'Ikastetxearen izena',
				POBLACION: 'Herria',
				CURSO: 'Maila',
				EP6: 'Lehen Hezkuntzako 6. maila',
				ESO1: 'DBHko 1. maila',
				CONT: 'Harremanetarako pertsona',
				CONT_CARGO: 'Harremanetarako pertsonaren kargua',
				CONT_NOMBRE: 'Harremanetarako pertsonaren izen-abizenak',
				CONT_EMAIL: 'Harremanetarako pertsonaren helbide elektronikoa',
				CONT_TELEFONO: 'Harremanetarako pertsonaren telefonoa',
				IDIOMAS: 'Zein hizkuntzan nahi dituzue Inspira STEAM saioak?',
				IDIOMAS_DESC: 'Hizkuntza bat baino gehiago aukeratzen baduzu eta lehentasunen hurrenkera adierazi nahi baduzu, egizu, mesedez oharretan. Saiatzen gara irizpide hori betetzen, nahiz eta batzuetan ezinezkoa izan, ikastetxeen eskaeraren eta aholkularien hizkuntza errealitatearen arteko desoreka dela eta. Hizkuntza bakarra markatu bada eta dauzkagun aholkulariekin bete ezin bada, ikastetxea programatik kanpo geratu daiteke.',
				GRUPOS: 'Taldeen kopurua',
				GRUPO_NOMBRE: '{{num}}. taldea. Izena',
				GRUPO_CHICAS: '{{num}}. taldea. Neska kopurua:',
				GRUPO_CHICOS: '{{num}}. taldea. Mutil kopurua:',
				COMENTARIOS: 'Beste ohar batzuk',
				PROVINCIA: 'Probintzia',
				EXPERIENCIA_CENTRO: 'Ikastetxeak hartu du parte Inspira STEAMen aurreko edizioetan?',
				EDICIONES_CENTRO: 'Zein ediziotan?',
				DIRECCION: 'Dirección del centro (Tipo de vía, Nombre de la vía, Número, Código Postal, Localidad)',
				WEB: 'Página web del centro (si existe)'
			}
		},
		TOPBAR: {
			SALUDO: 'Kaixo',
			PERFIL: 'Nire profila',
			PERFIL_DETALLE: 'Datu pertsonalak eta ziurtagiriak',
			LOGOUT: 'Itxi saioa'
		},

		INPUT: {
			VALIDATION: {
				REQUIRED_FIELD: 'Eremu hau bete behar duzu'
			},
			SUBMIT: 'Gorde informazioa'
		}
	}
};
