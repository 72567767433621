// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { StartmentoraComponent } from './views/pages/startmentora/startmentora.component';
import { StartcentroComponent } from './views/pages/startcentro/startcentro.component';
import { HomeComponent } from './views/pages/home/home.component';
import { MaterialsComponent } from './views/pages/materials/materials.component';
import { FormacionComponent } from './views/pages/formacion/formacion.component';
import { PerfilComponent } from './views/pages/perfil/perfil.component';
import { MentorasComponent } from './views/pages/mentoras/mentoras.component';
import { CentrosComponent } from './views/pages/centros/centros.component';
import { EventosComponent } from './views/pages/eventos/eventos.component';
import { FormacionesComponent } from './views/pages/formaciones/formaciones.component';
import { MentoraComponent } from './views/pages/mentora/mentora.component';
import { MaterialesComponent } from './views/pages/materiales/materiales.component';
import { LogsComponent } from './views/pages/logs/logs.component';
import { GruposComponent } from './views/pages/grupos/grupos.component';
import { ControlpanelComponent } from './views/pages/controlpanel/controlpanel.component';
import { GestionnodoComponent } from './views/pages/gestionnodo/gestionnodo.component';

// Auth
import { AuthGuard, StartGuard } from './_helpers';
import { AsignacionesComponent } from './views/pages/asignaciones/asignaciones.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'start/mentoras', 
				component: StartmentoraComponent
			},
			{
				path: 'start/centros', 
				component: StartcentroComponent
			},
			{
				path: 'home', 
				component: HomeComponent, 
				canActivate: [StartGuard]
			},
			{
				path: 'material', 
				component: MaterialsComponent, 
				canActivate: [StartGuard]
			},
			{
				path: 'materiales', 
				component: MaterialesComponent, 
				canActivate: [StartGuard]
			},
			{
				path: 'formacion', 
				component: FormacionComponent, 
				canActivate: [StartGuard]
			},
			{
				path: 'eventos_centro', 
				component: FormacionComponent, 
				canActivate: [StartGuard]
			},
			{
				path: 'perfil', 
				component: PerfilComponent, 
				canActivate: [StartGuard]
			},
			{
				path: 'mentoras', 
				component: MentorasComponent
			},
			{ 
				path: 'mentora/:id', 
				component: MentoraComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'centros', 
				component: CentrosComponent
			},
			{ 
				path: 'centro/:id',
				component: MentoraComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'eventos', 
				component: EventosComponent
			},
			{
				path: 'formaciones', 
				component: FormacionesComponent
			},
			{
				path: 'asignaciones',
				component: AsignacionesComponent
			},
			{
				path: 'logs', 
				component: LogsComponent
			},
			{
				path: 'grupos', 
				component: GruposComponent
			},
			{
				path: 'controlpanel', 
				component: ControlpanelComponent
			},
			{
				path: 'gestionnodo', 
				component: GestionnodoComponent
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'mail',
				loadChildren: () => import('./views/pages/apps/mail/mail.module').then(m => m.MailModule)
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('./views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			{
				path: 'ngbootstrap',
				loadChildren: () => import('./views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
			},
			{
				path: 'material1',
				loadChildren: () => import('./views/pages/material/material.module').then(m => m.MaterialModule)
			},
			{
				path: 'user-management',
				loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},
			{
				path: 'wizard',
				loadChildren: () => import('./views/pages/wizard/wizard.module').then(m => m.WizardModule)
			},
			{
				path: 'builder',
				loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
