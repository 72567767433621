// Spain
export const locale = {
	lang: 'cat',
	data: {
		TRANSLATOR: {
			SELECT: 'Tria el teu idioma',
		},
		MODAL: {
			CERRAR: 'Tancar'
		},
		MENU: {
			START: 'Formulari inicial',
			HOME: 'Inici',
			MATERIAL: 'Material',
			MATERIALES: 'Materials',
			FORMACION: 'Formació',
			FORMACION_CENTRO: 'Esdeveniments',
			PERFIL: 'El meu Perfil',
			MENTORAS: 'Mentores',
			CENTROS: 'Centres',
			EVENTOS: 'Esdeveniments (Inici)',
			FORMACIONES: 'Formacions/Esdeveniments',
			ASIGNACIONES: 'Assignacions',
			LOGS: 'Reg.Canvis',
			GRUPOS: 'Grups'
		},
		BREADCRUMBS: {
			START: {
				TITLE: 'Formulari inicial',
				DESC: 'Algunes dades més sobre la teva participació a Inspira STEAM'
			},
			HOME: {
				TITLE: "Inici",
				DESC: "Et donem la benvinguda a la Intranet d'Inspira STEAM"
			},
			MATERIAL: {
				TITLE: "Material",
				DESC: "Aquí trobaràs tota la documentació necessària per a Inspira STEAM"
			},
			MATERIALES: {
				TITLE: "Materials",
				DESC: "Detall i gestió dels materials disponibles per a les mentores i centres."
			},
			FORMACION: {
				TITLE: "Formació",
				DESC: "Aquí trobaràs les formacions disponibles. Has de seleccionar-ne una."
			},
			FORMACION_CENTRO: {
				TITLE: "Esdevenimentss",
				DESC: "Aquí trobaràs els esdeveniments disponibles. Has de seleccionar-ne un."
			},
			MENTORAS: {
				TITLE: "Mentores",
				DESC: "Llistat complet de les mentores registrades a Inspira STEAM."
			},
			MENTORA: {
				TITLE: "Mentora",
				DESC: "Detall i gestió de mentora."
			},
			CENTROS: {
				TITLE: "Centres",
				DESC: "Llistat complet de centres registrats a Inspira STEAM."
			},
			CENTRO: {
				TITLE: "Centre",
				DESC: "Detall i gestió de centre."
			},
			EVENTOS: {
				TITLE: "Esdeveniments",
				DESC: "Gestió dels esdeveniments d'Inspira STEAM."
			},
			FORMACIONES: {
				TITLE: "Formacions",
				DESC: "Gestió de les formacions d'Inspira STEAM."
			},
			ASIGNACIONES: {
				TITLE: "Assignacions",
				DESC: "Detall i gestió de les assignacions disponibles per a mentors i mentores."
			},
			PERFIL: {
				TITLE: "El meu perfil",
				DESC: "Revisa les teves dades abans de participar a Inspira STEAM."
			},
			LOGS: {
				TITLE: "Registre de canvis",
				DESC: "Registre de canvis realitzats a la plataforma per Mentores i Centres."
			},
			GRUPOS: {
				TITLE: "Grups",
				DESC: "Llistat dels grups que omplen els qüestionaris a Inspira STEAM."
			},
			CONTROLPANEL: {
				TITLE: "Panel de Control",
				DESC: "Gestión de admins, nodos, ediciones."
			},
			GESTIONNODO: {
				TITLE: "Gestión del nodo",
				DESC: "Gestión de zonas, subzonas, idiomas y entidades de Mentoras y Centros."
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				SIGNIN_BUTTON: 'Inicia Sessió',
				YES_ACCOUNT: 'Ja tens un compte?',
				NO_ACCOUNT: '¿Encara no tens un compte?',
				SIGNUP_BUTTON: "Registra't",
				FORGOT_BUTTON: 'Has oblidat la contrasenya?',
				BACK_BUTTON: 'Tornar'
			},
			LOGIN: {
				TITLE: 'Inicia sessió a la Intranet',
				BUTTON: 'Iniciar Sessió',
			},
			FORGOT: {
				TITLE: 'Heu oblidat la contrasenya?',
				DESC: 'Introdueix el teu correu electrònic per restablir la contrasenya',
				SUCCESS: "S'ha enviat un correu de restabliment al teu compte. Segueix les instruccions indicades en el mateix."
			},
			REGISTER: {
				TITLE: 'Registre a la Intranet',
				SUCCESS: 'El teu compte ha estat creat correctament.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Nom',
				APELLIDO: 'Cognoms',
				CENTERNAME: 'Nom del centre',
				PASSWORD: 'Contrasenya',
				CONFIRM_PASSWORD: 'Confirmar Contrasenya',
				USERNAME: 'Usuari',
				NODO: 'Node',
				SOY: 'Sóc...',
				MENTORA: 'Mentora / Mentor',
				CENTRO: 'Centre',
				ACEPTO: "Estic d'acord amb la",
				LOPD: 'Llei Orgànica de Protecció de Dades (LOPD)',
				LISTAMENTORAS: 'Autoritzo a ser afegida a la llista de Mentores',
				LISTACENTROS: 'Autoritzo a ser afegit a la llista de Centres',
				SELECTNODO: 'Selecciona el node...',
				LOADNODO: 'Carregant nodes...'
			},
			VALIDATION: {
				INVALID_LOGIN: 'Les dades introduïdes no són correctes. Intenta-ho de nou.',
				REQUIRED_FIELD: "Has d'omplir aquest camp",
				MIN_LENGTH_FIELD: "La mida mínima d'aquest camp és:",
				MAX_LENGTH_FIELD: "La mida màxima d'aquest camp és:",
				INVALID_FIELD: 'Aquest camp no és vàlid',
				MATCH: 'La contrasenya i la seva confirmació no coincideixen',
				INVALID_REGISTER: "L'email introduït ja es troba registrat. Intenta-ho de nou o inicia sessió."
			}
		},
		FORMACION: {
			BOTON_NO: "Prem aquí si ja has assistit a una formació d'Inspira STEAM en els últims 4 anys",
			CONFIR_NO: "Has confirmat l'assistència a una formació en els últims 4 anys",
			FECHA: 'Data',
			NOMBRE: 'Nom',
			LUGAR: 'Lloc',
			HORA: 'Hora',
			ESTADO: 'Estat',
			DISPONIBLE: 'DISPONIBLE',
			COMPLETA: 'COMPLETA',
			INSCRIBIRME: "INSCRIURE'M",
			ULTIMAS: 'ÚLTIMES PLACES',
			CONFIRM_PREGUNTA_TITLE: 'Confirmació de Formació',
			CONFIRM_PREGUNTA_TEXT: 'Confirmes que has assistit a una formació en els últims 4 anys?',
			CONFIRM_BTNOK: 'Sí, confirmo',
			CONFIRM_CORRECT_TITLE: 'Confirmació Correcta',
			CONFIRM_CORRECT_TEXT: "Hem guardat correctament la teva confirmació d'assistència a formació en els últims 4 anys.",
			CONFIRM_ERROR_TITLE: 'Error en la inscripció',
			CONFIRM_ERROR_TEXT: 'La formació seleccionada està completa.',
			CONFIRM_CANCEL_TITLE: "Cancel·lació d'Inscripció",
			CONFIRM_CANCEL_TEXT: 'Confirmes la cancel·lació de la teva inscripció a aquest esdeveniment?',
			CONFIRM_CANCELED_TITLE: "Cancel·lació Correcta",
			CONFIRM_CANCELED_TEXT: "S'ha cancel·lat la teva inscripció a l'esdeveniment.",
			INSCRITA: 'Formació inscrita',
			CANCELAR: 'CANCEL·LAR ASSISTÈNCIA',
			INFO: "Les formacions estan dirigides a mentores i mentors nous, o que hagin realitzat la formació fa més de 4 anys. Es donarà prioritat a aquestes persones, en cas d'haver places disponibles podran participar la resta de mentores i mentors.",
			INFO_CENTRO: "<b>Reunió de coordinació obligatòria per a centres participants a Inspira STEAM 2020/21.</b> Cal que almenys una persona de cada centre acudeixi a la reunió de coordinació amb centres que se celebrarà per donar a conèixer els detalls del programa, el que implica participar amb els grups d'estudiants, responsabilitats en les diferents etapes del procés, materials, etc.",
			ASISTENCIA_SI: "Has assistit a l'esdeveniment",
			ASISTENCIA_NO: "No has assistit a l'esdeveniment",
			NO_EVENTOS:'No hi ha esdeveniments disponibles'
		},
		MATERIAL: {
			BOTON_LECTURA_MENT: "Confirmo que he llegit el Kit d'Informació de Mentores",
			CONFIR_LECTURA_MENT: "Has confirmat la lectura del Kit d'Informació de Mentores",
			BOTON_LECTURA_CENT: "Confirmo que he llegit el Kit d'Informació de Centres",
			CONFIR_LECTURA_CENT: "Has confirmat la lectura del Kit d'Informació de Centres",
			CONFIRMACION: 'Confirmes la lectura del document?',
			KIT_MENT: "Kit d'Informació de Mentores",
			KIT_MENT_DESC: 'Aquest document conté informació operativa perquè les mentores i mentors del programa Inspira STEAM coneguin les bases del programa, el que implica participar-hi i els passos a seguir. Pretén donar resposta, de forma ràpida i àgil, als dubtes que van sorgint al llarg del procés.',
			KIT_CENT: "Kit d'Informació de Centres",
			KIT_CENT_DESC: 'Aquest document conté informació operativa perquè els centres del programa Inspira STEAM coneguin les bases del programa, el que implica participar-hi i els passos a seguir. Pretén donar resposta, de forma ràpida i àgil, als dubtes que van sorgint al llarg del procés',
			NODISPO: 'No hi ha materials disponibles de moment.',
			DESCARGARTEXT: 'Per descarregar el material de les sessions entra aqui: ',
			SESIONES:'Material sessions',
			IMPORTANTE:'IMPORTANTE',
			COMPLETAR_PRE: 'completar 3-4 días antes de que el primer grupo haya realizado la sesión 1 de Inspira STEAM.',
			COMPLETAR_POST: 'completar 3-4 días después de que el último grupo experimental haya finalizado la sesión 6 de Inspira STEAM.',
			PRE_BLOQUE: 'Cuestionario PRE, bloque',
			POST_BLOQUE: 'Cuestionario POST, bloque'
		},
		HOME: {
			INSCRIPCION: 'Inscripció {{edicion}}',	//NOTA: A 'edició' va la data: ex. 2020-2021
			INSCRIPCION_TEXT: "Vols inscriure't en la nova edició d'Inspira STEAM {{node}}? Revisa que el teu perfil és correcte abans de la inscripció",
			INSCRIPCION_BTNOK: "Sí, inscriure'm",
			INSCRIBIRME: "Inscriure'm",
			BTNCANCEL: 'Cancel·lar',
			INSCRIPCION_CONFIRM: 'Inscripció correcta',
			INSCRIPCIÓN_CONFIRM_TEXT: "T'has inscrit correctament a l'edició {{edicio}} d'Inspira STEAM {{node}}",
			EVENTO: "Vols inscriure't a l'esdeveniment?",
			EVENTO_BTNOK: "Sí, inscriure'm",
			EVENTO_CONFIRM: 'Inscripció correcta',
			EVENTO_CONFIRM_TEXT: "T'has inscrit correctament a l'esdeveniment.",
			EVENTO_RECH: "Confirmes que no vas a assistir a l'esdeveniment?",
			EVENTO_RECH_BTNOK: 'Sí, confirmo',
			EVENTO_RECH_CONFIRM: 'Resposta Guardada',
			EVENTO_RECH_CONFIRM_TEXT: "Has indicat correctament que no vas a assistir a l'esdeveniment: {{esdeveniment}}",
			INSTR_CERT_TITLE: 'Certificat de delictes de naturalesa sexual',
			INSTR_CERT_1: "Totes les mentores i mentors que participen en el programa han de lliurar, cada edició, el Certificat de Delictes de Naturalesa Sexual per comprovar que no tenen antecedents. Aquest és un requisit d'obligat compliment a Espanya per a totes les persones que treballen amb menors. Aquest certificat s'haurà de lliurar en les dues setmanes següents a realitzar la formació. ",
			INSTR_CERT_2: "Es pot sol·licitar en línia o acudint directament a les oficines corresponents.",
			INSTR_CERT_3: 'ONLINE',
			INSTR_CERT_4: "L'opció més ràpida per obtenir el certificat és sol·licitar-lo a través del web: {{web}} (amb el DNI electrònic o amb clau)",
			INSTR_CERT_5: "Para más información consulte directamente la web de la Gerencia Territorial del Ministerio de Justicia de tu territorio o la web del Ministerio de Justicia: {{web}}", //to translate
			INSTR_CERT_6: 'PRESENCIAL',
			INSTR_CERT_7: '1. Omplir aquest formulari {{formulari}} i lliurar-lo a la gerència territorial del Ministeri de Justícia de la teva comunitat juntament amb el DNI (llista de gerències territorials per comunitats: {{gerències}} )',
			INSTR_CERT_8: '2. Presentar la sol·licitud impresa juntament amb el DNI al jutjat de la teva ciutat i ells ho enviaran al Ministeri de Justícia a Madrid (triga unes 2 setmanes).',
			INSTR_CERT_9: 'Més informació al web de Ministeri de Justícia: {{web}}',
			EVENTO_TITLE: "Detalls de l'esdeveniment",
			EVENTO_FECHA: 'Data',
			EVENTO_DESCR: 'Descripció',
			EVENTO_LUGAR: 'Lloc',
			EVENTO_LINK: 'Link',
			EVENTO_INFORMACION: "Més informació sobre l'esdeveniment",
			EVENTO_CONFIRMADA: 'ASISTÈNCIA CONFIRMADA',
			EVENTO_CONFIRMAR: 'CONFIRMAR ASISTÈNCIA',
			EVENTO_RECHAZAR: 'NO ASSISTIRÉ',
			EDICION_DISP: 'Edició {{edicio}} disponible',
			EDICION_DISP_TEXT: "Inscriu-te per començar una nova edició d'Inspira STEAM",
			CERT_AVISO: "Has d'adjuntar el <b> Certificat de Delictes de Naturalesa Sexual</b> abans de participar a Inspira STEAM a la secció \"El meu perfil\"",
			CERT_INSTR: 'Com sol·licitar el certificat',
			FORMACION_MENTORAS: 'Formació de Mentores',
			FORMACION_CENTROS: 'Esdeveniments disponibles',
			FORMACION_CENTROS_INS: 'Esdeveniment inscrit',
			FORMACION_TEXT: "Has inscriure't a una de les dates d'esdeveniment disponibles",
			FORMACION_DETALLE: 'Detall',
			MATERIAL: 'Material',
			MATERIAL_TEXT: 'Revisa la documentació disponible',
			PERFIL: 'El meu perfil',
			PERFIL_TEXT: 'Veure les meves dades personals',
			INSCRIPCION_NO_MENT: 'INSCRIPCIÓ NO DISPONIBLE: La inscripció de Mentores no es troba oberta en aquest moment.',
			INSCRIPCION_NO_CENT: 'INSCRIPCIÓ NO DISPONIBLE: La inscripció de Centres no es troba oberta en aquest moment.',
			LINK_SOCIAL: 'Prem <a href="http://social.inspirasteam.net/" target="_blank"> aquí</a> per accedir a la <a href="http://social.inspirasteam.net/" target="_blank"> Plataforma Social Inspira STEAM</a>.',
		},
		PERFIL: {
			MENU: {
				EMAIL: 'Email',
				TELEFONO: 'Telèfon',
				NOM_CONT: 'Nom contacte',
				EMAIL_CONT: 'E-mail contacte',
				INFORMACION_CENTRO: 'Informació del Centre',
				INFORMACION_PERSON: 'Informació personal',
				PASSWORD: 'Canviar contrasenya',
				BAJA: 'Donar de baixa',
				CERTIFICADOS: 'Certificats',
				GUARDAR_OK: 'Dades Guardades',
				GUARDAR_OK_TEXT: "Les dades s'han guardat correctament",
				DATOS_WEB: 'Dades públiques mentores '
			},
			CERTIFICADOS: {
				CERT_DEL: 'Certificat de Delictes de Natura Sexual',
				INSTR: 'Com sol·licitar el certificat',
				MENSAJE_OK: "El certificat s'ha pujat correctament",
				MENSAJE_NO: 'Pendent pujar el certificat',
				ARRASTRA: 'Arrossega o prem aquí per pujar el teu certificat (PDF)',
				SUBIR: 'Pujar Certificat',
				SUBIR_OK: 'Certificat Guardat',
				SUBIR_OK_TEXT: "El certificat s'ha guardat correctament",
				SUBIR_ERR: 'Format Incorrecte',
				SUBIR_ERR_TEXT: 'El certificat ha de pujar-se en format PDF',
				SUBIR_ERR2: 'Arxiu no seleccionat',
				SUBIR_ERR2_TEXT: 'Has de seleccionar o arrossegar un arxiu en el quadre de la zona superior'
			},
			COMPROMISOS: {
				COMP_ENT: "Compromís d'entitats",
				COMP_INFO: "Què és el Compromís d'entitats",
				COMP_TEXT1: 'En alguns casos, les organitzacions en què treballen les mentores volen tenir un compromís més profund amb el projecte, reconèixer i valorar la tasca que estan realitzant aquestes dones i facilitar la seva participació en el projecte. Per a això, hem elaborat el Compromís Inspira STEAM, un document on aquestes organitzacions es comprometen a: ',
				COMP_BUL1: 'Assumir la dedicació de la mentora a Inspira STEAM dins la seva jornada laboral.',
				COMP_BUL2: 'Facilitar la seva assistència a la formació, a les sessions en els centres escolars i als actes de difusió que consideri rellevants.',
				COMP_BUL3: 'Treballar per difondre i sensibilitzar a la seva comunitat dels temes i problemàtica que aborda el programa.',
				COMP_TEXT2: 'Com a reconeixement a aquest compromís, el programa fa visibles aquestes entitats al web del projecte: <a href="https://inspirasteam.net/entidades-compromiso-inspira/" target="_blank"> https://inspirasteam.net/entidades-compromiso-inspira/</a>',
				COMP_DAT: 'Si la teva entitat vol signar aquest compromís, completa els següents passos:',
				TEMP_COMP_TEXT: "Descarrega la plantilla del Compromís d'entitats.",
				SUBIR_TEXT: "Un cop emplenat i signat per la persona responsable de la teva entitat, puja aquí el Compromís d'entitats.",
				MENSAJE_OK: "El document s'ha pujat correctament",
				MENSAJE_NO: 'Pendent pujar el document',
				ARRASTRA: "Arrossega o prem aquí per pujar el Compromís d'entitats (PDF)",
				SUBIR: "Pujar Compromís d'entitats",
				SUBIR_OK: "Document guardat",
				SUBIR_OK_TEXT: "El document s'ha guardat correctament",
				SUBIR_ERR: 'Format Incorrecte',
				SUBIR_ERR_TEXT: 'El document ha de pujar-se en format PDF',
				MAS_INFO: 'Completa les següents dades per visibilitzar la teva entitat a la <a href="https://inspirasteam.net/entidades-compromiso-inspira/" target="_blank"> web.</a>',
				SUBIR_LOGO: "Puja el logo de la teva entitat a qualsevol enllaç públic (web, drive, dropbox ...) i passa'ns l'enllaç.",
				LINK_ENT: "Passa'ns l'enllaç de la pàgina web de la teva entitat."
			},
			DATOSWEB: {
				SUBTITLE: 'Formulari de recollida de dades de mentores per a la web: <a href="https://inspirasteam.net/mentoras/" target="_blank"> inspirasteam.net</a>', // to add: (si ya has sido mentora/mentor revisa tus datos actuales y rellena este formulario solo si deseas cambiarlos)
				CHARACTER: 'caràcters',
				BIO: 'Bio' ,
				BIO_TEXT: 'Resum públic de la teva trajectòria (un, dos paràgrafs). Apareixerà juntament amb les xarxes socials quan algú premi teva foto al web.',
				FOTO: 'Foto',
				FOTO_TEXT: "Puja la teva foto que vulguis que aparegui a qualsevol enllaç públic (web, drive, dropbox ...) i passa'ns l'enllaç. Editarem la teva foto dins d'un cercle (pots veure exemples a la <a href=\"https://inspirasteam.net/mentoras/\" target=\"_blank\"> web de Inspira STEAM</a>). Si ho deixes buit, entendrem que vols que aparegui el logotip d'InspiraSTEAM en lloc de la teva foto.",
				SOCIAL: 'Xarxes socials',
				SOCIAL_TEXT: 'Indica els teus perfils en xarxes socials (el/els enllaços als teus perfils): facebook, twitter, linkedin...',
				NAME: 'Nom',
				NAME_TEXT: 'Tal com vols que aparegui a la web.'
			},
			PASSWORD: {
				ACTUAL: 'Contrasenya actual',
				NUEVA: 'Nueva contrasenya',
				CONFIRM: 'Confirmar contrasenya',
				ERROR: 'La contrasenya actual introduïda no és correcta' ,
				CAMBIO_OK: 'Contrasenya Canviada',
				CAMBIO_OK_TEXT: "La contrasenya s'ha modificat correctament"
			},
			BAJA: {
				AVISO: 'ATENCIÓ: Aquesta acció és irreversible. Si esborres les teves dades hauràs de registrar-te de nou per tornar a participar a Inspira STEAM.',
				INFO: "Si dónes de baixa el compte d'Inspira STEAM confirmes que no participaràs en el mentoring. Totes les teves dades seran esborrats del sistema.",
				BTN: "BAIXA D'INSPIRA STEAM",
				CONFIRMA: "Confirmes donar-te de baixa i esborrar totes les teves dades d'Inspira STEAM?",
				CONFIRMA_BTNOK: 'Sí, donar-me baixa',
				BAJA_OK: 'Compte eliminat',
				BAJA_OK_TEXT: "Aquest compte i totes les seves dades associats s'han esborrat correctament."
			}
		},
		FORMULARIOS: {
			MENTORAS: {
				TITULO: 'Registre de Mentora / Mentor',
				NOMBRE: 'Nom',
				APELLIDOS: 'Cognoms',
				TELEFONO: 'Telèfon',
				ESTUDIOS: 'Estudis o relació amb les STEAM',
				ENTIDAD: 'Entitat',
				OPCIONAL: 'Opcional',
				OTRA: 'Una altra',
				IDIOMAS: "En quin idioma(es) pots fer les sessions d'Inspira STEAM",
				PROVINCIAS: 'Província(es) de preferència per participar a Inspira STEAM',
				PROVINCIAS_DESC: 'Aquesta selecció és orientativa. Posteriorment es concretarà la zona de participació.',
				ZONA: 'Zona, barri o població de preferència per participar a Inspira STEAM',
				SESIONESCON: 'Estic disposada a tenir sessions Inspira STEAM amb:',
				SESIONESCON_DESC: "La primera i l'última sessió són conjuntes, noies i nois junts. De la sessió dos a la cinc es realitzen noies i nois per separat. Selecciona si de la sessió dos a la cinc vols treballar amb noies, amb nois, o amb qualsevol dels dos.",
				GRUPOS: "Estic disposada a realitzar les sessions d'Inspira STEAM amb més d'un grup",
				GRUPOS_CENTRO: 'del mateix centre',
				EXPERIENCIA: 'Has estat ja mentora a Inspira STEAM?',
				EDICIONES: 'Edicions en les que has estat mentora',
				COMENTARIOS: 'Altres comentaris',
				SI: 'Sí',
				NO: 'No',
				NINAS: 'Nenes',
				NINOS: 'Nens',
				GENERO: 'Gènere',
				GENEROFEM: 'Femení',
				GENEROMAS: 'Masculí',
				GENEROTRO: 'Un altre',
				LOCALIDAD: "Localitat(s) de preferència per participar a Inspira STEAM",
				RESIDENCIA: 'Localitat de residència/treball',
				ZONASEUS: 'Zona(es) de preferència per participar a Inspira STEAM. Si us plau, selecciona totes les opcions que puguis.',
				REPETIR: '¿Te gustaría repetir en el mismo centro? Indícanos cuál es: '
			},
			CENTROS: {
				TITULO: 'Registre de Centre',
				NOMBRE: "Nom de centre",
				POBLACION: 'Població',
				CURSO: 'Curs',
				EP6: '6e E.P.',
				ESO1: '1r ESO',
				CONT: 'Persona de contacte',
				CONT_CARGO: 'Càrrec de persona de contacte',
				CONT_NOMBRE: 'Nom i cognoms de persona de contacte',
				CONT_EMAIL: 'E-mail de persona de contacte',
				CONT_TELEFONO: 'Telèfon de persona de contacte',
				IDIOMAS: "En quin idioma voleu les sessions d'Inspira STEAM",
				IDIOMAS_DESC: "Si selecciones més d'un idioma i vols indicar un ordre de preferència, si us plau indica-ho als comentaris. S'intenta complir aquest criteri, encara que en ocasions no és possible pel desequilibri entre la demanda dels centres i la realitat idiomàtica de les mentores. En cas que només s'hagi marcat un idioma i no sigui possible cobrir amb les mentores disponibles, el centre pot quedar fora del programa.",
				GRUPOS: 'Nombre de grups',
				GRUPO_NOMBRE: 'Grup {{num}}. Nom',
				GRUPO_CHICAS: 'Grup {{num}}. Nº de Noies',
				GRUPO_CHICOS: 'Grup {{num}}. Nº de Nois',
				COMENTARIOS: 'Altres comentaris',
				PROVINCIA: 'Província',
				EXPERIENCIA_CENTRO: "Ha participat el centre en edicions anteriors d'Inspira STEAM?",
				EDICIONES_CENTRO: 'Edicions en què ha participat',
				DIRECCION: 'Dirección del centro (Tipo de vía, Nombre de la vía, Número, Código Postal, Localidad)',
				WEB: 'Página web del centro (si existe)'
			}
		},
		TOPBAR: {
			SALUDO: 'Hola',
			PERFIL: 'El meu perfil',
			PERFIL_DETALLE: 'Dades personals i Certificats',
			LOGOUT: 'Tancar Sessió'
		},

		INPUT: {
			VALIDATION: {
				REQUIRED_FIELD: "Has d'omplir aquest camp"
			},
			SUBMIT: 'Guardar Informació'
		}
	}
};
