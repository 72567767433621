import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, EdicionService, NodoService, DocumentoService, LogService, GestionnodoService, EventoService, AsignacionService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';

import{ GlobalVariables } from '../../../_common/global-variables';

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as logoInspira from './inspira.js';
import * as logoDeusto from './deusto.js';
import * as firma from './firma.js';

declare const jsPDF : any;

@Component({
  selector: 'kt-mentora',
  templateUrl: './mentora.component.html',
  styleUrls: ['./mentora.component.scss']
})
export class MentoraComponent implements OnInit {

  
  currentUser: any;
  loading = false;
  nodo: any;
  mentora = null;
  nuevo = true;

  plantCompEntEu = "/assets/docs/CompromisoEntidades_Inspira_ES_EUS.docx";
  plantCompEntEs = "/assets/docs/CompromisoEntidades_Inspira_ES.docx";

  startForm: FormGroup;
  errors: any = [];
  submitted = false;
  ediciones = [];
  edicionActiva: any;
  edicionActivaCentro: any;
  items: FormArray;
  edicionesItem: FormArray;
  error = {idioma: false, participa: false, sesiones: false, provincia: false, desplaza: false};
//   localidades = ["Avilés", "Campomanes", "Ceceda", "Gijón", "Grado", "Jarrio", "La Barganiza", "La Pereda", "Langreo", "Lugones", "Navia", "Oviedo", "Proaza", "San Cucao", "Trubia", "Urbanización La Fresneda", "Viella"];
	localidadesItems: FormArray;
	sublocalidadesItems: FormArray;
//   localidadesEus = ["Aramaio", "Murgia", "Santa Cruz de Campezo", "Laudio-Llodio", "Vitoria – Gasteiz", "Abanto-Zierbena", "Balmaseda",
//   "Ortuella", "Sodupe", "Amorebieta-Etxano", "Berriz", "Bakio", "Barakaldo", "Portugalete", "Santurtzi", "Sestao", "Berriatua",
//   "Ondarroa", "Bilbao", "Erandio", "Bakio", "Gernika", "Arratzu", "Getxo", "Leioa", "Sopela",
//   "Loiu", "Aduna", "Beasain", "Tolosa", "Urnieta", "Villabona", "Donostia-San Sebastián", "Errenteria", "Irun", "Orio","Zarautz", "Zumaia"];
//   localidadesEusItems: FormArray;
  centroForm: FormGroup;
  passwordForm: FormGroup;
  compromisoForm: FormGroup;
  errorGrupos = [];
  zonas = [];
  subzonas = [];
  idiomas = [];
  entidades = [];
  subzonasArray: FormArray;
  edicionesHash = {};
  modalRef1: NgbModalRef;

  //ASIGNACIONES
  rows = [];
  rows1 =[];
  rows2 = [];
  nodos = {};
  centros = [];
  centrosHash = {};
  mentoraSeleccionada = null;
  asignacionForm: FormGroup;
  hoy = {};
  estadoArray: FormArray;
  estados = ["PRE-INSCRITA/O","INSCRITA/O","ASIGNABLE","ASIGNADA/O"];
  borrar = null;
  numeroSeleccionado = 1;
  numGrupos = [1,2,3,4,5];
  generos = ["Chicas", "Chicos", "Mixto"];
  idiomas1 = ["Castellano", "Euskara", "Catalán", "Inglés"];
  elementoExportar = {};
  mentoras = [];
  mentorasHash = {};

  refs = {
    cert4: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2NcsB3s">https://bit.ly/2NcsB3s</a>'},
    cert5: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2V3vHcz">https://bit.ly/2V3vHcz</a>'},
    cert7: {
      formulario: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2DRkchs">https://bit.ly/2DRkchs</a>',
      gerencias: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2U28wzz">https://bit.ly/2U28wzz</a>',
    },
    cert9: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2GuW8op">https://bit.ly/2GuW8op</a>'}
  }

  vista = "personal";

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('certificadoSwal', {static: false}) private certificadoSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('bajaSwal', {static: false}) private bajaSwal: SwalComponent
  @ViewChild('bajaConfirmSwal', {static: false}) private bajaConfirmSwal: SwalComponent
  @ViewChild('changeSwal', {static: false}) private changeSwal: SwalComponent
  @ViewChild('errorUsernameSwal', {static: false}) private errorUsernameSwal: SwalComponent
  @ViewChild('questionDeleteSwal', {static: false}) private questionDeleteSwal: SwalComponent
  @ViewChild('errorEdicionExisteSwal', {static: false}) private errorEdicionExisteSwal: SwalComponent


  constructor(private nodoService: NodoService,
    private edicionService: EdicionService,
    private userService: UserService,
    private documentoService: DocumentoService,
    private eventoService: EventoService,
	private fb: FormBuilder,
	private translate: TranslateService,
    private cdr: ChangeDetectorRef,
	private modalService: NgbModal,
	private logService: LogService,
    private router: Router,
	private gestionNodoService: GestionnodoService,
    private route: ActivatedRoute,
	private asignacionService: AsignacionService,) {
      this.unsubscribe = new Subject();
    }

    openCentred(content) {
      this.modalService.open(content, { centered: true, size: 'lg' } );
    }

  ngOnInit() {
    this.currentUser = null;
    this.route.paramMap.subscribe(params => {
	  this.loadPerfil(params.get("id"));
    });
    this.cdr.detectChanges();
  }

  loadRows(){
    this.asignacionService.getAll()
    .subscribe(data => {
      this.rows = data;
      this.cdr.detectChanges();
	  this.filterDatatable();
	  this.filterMentoras();
    })
  }

  filterMentoras(){
	console.log(this.rows)
	console.log(this.edicionesHash)
	console.log(this.currentUser)
	this.rows2 = this.rows.filter(function(item){
		var mostrar = true;
		if(this.currentUser.id) {
			if(!(this.currentUser.id == item.centro)) mostrar = false;
		}
		return mostrar;
	}.bind(this));
	console.log(this.rows2)
  }

  filterDatatable(){
    // assign filtered matches to the active datatable
    this.rows1 = this.rows.filter(function(item){
      var mostrar = true;
      if(this.currentUser.id) {
        if(!(this.currentUser.id == item.mentora)) mostrar = false;
      }
      //if(this.filters.role != "TODOS" && item.role != this.filters.role) mostrar = false;
      return mostrar;
    }.bind(this));
  }

  loadNodos() {
	this.nodoService.getAll()
	.subscribe(nodos => {
		for(var i = 0; i < nodos.length; i++) {
			this.nodos[nodos[i].id+""]= nodos[i];
		}
		function compare( a, b ) {
			if ( a.name < b.name ){
			return -1;
			}
			if ( a.name > b.name ){
			return 1;
			}
			return 0;
		}
		this.nodo = nodos.sort(compare);
				this.cdr.detectChanges();
	});
  }

  loadCentrosNodo() {
    this.userService.getByRolNodo('centro', this.currentUser.nodo)
	    .subscribe(users => {
        //this.centros = users;
		this.centros = [];
        this.centros.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        for(let centro of users){
			if(centro.estado == "ASIGNABLE"){
				this.centrosHash[centro.id+""] = centro;
				this.centros.push(centro);
			}
        }
        this.cdr.detectChanges();
	});
  }
  loadFechaActual(){
    const fechaActual = new Date();
    const mesCastellano = fechaActual.toLocaleString('es-ES', {month: 'long'})

    let mesActual = new Date().getMonth();
    let mesEuskera;
    switch (mesActual) {
      case 0:
        mesEuskera = "Urtarrila";
        break;
      case 1:
        mesEuskera = "Otsaila";
        break;
      case 2:
        mesEuskera = "Martxoa";
        break;
      case 3:
        mesEuskera = "Apirila";
        break;
      case 4:
        mesEuskera = "Maiatza";
        break;
      case 5:
        mesEuskera = "Ekaina";
        break;
      case 6:
        mesEuskera = "Uztaila";
        break;
      case 7:
        mesEuskera = "Abuztua";
        break;
      case 8:
        mesEuskera = "Iraila";
        break;
      case 9:
        mesEuskera = "Urria";
        break;
      case 10:
        mesEuskera = "Azaroa";
        break;
      case 11:
        mesEuskera = "Abendua";
        break;
      default:
        mesEuskera = "";
    }
    

    const dia = fechaActual.getDate();
    this.hoy = {
      mesCastellano: mesCastellano,
      mesEuskera: mesEuskera,
      dia: dia,
    }
  }

  loadPerfil(id: any) {
    this.userService.getUser(id)
    .subscribe(data => {
      console.log(data);
      this.currentUser = data;
      this.getNodo();
      if(this.currentUser.role == "mentora") {
		this.initStartForm();
		this.loadFormacion();
	  }
	  if(this.currentUser.role == "centro") this.initCentroForm();
	  this.initCompromisoForm();
	  this.loadPerfiles();
	  this.loadZonas();
	  this.loadIdiomas();
	  this.loadEntidades();

	  //ASIGNACIONES
	  this.loadEdiciones();
	  this.loadRows();
	  this.loadNodos();
	  this.loadCentrosNodo();
	  this.loadFechaActual();
	  this.loadMentorasNodo();
      this.cdr.detectChanges();

    });
  }

  cambioVista(vista: any) {
    this.vista = vista;
  }

  openNew(content) {
    this.nuevo = true;
	this.initMaterialForm();
    this.modalRef1 = this.modalService.open(content, { centered: true, size: 'lg' } ); 
  }

  initMaterialForm() {
    const reg = /^(http\:\/\/|https\:\/\/)/;
    this.mentoraSeleccionada = null;
    if(!this.currentUser.nodo){
		this.asignacionForm = this.fb.group({
      edicion: ['', Validators.compose([Validators.required])],
      //mentoraSeleccionada: ['', Validators.compose([Validators.required])],
      centro:  ['', Validators.compose([Validators.required])],
      numGrupos :  ['', Validators.compose([Validators.required])],
      comentarios : [''],
      idioma1: ['', Validators.compose([Validators.required])],
      idioma2: [''],
      idioma3: [''],
      idioma4: [''],
      idioma5: [''],

      genero1: ['',Validators.compose([Validators.required])],
      genero2: [''],
      genero3: [''],
      genero4: [''],
      genero5: [''],
      /*
      name: ['', Validators.compose([Validators.required])],
      fechaSubida: [null],
      role: ['', Validators.compose([Validators.required])],
      contenido: ['', Validators.compose([Validators.required, Validators.pattern(reg)])],
      nodo: [null],      
      estadoArray: this.fb.array([ ])*/
		});
    
    } else {
        this.asignacionForm = this.fb.group({
          edicion: ['', Validators.compose([Validators.required])],
          //mentoraSeleccionada: ['', Validators.compose([Validators.required])],
          centro:  ['', Validators.compose([Validators.required])],
          numGrupos :  ['', Validators.compose([Validators.required])],
          comentarios: [''],

          idioma1: ['', Validators.compose([Validators.required])],
          idioma2: [''],
          idioma3: [''],
          idioma4: [''],
          idioma5: [''],

          genero1: ['', Validators.compose([Validators.required])],
          genero2: [''],
          genero3: [''],
          genero4: [''],
          genero5: [''],
          /*
          name: ['', Validators.compose([Validators.required])],
          fechaSubida: [null],
          role: ['', Validators.compose([Validators.required])],
          contenido: ['', Validators.compose([Validators.required, Validators.pattern(reg)])],
          nodo: [this.currentUser.nodo],      
          estadoArray: this.fb.array([ ])*/
      });
    } this.loadEstados();
  }

  loadMentorasNodo() {
    this.userService.getByRolNodo('mentora', this.currentUser.nodo)
	    .subscribe(users => {
        this.mentoras = users;
        for(let user of this.mentoras) {
          this.mentorasHash[user.id+""] = user;
          //this.loadInscripcion(user);
          if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
        }
        this.cdr.detectChanges();
		});
  }

  loadEstados(){
    this.estadoArray = this.asignacionForm.get('estadoArray') as FormArray;
    if(this.estadoArray)
      for(var i = 0; i < this.estados.length; i++){
        this.estadoArray.push(this.createItemEst(this.estados[i]));
      }
      this.cdr.detectChanges();
  }

  createItemEst(estado1: any): FormGroup {
    var estadoSelect = false;     
    return this.fb.group({
      estado: estado1,
      estadoSelect: estadoSelect
    });
  }

  onNumeroSeleccionado(number: any){
    this.asignacionForm.get('numGrupos').valueChanges.subscribe((numGrupos: number) => {
      for (let i = 1; i <= 5; i++) {
        const idiomaControl = this.asignacionForm.get(`idioma${i}`);
        const generoControl = this.asignacionForm.get(`genero${i}`);
        if (i <= numGrupos) {
          idiomaControl.setValidators([Validators.required]);
          generoControl.setValidators([Validators.required]);
        } else {
          idiomaControl.clearValidators();
          generoControl.clearValidators();
        }
    
        idiomaControl.updateValueAndValidity();
        generoControl.updateValueAndValidity();
      }
    });
  }

  submitAsig(){
    this.submitted = true;
    const controls = this.asignacionForm.controls;

    // check form
	if (this.asignacionForm.invalid) {
		Object.keys(controls).forEach(controlName =>
			controls[controlName].markAsTouched()
		);
		return;
	}
    this.loading = true;
	this.cdr.detectChanges();
	var form = this.asignacionForm.value;
	for(let i = 0 ; i < this.rows1.length ; i++){
		if(this.rows1[i].edicion == form.edicion){
			this.errorEdicionExisteSwal.fire();
			this.loading = false;
			this.cdr.detectChanges();
			return;
		}
	}

    //form.mentora = form.mentoraSeleccionada.id,
    form.num_grupos = form.numGrupos;
    form.fecha_creacion = new Date();
	form.mentora = this.currentUser.id;

    this.asignacionService.post(form)
    .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.rows = [...this.rows, data];
          this.filterDatatable();
          this.cdr.detectChanges();
          this.mentoraSeleccionada = null;
          this.numeroSeleccionado = 1;
          this.modalService.dismissAll();
        },
        error => {
          console.error(error);
					this.loading = false;
        });
  }

  isControlHasError1(controlName: string, validationType: string): boolean {
	const control = this.asignacionForm.controls[controlName];
	if (!control || !this.submitted) {
		return false;
	}

	if(!validationType) {
		const result = control.invalid && (control.dirty || control.touched);
		return result;
	} else {
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
  edicionObj = null;
  edit(content, id) {
    this.nuevo = false;
    for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id == id) {
        var form = this.rows[i];
        this.edicionObj = form;
        this.asignacionForm = this.fb.group({
          edicion: [this.edicionesHash[form.edicion].id, Validators.compose([Validators.required])],
          centro:  [this.centrosHash[form.centro].id, Validators.compose([Validators.required])],
          idioma:  [form.idioma, Validators.compose([Validators.required])],
          numGrupos :  [form.num_grupos, Validators.compose([Validators.required])],
          grupo: [form.grupo, Validators.compose([Validators.required])],
          comentarios : [form.comentarios],
        });
        this.modalService.open(content, { centered: true, size: 'lg' } );
        this.cdr.detectChanges(); 
        break;
      }
    }
  }

  editGroups(){
    if(this.loading) return;
    this.submitted = true;

    const controls = this.asignacionForm.controls;
    if (this.asignacionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
    this.cdr.detectChanges();

    this.loading = true;
    var form = this.asignacionForm.value;
    form.id = this.edicionObj.id;
    form.num_grupos = form.numGrupos;
    if(form.num_grupos == 1){
      form.genero2 = '';
      form.idioma2 = '';
      form.genero3 = '';
      form.idioma3 = '';
      form.genero4 = '';
      form.idioma4 = '';
      form.genero5 = '';
      form.idioma5 = '';
    } 
    else if(form.num_grupos == 2){
      form.genero3 = '';
      form.idioma3 = '';
      form.genero4 = '';
      form.idioma4 = '';
      form.genero5 = '';
      form.idioma5 = '';
    }
    else if(form.num_grupos == 3){
      form.genero4 = '';
      form.idioma4 = '';
      form.genero5 = '';
      form.idioma5 = '';
    }
    else if(form.num_grupos == 4){
      form.genero5 = '';
      form.idioma5 = '';
    }
    this.asignacionService.update(form)
    .pipe(first())
    .subscribe(data => {
      this.loading = false;
      for(let i = 0 ; i < this.rows.length;i++){
        if(this.rows[i].id == this.edicionObj.id){
          this.rows[i] = data.body;
          this.rows = [...this.rows]
          this.cdr.detectChanges();
        }
      }
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    },
    error => {
      console.error(error);
      this.loading = false;
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    })
  }

  editAsig(){
    if(this.loading) return;
    this.submitted = true;

    this.loading = true;
    var form = this.asignacionForm.value;
	for(let i = 0 ; i < this.rows1.length ; i++){
		if(this.rows1[i].edicion == form.edicion){
			this.errorEdicionExisteSwal.fire();
			this.loading = false;
			this.cdr.detectChanges();
			return;
		}
	}

    form.id = this.edicionObj.id;

    form.fechaCreacion = new Date();
    form.mentora = this.currentUser.id;

    this.asignacionService.update(form)
    .pipe(first())
    .subscribe(data => {
      this.loading = false;
      for(let i = 0 ; i < this.rows.length;i++){
        if(this.rows[i].id == this.edicionObj.id){
          this.rows[i] = data.body;
          this.rows = [...this.rows];
          this.filterDatatable();
          this.cdr.detectChanges();
        }
      }
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    },
    error => {
      console.error(error);
      this.loading = false;
      this.modalService.dismissAll();
      this.cdr.detectChanges();
    })
  }

  resizeTextarea() {
    const textarea = document.querySelector('textarea');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  borrarAsignacion(row: any){
    this.borrar = row;
    this.questionDeleteSwal.fire();
  }

  borrarAsignacion1(){
    this.borrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.asignacionService.delete(this.borrar.id)
    .subscribe(data => {
      for(var i = 0 ; i < this.rows.length; i++){
        if(this.rows[i].id == this.borrar.id){
          this.rows.splice(i, 1);
          var datos = [...this.rows];
          this.rows = [];
          this.rows = datos;
          this.filterDatatable();
          this.cdr.detectChanges();
          break;
        }
      }
    })
  }

  group(content, id){
    for(var i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id == id) {
        var form = this.rows[i];
        this.edicionObj = form;
        this.numeroSeleccionado = form.num_grupos;
        this.asignacionForm = this.fb.group({
          numGrupos: [form.num_grupos, Validators.compose([Validators.required])],
          idioma1: [form.idioma1, Validators.compose([Validators.required])],
          idioma2: [form.idioma2, Validators.compose([Validators.required])],
          idioma3: [form.idioma3, Validators.compose([Validators.required])],
          idioma4: [form.idioma4, Validators.compose([Validators.required])],
          idioma5: [form.idioma5, Validators.compose([Validators.required])],
          
          genero1: [form.genero1, Validators.compose([Validators.required])],
          genero2: [form.genero2, Validators.compose([Validators.required])],
          genero3: [form.genero3, Validators.compose([Validators.required])],
          genero4: [form.genero4, Validators.compose([Validators.required])],
          genero5: [form.genero5, Validators.compose([Validators.required])],
        });
        this.modalService.open(content, { centered: true, size: 'lg' } );
        this.cdr.detectChanges(); 
        break;
      }
    }
  }

  async exportar(row: any){
    if(row.loadingTrue) return;
    row.loadingTrue = true;
    this.cdr.detectChanges();
    this.elementoExportar = row;
    this.cdr.detectChanges();

    const doc = new jsPDF();
    doc.addImage(logoInspira.base64, 125, 15, 50, 30);
    doc.addImage(logoDeusto.base64, 30, 30, 60, 15);
    doc.addImage(firma.base64, 95, 230, 30, 20);
    const content = document.getElementById('pdfContent').innerHTML;
    const content1 = document.getElementById('pdfContent1').innerHTML;
    const content4 = document.getElementById('pdfContent4').innerHTML;
    const content5 = document.getElementById('pdfContent5').innerHTML;
    const content6 = document.getElementById('pdfContent6').innerHTML;
    const informa1 = document.getElementById('informa1').innerHTML;
    const informa2 = document.getElementById('informa2').innerHTML;
    const final1 = document.getElementById('final1').innerHTML;
    const final2 = document.getElementById('final2').innerHTML;

    doc.rect(30, 60, 78, 155);
    doc.rect(108, 60, 78, 155);
    doc.fromHTML(content, 34, 63, {
      'width': 70,
      'align': 'justify',
      'lineHeight': 1.5
    });
    doc.fromHTML(content1, 113, 63, {
      'width': 70,
      'align': 'justify',
      'lineHeight': 3
    });

    doc.fromHTML(informa1, 34, 89, {
      'width': 70
    })
    doc.fromHTML(final1, 34, 97, {
      'width': 70
    })

    doc.fromHTML(informa2, 113, 88, {
      'width': 70
    })
    doc.fromHTML(final2, 113, 97, {
      'width': 70
    })

    //FIRMA Y NOMBRE
    doc.fromHTML(content4, 87, 253);
    doc.fromHTML(content5, 71, 260);
    doc.fromHTML(content6, 73, 267);
    
    doc.save(this.currentUser.name + " " + this.currentUser.apellidos + " - " + this.edicionesHash[row.edicion].name + " - " + moment().format("DDMMYYYYHHmmss") + '.pdf');
    row.loadingTrue = false;
    this.cdr.detectChanges();
  }

  /*------------------------------------------------------------------------------------*/

  getNodo(){
    this.nodoService.getById(this.currentUser.nodo)
	    .subscribe(nodo => {
     	this.nodo = nodo;
		this.cdr.detectChanges();
		});
  }

  anterior = null;
  siguiente = null;

  loadFormacion() {
	if(this.currentUser.lastFormacion) {
		this.eventoService.getById(this.currentUser.lastFormacion)
		.subscribe(evento => {
			this.currentUser.formacion = evento.name + " - " + moment(new Date(evento.fecha)).format("DD/MM/YYYY") + " " + evento.hora;
		});
	} else {
		this.currentUser.formacion = null;
	}
  }

  loadPerfiles(){
	var mentoras = [];
	var centros = []
    this.anterior = null;
    this.siguiente = null;
    this.cdr.detectChanges();

    function compare( a, b ) {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    }
		if (this.currentUser.role == 'mentora'){
			var continuar = function() {
				mentoras.sort( compare );
			for(var i = 0; i < mentoras.length; i++) {
				if(mentoras[i].id == this.currentUser.id) {
				if(i > 0) this.anterior = mentoras[i-1];
				if(i < mentoras.length-1) this.siguiente = mentoras[i+1];
				}
			}
			this.cdr.detectChanges();
			}.bind(this);

			if(GlobalVariables.listadoPagina == "mentora") {
				mentoras = GlobalVariables.listado;
			continuar();
			} else {
			this.userService.getByRolNodo('mentora',this.currentUser.nodo)
				.subscribe(users => {
					mentoras = users;
				GlobalVariables.listadoPagina = "mentora";
				GlobalVariables.listado = users;
				continuar();
			});
			}
		}else if (this.currentUser.role == 'centro'){
			var continuar = function() {
				centros.sort( compare );
			for(var i = 0; i < centros.length; i++) {
				if(centros[i].id == this.currentUser.id) {
				if(i > 0) this.anterior = centros[i-1];
				if(i < centros.length-1) this.siguiente = centros[i+1];
				}
			}
			this.cdr.detectChanges();
			}.bind(this);

			if(GlobalVariables.listadoPagina == "centro") {
				centros = GlobalVariables.listado;
			continuar();
			} else {
			this.userService.getByRolNodo('centro',this.currentUser.nodo)
				.subscribe(users => {
					centros = users;
				GlobalVariables.listadoPagina = "centro";
				GlobalVariables.listado = users;
				continuar();
			});
			}
		}
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  // ---- MENTORA - INFORMACION PERSONAL ----

  	loadEdiciones() {
		console.log("loadEdiciones()");
		this.edicionService.getActive()
		.subscribe(activeEdiciones =>{
			for(let activeEdicion of activeEdiciones){
				this.edicionActiva = activeEdicion.id;
			}
			
		});
		this.edicionService.getAllInactive()
	    .subscribe(ediciones => {
			console.log(ediciones);
			this.ediciones = ediciones;
			//this.cdr.detectChanges();
			for(let edicion of ediciones){
				this.edicionesHash[edicion.id+""] = edicion;
			}
			this.cdr.detectChanges();

			this.items = this.startForm.get('items') as FormArray;
			for(var i = 0; i < this.ediciones.length; i++){
				this.items.push(this.createItem(this.ediciones[i]));
      		}
			
      		this.cdr.detectChanges();
		});
	}
	zonasName = [];
	zonasSel = {};
	zonaSeleccionada = false;

	loadZonas(){
		this.gestionNodoService.getZonasByNodo(this.currentUser.nodo)
		.subscribe(zonas => {
		this.zonas = zonas;
		for(var i = 0; i < zonas.length; i++) {
			this.zonasName[zonas[i].id+""] = zonas[i];
		}
		this.localidadesItems = this.startForm.get('localidadesItems') as FormArray;
			if(this.localidadesItems) {
				for(var i = 0; i < this.zonas.length; i++) {
					this.localidadesItems.push(this.createItemLoc(this.zonas[i].nombre, null, this.zonas[i].id));
				}
			}

			var localidadesItems = this.startForm.value.localidadesItems;
			this.zonaSeleccionada = false;
			for(var i = 0; i < localidadesItems.length; i++) {
				this.zonasSel[localidadesItems[i].zonaId + ""] = localidadesItems[i].desplaza;
				if(localidadesItems[i].desplaza ) {
					this.zonaSeleccionada = true;
				}
			}
			this.cdr.detectChanges();

			this.startForm.get("localidadesItems").valueChanges.subscribe(x => {

				this.zonaSeleccionada = false;
				for(var i = 0; i < x.length; i++) {
					this.zonasSel[x[i].zonaId + ""] = x[i].desplaza;
					if(x[i].desplaza ) {
						this.zonaSeleccionada = true;
					}
				}
				this.cdr.detectChanges();
			})
			this.cdr.detectChanges();
		});
	}
 
	loadSubzonas(nodo){
		this.gestionNodoService.getSubzonasByNodo(nodo)
		.subscribe(subzonas => {
			this.subzonas = subzonas;
			this.sublocalidadesItems = this.startForm.get('sublocalidadesItems') as FormArray;
			if(this.sublocalidadesItems) {
				for(var i = 0; i < this.subzonas.length; i++) {
					this.sublocalidadesItems.push(this.createItemSubLoc(this.subzonas[i].nombre, this.subzonas[i].zona, null));
				}
			}
		this.cdr.detectChanges();
		});
	}

	loadIdiomas(){
		this.gestionNodoService.getIdiomasByNodo(this.currentUser.nodo)
		.subscribe(idiomas => {
		this.idiomas = idiomas;
		this.cdr.detectChanges();
		});
	}

	loadEntidades(){
		this.gestionNodoService.getEntidadesByNodo(this.currentUser.nodo)
		.subscribe(entidades => {
		this.entidades = entidades;
		this.cdr.detectChanges();
		});
	}


	// loadPoblaciones() {
	// 	this.localidadesItems = this.startForm.get('localidadesItems') as FormArray;
	// 	if(this.localidadesItems)
	// 		for(var i = 0; i < this.localidades.length; i++){
	// 			this.localidadesItems.push(this.createItemLoc(this.localidades[i]));
	// 		}
	// 	this.cdr.detectChanges();
	// }

//   loadPoblacionesEus(){
//     this.localidadesEusItems = this.startForm.get('localidadesEusItems') as FormArray;
//     if(this.localidadesEusItems)
//       for(var i = 0; i < this.localidadesEus.length; i++){
//         this.localidadesEusItems.push(this.createItemLoc(this.localidadesEus[i]));
//       }
//       this.cdr.detectChanges();
//   }

	createItem(edicion: any): FormGroup {
		var participa = false;
		if(this.currentUser.ediciones && this.currentUser.ediciones.includes(edicion.name)){
			participa = true;
		}
		return this.fb.group({
			edicion: edicion.name,
			participa: participa
		});
	}

	createItemLoc(localidad: any, zona: any, zonaId: any): FormGroup {
		var desplaza = false;
		if(this.currentUser.localidades && this.currentUser.localidades.includes(localidad)){
			desplaza = true;
		}
		return this.fb.group({
			nombre: localidad,
			desplaza: desplaza,
			zona: zona,
			zonaId: zonaId
		});
	}

	createItemSubLoc(localidad: any, zona: any, zonaId: any): FormGroup {
		var desplaza = false;
		if(this.currentUser.subzonas && this.currentUser.subzonas.includes(localidad)){
			desplaza = true;
		}
		return this.fb.group({
			nombre: localidad,
			desplaza: desplaza,
			zona: zona,
			zonaId: zonaId
		});
	}

	
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initStartForm() {
		var masGrupos = "no";
		if(this.currentUser.masGrupos) masGrupos = "si";
		var expMentora = "no";
		if(this.currentUser.expMentora) expMentora = "si";

		if(this.currentUser.nodo == 1) {	//Euskadi
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				idiomaEu: [this.currentUser.idiomaEu],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				provAra: [this.currentUser.provAra],
				provBiz: [this.currentUser.provBiz],
				provGip: [this.currentUser.provGip],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 3) {	//Asturies
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});

		} else if(this.currentUser.nodo == 7 || this.currentUser.nodo == 8|| this.currentUser.nodo == 9 || this.currentUser.nodo == 11 || this.currentUser.nodo == 12) { //Murcie, Jaen, Txile y Pontevedra
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 4 || this.currentUser.nodo == 5) {	//Barcelona
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				idiomaCat: [this.currentUser.idiomaCat],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 6) {	//Canarias
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				provTen: [this.currentUser.provTen],
				provGca: [this.currentUser.provGca],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 2) {	//Madrid
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 10) {	// Inspira STEAM Plus
			var provincia = "";
			if(this.currentUser.provMad) provincia = "Madrid"
			else if(this.currentUser.provBcn) provincia = "Barcelona"
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],
				idiomaCat: [this.currentUser.idiomaCat],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				prov: [provincia, Validators.compose([Validators.required])],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo != 1 && this.currentUser.nodo != 2 &&this.currentUser.nodo != 3 &&this.currentUser.nodo != 4 && this.currentUser.nodo != 5 && 
			this.currentUser.nodo != 6 &&this.currentUser.nodo != 7 && this.currentUser.nodo != 8 && this.currentUser.nodo != 9 && this.currentUser.nodo != 10 && this.currentUser.nodo != 11 && this.currentUser.nodo != 12) {
			this.startForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
        		estado: [this.currentUser.estado],				
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		}
		this.loadEdiciones();
		this.loadSubzonas(this.currentUser.nodo);
		// this.loadPoblaciones();
		// this.loadPoblacionesEus();
  	}

	/**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.submitted = true;

		const controls = this.startForm.controls;

		console.log(controls);

		if(this.currentUser.nodo == 1 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaEu) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if((this.currentUser.nodo == 4 || this.currentUser.nodo == 5) && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaCat) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if(this.currentUser.nodo == 2 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if(!this.startForm.value.sesionesNinas && !this.startForm.value.sesionesNinos) {
			this.error.sesiones = true;
		} else {
			this.error.sesiones = false;
		}

		if(this.currentUser.nodo == 1) {
			if(!this.startForm.value.provAra && !this.startForm.value.provBiz && !this.startForm.value.provGip) {
				this.error.provincia = true;
			} else {
				this.error.provincia = false;
			}
		}

		if(this.currentUser.nodo == 6) {
			if(!this.startForm.value.provTen && !this.startForm.value.provGca) {
				this.error.provincia = true;
			} else {
				this.error.provincia = false;
			}
		}

		var ediciones = [];

		if(this.startForm.value.expMentora == "si") {
			console.log(this.startForm.value);
			var items = this.startForm.value.items;
			var selected = false;
			ediciones = [];
			for(var i = 0; i < items.length; i++) {
				if(items[i].participa) {
					selected = true;
					ediciones.push(items[i].edicion);
				}
			}
			if(!selected) {
				this.error.participa = true;
			} else {
				this.error.participa = false;
			}
		} else {
			this.error.participa = false;
		}

		var localidades = [];

		// if(this.currentUser.nodo == 3) {
		// 	var localidadesItems = this.startForm.value.localidadesItems;
		// 	var selectedLoc = false;
		// 	localidades = [];
		// 	for(var i = 0; i < localidadesItems.length; i++) {
		// 		if(localidadesItems[i].desplaza) {
		// 			selectedLoc = true;
		// 			localidades.push(localidadesItems[i].nombre);
		// 		}
		// 	}
		// 	if(!selectedLoc) {
		// 		this.error.desplaza = true;
		// 	} else {
		// 		this.error.desplaza = false;
		// 	}
		// } else {
		// 	this.error.desplaza = false;
		// }

    	if(this.zonas.length != 0) {
			var localidadesItems = this.startForm.value.localidadesItems;
			var selectedLoc = false;
			localidades = [];
			for(var i = 0; i < localidadesItems.length; i++) {
				if(localidadesItems[i].desplaza) {
					selectedLoc = true;
					localidades.push(localidadesItems[i].nombre);
				}
			}
			if(!selectedLoc) {
				this.error.desplaza = true;
			} else {
				this.error.desplaza = false;
			}
		} else {
			this.error.desplaza = false;
		}

		var subzonas = [];

		if(this.subzonas.length != 0) {
			var sublocalidadesItems = this.startForm.value.sublocalidadesItems;
			subzonas = [];
			for(var i = 0; i < sublocalidadesItems.length; i++) {
				if(sublocalidadesItems[i].desplaza) {
					subzonas.push(sublocalidadesItems[i].nombre);
				}
			}
		}

		// check form
		if (this.startForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if(this.error.idioma || this.error.participa || this.error.sesiones || this.error.provincia){
			console.log(this.error);
			return;
		}

		this.loading = true;

		var currentUser = this.currentUser;
		var form = this.startForm.value;
		form.localidades = JSON.stringify(localidades);
		form.subzonas = JSON.stringify(subzonas);
		if(form.expMentora == "si") {
			form.expMentora = true;
			form.ediciones = JSON.stringify(ediciones);
		} else {
			form.expMentora = false;
			form.ediciones = null;
		}
		if(form.masGrupos == "si") form.masGrupos = true;
		else form.masGrupos = false;
		if(form.entidadopt != null && form.entidadopt != "") {
			if(form.entidadopt != "Otra") form.entidad = form.entidadopt;
		}

		if(form.prov == "Madrid") {
			form.provMad = true;
			form.provBcn = false;
		} else if(form.prov == "Barcelona") {
			form.provBcn = true;
			form.provMad = false;
		}

		if(form.estado != 'PRE-INSCRITA' && form.estado != 'FORMULARIO') {
			form.edicion = this.edicionActiva;
		} else if(form.estado == 'PRE-INSCRITA' || form.estado == 'FORMULARIO'){ 
			form.edicion = null;
		}

		form.id = currentUser.id;

		console.log(form);

		this.userService.update(form)
            .pipe(first())
            .subscribe(
                data => {

          			//window.scrollTo(0, 0);
					console.log("OK", data);
					currentUser.username = form.username;
					currentUser.name = form.name;
					currentUser.apellidos = form.apellidos;
					currentUser.comentarios = form.comentarios;
					currentUser.ediciones = form.ediciones;
					currentUser.estudios = form.estudios;
					currentUser.expMentora = form.expMentora;
					if(form.idiomaEn) currentUser.idiomaEn = form.idiomaEn;
					if(form.idiomaEs) currentUser.idiomaEs = form.idiomaEs;
					if(form.idiomaEu) currentUser.idiomaEu = form.idiomaEu;
					if(form.idiomaCat) currentUser.idiomaCat = form.idiomaCat;
					currentUser.entidad = form.entidad;
					currentUser.entidadopt = form.entidadopt;
					currentUser.estado = form.estado;
					currentUser.edicion = form.edicion;
					if(form.provAra) currentUser.provAra = form.provAra;
					if(form.provBiz) currentUser.provBiz = form.provBiz;
					if(form.provGip) currentUser.provGip = form.provGip;
					if(form.provTen) currentUser.provTen = form.provTen;
					if(form.provGca) currentUser.provGca = form.provGca;
					if(form.provMad) currentUser.provMad = form.provMad;
					if(form.provBcn) currentUser.provBcn = form.provBcn;
					currentUser.masGrupos = form.masGrupos;
					currentUser.sesionesNinas = form.sesionesNinas;
					currentUser.sesionesNinos = form.sesionesNinos;
					currentUser.telefono = form.telefono;
					currentUser.genero = form.genero;
					if(form.localidades) currentUser.localidades = form.localidades;
					if(form.subzonas) currentUser.subzonas = form.subzonas;
          			//currentUser.localidadesEus = form.localidades;
					if(form.zona) currentUser.zona = form.zona;
					if (form.repCent) currentUser.repCent = form.repCent;

					this.logService.post({tipo: "EDITAR-PERFIL", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
            			.pipe(first()).subscribe();

          			this.currentUser = currentUser;

          			this.loading = false;
					this.confirmationSwal.fire();

					this.initStartForm();
        },
        error => {
            console.error(error);
			this.errorUsernameSwal.fire();
			this.loading = false;
			this.cdr.detectChanges();
        });
	}
	initCompromisoForm(){
		if (this.submitted = false) {
			this.compromisoForm = this.fb.group({
			compLogo: ['', Validators.compose([Validators.required])],
			compWeb: ['', Validators.compose([Validators.required])],
			items: this.fb.array([ ])
		});
		} else if (this.submitted = true) {
			this.compromisoForm = this.fb.group({
			compLogo: [this.currentUser.compLogo, Validators.compose([Validators.required])],
			compWeb: [this.currentUser.compWeb, Validators.compose([Validators.required])],
			items: this.fb.array([ ])
		});

		}
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.startForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  	}

	// ---- CENTRO - INFORMACION CENTRO ----

	loadEdicionesCentro() {
		console.log("loadEdicionesCentro()");
		this.edicionService.getActive()
		.subscribe(activeEdicionesCentro =>{
			for(let activeEdicionCentro of activeEdicionesCentro){
				this.edicionActivaCentro = activeEdicionCentro.id;
			}
		});
		
		this.edicionService.getAllInactive()
		.subscribe(ediciones => {
			console.log(ediciones);
			this.ediciones = ediciones;
			//this.cdr.detectChanges();
			this.edicionesItem = this.centroForm.get('edicionesItem') as FormArray;
			if(this.edicionesItem){
				for(var i = 0; i < this.ediciones.length; i++){
					this.edicionesItem.push(this.createItemEdicion(this.ediciones[i]));
				}
			}
			  this.cdr.detectChanges();
		});
	}

	createItemCentro(grupo: any): FormGroup {
		var grupo = grupo;
		if(grupo == null) grupo = {nombre: '', chicas: null, chicos: null}
		return this.fb.group({
			nombre: [grupo.nombre || "", Validators.compose([Validators.required])],
			chicas: [grupo.chicas, Validators.compose([Validators.required])],
			chicos: [grupo.chicos, Validators.compose([Validators.required])],
		});
	}

	createItemEdicion(edicion: any): FormGroup {
		var participa = false;
		if(this.currentUser.ediciones && this.currentUser.ediciones.includes(edicion.name)){
			participa = true;
		}
		return this.fb.group({
			edicion: edicion.name,
			participa: participa
		});
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initCentroForm() {
		try{
			this.currentUser.gruposArray = JSON.parse(this.currentUser.gruposArray);
		} catch (e){}
		if(this.currentUser.nodo == 1) {
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
        		estado: [this.currentUser.estado],
				//idiomaEu: [this.currentUser.idiomaEu],
				//idiomaEs: [this.currentUser.idiomaEs],
				//idiomaEn: [this.currentUser.idiomaEn],
				idioma: [this.currentUser.idioma],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				investigacion: [this.currentUser.investigacion],
				preFin: [this.currentUser.preFin],
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 3) {	//Asturies
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
        		estado: [this.currentUser.estado],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 4 || this.currentUser.nodo == 5 || this.currentUser.nodo == 10) {	//Barcelona y Plus
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
       			estado: [this.currentUser.estado],
				idiomaCat: [this.currentUser.idiomaCat],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				investigacion: [this.currentUser.investigacion],
				preFin: [this.currentUser.preFin],
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 6 || this.currentUser.nodo == 7 || this.currentUser.nodo == 8) {	//Canarias, Murtxia, Jaén y Pontevedra
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
        		estado: [this.currentUser.estado],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 11 || this.currentUser.nodo == 12) {	// Pontevedra,Ourense		
		var expCentro = "no";
		if(this.currentUser.expCentro) expCentro = "si";
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				//provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estado: [this.currentUser.estado],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 2) {	//Madrid
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
        		estado: [this.currentUser.estado],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		}else if(this.currentUser.nodo == 9) {	//Txile
			this.centroForm = this.fb.group({
				username: [this.currentUser.username, Validators.compose([Validators.required])],
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
        		estado: [this.currentUser.estado],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo != 1 && this.currentUser.nodo != 2 &&this.currentUser.nodo != 3 &&this.currentUser.nodo != 4 && this.currentUser.nodo != 5 && 
			this.currentUser.nodo != 6 &&this.currentUser.nodo != 7 && this.currentUser.nodo != 8 && this.currentUser.nodo != 9 && this.currentUser.nodo != 10 && this.currentUser.nodo != 11 && this.currentUser.nodo != 12) {
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estado: [this.currentUser.estado],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		}
		// if(this.currentUser.nodo == 11 || this.currentUser.nodo == 12) {
		// 	this.loadEdicionesCentro();
		// }
		this.loadEdicionesCentro();
		this.items = this.centroForm.get('items') as FormArray;
		if(this.currentUser.gruposArray){
			for(var i = 0; i < this.currentUser.gruposArray.length; i++){
				this.items.push(this.createItemCentro(this.currentUser.gruposArray[i]));
			}
		}
		//this.gruposCambiados();
	}

	gruposCambiados() {
		var grupos = this.centroForm.value.grupos;
		console.log(grupos);
		if(grupos < this.items.length) {
		var size = this.items.length;
		for(var i = size; i > grupos; i--){
			this.items.removeAt(i-1);
		}
		} else if(grupos > this.items.length) {
		for(var i = this.items.length; i < grupos; i++){
			this.items.push(this.createItemCentro(null));
		}
		}


	}

	/**
	 * Form Submit
	 */
	onSubmitCentro() {
		if(this.loading) return;

		this.submitted = true;

		const controls = this.centroForm.controls;

		if(this.currentUser.nodo == 1 && !this.centroForm.value.idioma/*En && !this.centroForm.value.idiomaEs && !this.centroForm.value.idiomaEu*/) {
			this.error.idioma = true;
			console.warn("ERROR IDIOMA");
		} else if((this.currentUser.nodo == 4 || this.currentUser.nodo == 5) && !this.centroForm.value.idiomaEn && !this.centroForm.value.idiomaEs && !this.centroForm.value.idiomaCat) {
			this.error.idioma = true;
		} else if(this.currentUser.nodo == 2 && !this.centroForm.value.idiomaEn && !this.centroForm.value.idiomaEs) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		var ediciones = [];

		if(this.currentUser.nodo == 11 || this.currentUser.nodo == 12){
			if (this.centroForm.value.expCentro == "si") {
				var edicionesItem = this.centroForm.value.edicionesItem;
				var selected = false;
				ediciones = [];
				for(var i = 0; i < edicionesItem.length; i++) {
					if(edicionesItem[i].participa) {
						selected = true;
						ediciones.push(edicionesItem[i].edicion); //ya ha elegido edicion
					}
				}
				if(!selected) {
					this.error.participa = true;
				} else {
					this.error.participa = false;
				}
			} else {
				this.error.participa = false;
			}
		}

		// check form
		if (this.centroForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		console.warn(this.error);

		if(this.error.idioma || this.error.participa){
			return;
		}

    	this.loading = true;

		var currentUser = this.currentUser;
		var form = this.centroForm.value;
		form.id = currentUser.id;
		form.grupos = parseInt(form.grupos);
		form.gruposArray = JSON.stringify(form.items);
			if(form.expCentro == "si") {
				form.expCentro = true;
				form.ediciones = JSON.stringify(ediciones);
			} else {
				form.expCentro = false;
				form.ediciones = null;
			}

		if(form.estado != 'PRE-INSCRITO' && form.estado != 'FORMULARIO'){
			form.edicion = this.edicionActivaCentro;
			console.warn(form.edicion);
		} else if (form.estado == 'PRE-INSCRITO' || form.estado == 'FORMULARIO'){
			form.edicion = null;
		}

		this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          	console.log("OK", data);

			currentUser.username = form.username;
			currentUser.comentarios = form.comentarios;
			if(form.curso) currentUser.curso = form.curso;
			currentUser.grupos = form.grupos;
			if(form.idiomaEn) currentUser.idiomaEn = form.idiomaEn;
			if(form.idiomaEs) currentUser.idiomaEs = form.idiomaEs;
			if(form.idiomaEu) currentUser.idiomaEu = form.idiomaEu;
			if(form.idiomaCat) currentUser.idiomaCat = form.idiomaCat;
			if(form.idioma) currentUser.idioma = form.idioma;
			currentUser.gruposArray = form.gruposArray;
			currentUser.personaContacto = form.personaContacto;
			currentUser.nombreContacto = form.nombreContacto;
			currentUser.emailContacto = form.emailContacto;
			currentUser.poblacion = form.poblacion;
			if(form.provincia) currentUser.provincia = form.provincia;
			currentUser.telefono = form.telefono;
			currentUser.estado = form.estado;
			currentUser.edicion = form.edicion;
			if(form.ediciones) currentUser.ediciones = form.ediciones;
			if(form.expCentro) currentUser.expCentro = form.expCentro;
			if(form.zona) currentUser.zona = form.zona;
			if(form.investigacion) currentUser.investigacion = form.investigacion;
			if(form.preFin) currentUser.preFin = form.preFin;
			if(form.dirCentro) currentUser.dirCentro = form.dirCentro;
			if(form.webCentro) currentUser.webCentro = form.webCentro;

			this.currentUser = currentUser;

			this.logService.post({tipo: "EDITAR-PERFIL", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
				.pipe(first()).subscribe();

          	this.loading = false;
			this.confirmationSwal.fire();
			this.initCentroForm();
			this.cdr.detectChanges();
        },
        error => {
			console.error(error);
			this.errorUsernameSwal.fire();
			this.loading = false;
			this.cdr.detectChanges();
        });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasErrorCentro(controlName: string, validationType: string): boolean {
		const control = this.centroForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  	isControlHasErrorGrupos(num: any, controlName: string, validationType: string): boolean {
		const control = this.centroForm.controls.items['controls'][num].controls[controlName];
		if (!control || !this.submitted) {
			return false;
    }

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType);
			return result;
		}
	}


  // ------------------- CERTIFICADOS -------------------------

  files: File[] = [];
  image: any;



  onSelect(event) {
    console.log(event);
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);


    console.log(event.addedFiles[0]);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  subirCertificado() {
    if(this.loading) return;
    if(this.files.length == 0) {
      this.errorNotFileSwal.fire();
      return;
    }
    this.loading = true;
    var myReader:FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;

      var doc = {user: this.currentUser.id, tipo: "CERT_DEL_SEX", contenido: myReader.result, name: this.files[0].name, fechaSubida: new Date(), nodo: this.currentUser.nodo};

      this.documentoService.post(doc)
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);

            var form = {id: this.currentUser.id, certificado: data.id, subidaCertificado: new Date()};

            this.userService.update(form)
              .pipe(first())
              .subscribe(
                data => {
                  console.log("OK", data);

                  this.currentUser.certificado = form.certificado;
                  this.currentUser.subidaCertificado = form.subidaCertificado;

				  this.logService.post({tipo: "CERTIFICADO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: form.certificado})
            			.pipe(first()).subscribe();

                  this.loading = false;
                  this.certificadoSwal.fire();
                  this.cdr.detectChanges();
                },
                error => {
                  console.error(error);
                  //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
                  this.loading = false;
                });

          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }
    myReader.readAsDataURL(this.files[0]);
  }

  descargarCertificado() {
    this.documentoService.getById(this.currentUser.certificado)
    .subscribe(data => {
      console.log(data);
      var element = document.createElement('a');
      element.setAttribute('href', data.contenido);
      element.setAttribute('download', data.name);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    });
  }

  descargarCompromiso() {
    this.documentoService.getById(this.currentUser.compromiso)
    .subscribe(data => {
      console.log(data);
      var element = document.createElement('a');
      element.setAttribute('href', data.contenido);
      element.setAttribute('download', data.name);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    });
  }
  

  descargarPlantilla() {
    var url = "";
    var name = "";
    if(this.currentUser.nodo == 1) {
          url = this.plantCompEntEu;
          name = "Plantilla_Compromiso_Entidad";
        } else {
          url = this.plantCompEntEs;
          name = "Plantilla_Compromiso_Entidad";
		}
	this.logService.post({tipo: "DESCARGA", fecha: new Date(), user: this.currentUser.id, details: "Compromiso", referencia: null})
		.pipe(first()).subscribe();

	let link = document.createElement('a');
	link.setAttribute('type', 'hidden');
	link.href = url;
	link.download = name;
	document.body.appendChild(link);
	link.click();
	link.remove();
}


  filesComp: File[] = [];
  imageComp: any;

  onSelectComp(event) {
    console.log(event);
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.filesComp = [];
    this.filesComp.push(...event.addedFiles);


    console.log(event.addedFiles[0]);
  }

  onRemoveComp(event) {
    console.log(event);
    this.filesComp.splice(this.files.indexOf(event), 1);
  }
  
  subirCompromiso() {
	if(this.loading) return;
	if(this.filesComp.length == 0) {
		this.errorNotFileSwal.fire();
		return;
	}

	// const controls = this.compromisoForm.controls;
	// if (this.compromisoForm.invalid) {
	// 	Object.keys(controls).forEach(controlName =>
	// 		controls[controlName].markAsTouched()
	// 	);
	// 	return;
	// }
	this.loading = true;

    var myReaderComp:FileReader = new FileReader();
    myReaderComp.onloadend = (e) => {
      this.imageComp = myReaderComp.result;

      var doc = {user: this.currentUser.id, tipo: "CERT_COMP_ENT", contenido: myReaderComp.result, name: this.filesComp[0].name, fechaSubida: new Date(), nodo: this.currentUser.nodo};

      this.documentoService.post(doc) //post at DB table documento
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);
			var form = this.compromisoForm.value;
			form.compromiso = data.id;
			form.id = this.currentUser.id
            //var form = {id: this.currentUser.id, compromiso: data.id, subidaCompromiso: new Date()};

            this.userService.update(form)
              .pipe(first())
              .subscribe(
                data => {
                  console.log("OK", data);
				//write at the table users
				  this.currentUser.compromiso = form.compromiso;
				//   this.currentUser.compLogo = form.compLogo; 
                //   this.currentUser.compWeb = form.compWeb;

				  //this.authenticationService.changeUser(this.currentUser);

				//   this.logService.post({tipo: "CERTIFICADO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: form.certificado})
            	// 		.pipe(first()).subscribe();

                  this.loading = false;
				  this.confirmationSwal.fire();
				  this.cdr.detectChanges();
				//   this.compromisoSwal.fire();
				  this.initCompromisoForm();
                },
                error => {
                  console.error(error);
                  //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
                  this.loading = false;
                });

          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }
    myReaderComp.readAsDataURL(this.filesComp[0]);
  }
  subirCompromiso1() {
	if(this.loading) return;
	// if(this.filesComp.length == 0) {
	// 	this.errorNotFileSwal.fire();
	// 	return;
	// }

	const controls = this.compromisoForm.controls;
	if (this.compromisoForm.invalid) {
		Object.keys(controls).forEach(controlName =>
			controls[controlName].markAsTouched()
		);
		return;
	}
	this.loading = true;

    // var myReaderComp:FileReader = new FileReader();
    // myReaderComp.onloadend = (e) => {
    //   this.imageComp = myReaderComp.result;

    //   var doc = {user: this.currentUser.id, tipo: "CERT_COMP_ENT", contenido: myReaderComp.result, name: this.filesComp[0].name, fechaSubida: new Date(), nodo: this.currentUser.nodo};

     		var form = this.compromisoForm.value;
			form.id = this.currentUser.id
            //var form = {id: this.currentUser.id, compromiso: data.id, subidaCompromiso: new Date()};

            this.userService.update(form)
              .pipe(first())
              .subscribe(
                data => {
                  console.log("OK", data);
				//write at the table users
				//   this.currentUser.compromiso = form.compromiso;
				  this.currentUser.compLogo = form.compLogo; 
                  this.currentUser.compWeb = form.compWeb;

				  //this.authenticationService.changeUser(this.currentUser);

				//   this.logService.post({tipo: "CERTIFICADO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: form.certificado})
            	// 		.pipe(first()).subscribe();

                  this.loading = false;
				  this.confirmationSwal.fire();
				//   this.compromisoSwal.fire();
				  this.initCompromisoForm();
                },
                error => {
                  console.error(error);
                  //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
                  this.loading = false;
                });
  		}

  isControlHasErrorComp(controlName: string, validationType: string): boolean {
	const control = this.compromisoForm.controls[controlName];
	if (!control || !this.submitted) {
		return false;
	}

	if(!validationType) {
		const result = control.invalid && (control.dirty || control.touched);
		return result;
	} else {
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
  }

  // ------------------------- BAJA -------------------------------

  	baja(){
      if(this.loading) return;
      this.bajaSwal.fire()
  	}

  	baja1(){
      this.loading = true;
      var form = {id: this.currentUser.id, isActive: false, username: this.currentUser.username + "-" + moment().unix()}
      this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);

          this.loading = false;
          this.bajaConfirmSwal.fire();

          this.logService.post({tipo: "BAJA", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
                    .pipe(first()).subscribe();
        },
        error => {
          console.error(error);
          //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
          this.loading = false;
        });
    }


}
