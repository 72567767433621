import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, EdicionService, NodoService, LogService, DocumentoService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';

@Component({
  selector: 'kt-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {

  currentUser: any;
  loading = false;
  kitCentrosEs = "/assets/docs/InspiraSTEAM_KitInformacion_Centros_ES.pdf";
  kitCentrosEu = "/assets/docs/InspiraSTEAM_KitInformacion_Centros_EU.pdf";
  kitMentorasEs = "/assets/docs/InspiraSTEAM_KitInformacion_Mentora_ES.pdf";
  kitMentorasEu = "/assets/docs/InspiraSTEAM_KitInformacion_Mentora_EU.pdf";

  kitMentorasEsAsturias = "/assets/docs/InspiraSTEAM_KitInformacion_Mentora_Asturias.pdf"
  kitCentrosEsAsturias = "/assets/docs/InspiraSTEAM_KitInformacion_Centros_Asturias.pdf";

  kitCentrosEsBarcelona = "/assets/docs/InspiraSTEAMBarcelona_KitInformacion_Centros_ES.pdf";
  kitMentorasEsBarcelona = "/assets/docs/InspiraSTEAMBarcelona_KitInformacion_Mentora_ES.pdf";

  kitCentrosEsTarragona = "/assets/docs/InspiraSTEAMTarragona_KitInformacion_Centros.pdf";
  kitMentorasEsTarragona = "/assets/docs/InspiraSTEAMTarragona_KitInformacion_Mentora.pdf";

  @ViewChild('lecturaMentSwal', {static: false}) private lecturaMentSwal: SwalComponent
  @ViewChild('lecturaCentSwal', {static: false}) private lecturaCentSwal: SwalComponent
  @ViewChild('confirmationMentSwal', {static: false}) private confirmationMentSwal: SwalComponent
  @ViewChild('confirmationCentSwal', {static: false}) private confirmationCentSwal: SwalComponent

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private documentoService: DocumentoService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private logService: LogService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private router: Router) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.loadDocumentos();
  }

  download(role: any) {
    var url = "";
    var name = "";
    if(this.currentUser.nodo == 1) {
      if(role == "mentora") {
        if(this.translate.currentLang == "es") {
          url = this.kitMentorasEs;
          name = "Kit_de_Informacion_de_Mentoras.pdf";
        } else if(this.translate.currentLang == "eu") {
          url = this.kitMentorasEu;
          name = "Informazio_KITa_AHOLKULARIENTZAT.pdf";
        }
      } else if(role == "centro") {
        if(this.translate.currentLang == "es") {
          url = this.kitCentrosEs;
          name = "Kit_de_Informacion_de_Centros.pdf";
        } else if(this.translate.currentLang == "eu") {
          url = this.kitCentrosEu;
          name = "Informazio_KITa_IKASTETXEentzat.pdf";
        }
      }
    } else if(this.currentUser.nodo == 3) {
      if(role == "mentora") {
        url = this.kitMentorasEsAsturias;
        name = "Kit_de_Informacion_de_Mentoras.pdf";
      } else if(role == "centro") {
        url = this.kitCentrosEsAsturias;
        name = "Kit_de_Informacion_de_Centros.pdf";
      }
    } else if(this.currentUser.nodo == 4) {
      if(role == "mentora") {
        url = this.kitMentorasEsBarcelona;
        name = "Kit_de_Informacion_de_Mentoras.pdf";
      } else if(role == "centro") {
        url = this.kitCentrosEsBarcelona;
        name = "Kit_de_Informacion_de_Centros.pdf";
      }
    } else if(this.currentUser.nodo == 5) {
      if(role == "mentora") {
        url = this.kitMentorasEsTarragona;
        name = "Kit_de_Informacion_de_Mentoras.pdf";
      } else if(role == "centro") {
        url = this.kitCentrosEsTarragona;
        name = "Kit_de_Informacion_de_Centros.pdf";
      }
    }


    this.logService.post({tipo: "DESCARGA", fecha: new Date(), user: this.currentUser.id, details: "KIT", referencia: null})
            .pipe(first()).subscribe();

    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  kitLeido(){
    if(this.loading) return;
    if(this.currentUser.role == "mentora") this.lecturaMentSwal.fire()
    else this.lecturaCentSwal.fire();
  }

  kitLeido1(){
    if(this.loading) return;
    this.loading = true;
    var user = {id: this.currentUser.id, leidoKit: true};
    this.userService.update(user)
      .pipe(first())
      .subscribe(
        data => {
					console.log("OK", data);
					this.currentUser.leidoKit = user.leidoKit;

          this.authenticationService.changeUser(this.currentUser);
          this.cdr.detectChanges();

          this.logService.post({tipo: "KIT-LEIDO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
            .pipe(first()).subscribe();

          if(this.currentUser.role == "mentora") this.confirmationMentSwal.fire()
          else this.confirmationCentSwal.fire();
        },
        error => {
          console.error(error);
        });
  }

  // ------------------- DOCUMENTOS -------------------------

  originalRows = [];
  rowsDoc = [];

  loadDocumentos(){
    this.documentoService.getMaterialByNodoRole(this.currentUser.nodo, this.currentUser.role)
    .subscribe(data => {
      var estadoVar = this.currentUser.estado;
      if(this.currentUser.role=='centro' && this.currentUser.estado == 'ASIGNADO'){
        estadoVar = 'ASIGNADA/O';
      }else if (this.currentUser.role=='centro' && this.currentUser.estado == 'PRE-INSCRITO'){
        estadoVar = 'PRE-INSCRITA/O';
      }else if (this.currentUser.role=='centro' && this.currentUser.estado == 'INSCRITO'){
        estadoVar = 'INSCRITA/O';
      }
      if(this.currentUser.role=='mentora' && this.currentUser.estado == 'ASIGNADA'){
        estadoVar = 'ASIGNADA/O';
      }else if (this.currentUser.role=='mentora' && this.currentUser.estado == 'PRE-INSCRITA'){
        estadoVar = 'PRE-INSCRITA/O';
      }else if (this.currentUser.role=='mentora' && this.currentUser.estado == 'INSCRITA'){
        estadoVar = 'INSCRITA/O';
      }
     
      for(let doc of data) {
        var estados = JSON.parse(doc.estado);       
        doc.fechaObj = new Date(doc.fechaSubida);
        var fecha = doc.fechaSubida.substring(0, doc.fechaSubida.indexOf('+')) + "Z";
        doc.fecha = moment(fecha).format("DD/MM/YYYY HH:mm");
      if(estados)
      for(let estado of estados){
        //console.warn(estado, estadoVar);
        if(estado == estadoVar){
          doc.estado = estadoVar;
        }
      }
      
    } this.originalRows = data;
    this.rows1 = this.originalRows;
      this.rowsDoc = this.originalRows.filter(function(item){
        var mostrar = false;
        if(item.estado == estadoVar){
          mostrar = true;
        }
        
      return mostrar;
    }.bind(this));
      this.cdr.detectChanges();
    });
  }

  verDocumento(doc: any){
    //window.open(doc.contenido, "_blank");
    var element = document.createElement('a');
    element.setAttribute('href', doc.contenido);
    element.setAttribute('download', doc.nombre);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }


  filtrosOpen = false;
  rows1 = [];
  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null
  }

  filterDatatable(){
    
    // assign filtered matches to the active datatable
    this.rows1 = this.rowsDoc.filter(function(item){
      var mostrar = true;
     
      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.name.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      return mostrar;
    }.bind(this));
    
  }

  resetFilters() {

    this.filters = {
      nombre: null
    }
    this.filterDatatable();
  }

}
