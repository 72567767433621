// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';

import { NodoService, UserService, AuthenticationService, LogService } from '../../../../_services';

@Component({
	selector: 'kt-password-recovery',
	templateUrl: './password-recovery.component.html',
	encapsulation: ViewEncapsulation.None
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	role = null;
	submitted = false;
	nodos: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private route: ActivatedRoute,
		private router: Router,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private nodoService: NodoService,
		private userService: UserService,
		private logService: LogService,
		private authenticationService: AuthenticationService
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			code: ['', Validators.compose([Validators.required])],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(100)
			])
			]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
    if(this.loading) return;
		
		this.submitted = true;
		

		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
    }

    var hash = this.route.snapshot.paramMap.get('hash');
    
    var user = this.registerForm.value;
    user.hash = hash;

    this.loading = true;

    console.log(user);
    
    this.userService.changepasswordrec(user)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          this.loading = false;
          if(data["error"] == "CODE"){
            console.log("ERROR!!");
            this.authNoticeService.setNotice("El código de recuperación introducido no es correcto.", 'danger');
            this.cdr.detectChanges();
          } else if(data["error"] == "HASH"){
            console.log("ERROR!!");
            this.authNoticeService.setNotice("Este enlace de recuperación no es válido o ya ha sido utilizado.", 'danger');
            this.cdr.detectChanges();
          } else {
            console.log("OKOK");
            this.authNoticeService.setNotice("La contraseña ha sido cambiada con éxito, inicia sesión para acceder a tu cuenta.", 'success');
            this.cdr.detectChanges();
            this.router.navigate(['/auth/login']);
          }
        },
        error => {
          console.error(error);
        });
    
    return;

		this.loading = true;

		var user = this.registerForm.value;
		user.lang = this.translate.currentLang;
		user.role = this.role;
		
		this.userService.register(user)
            .pipe(first())
            .subscribe(
                data => {
                    console.log("OK", data);
                    this.authenticationService.login(this.registerForm.value.username, this.registerForm.value.password)
			            .pipe(first())
			            .subscribe(
			                data => {
								this.logService.post({tipo: "REGISTRO", fecha: new Date(), user: data.id, details: null, referencia: data.id})
								.pipe(first()).subscribe();
								if(this.role == "mentora") this.router.navigate(['/start/mentoras']);
								else if(this.role == "centro") this.router.navigate(['/start/centros']);
			                },
			                error => {
								console.error(error);
								this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
			                    this.loading = false;
			                });
                },
                error => {
                    console.error(error);
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
					this.loading = false;
                });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
	}
}
