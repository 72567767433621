import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
 
@Injectable()
export class AsignacionService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/asignacions`);
    }

    getById(id: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/asignacions/${id}`);
    }

    post(asignacion: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/asignacions`,asignacion);
    }

    update(asignacion: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}/api/asignacions/${asignacion.id}`,asignacion, {observe: 'response'});
    }

    delete(id: any): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/api/asignacions/${id}`);
    }
}