import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
 
@Injectable()
export class GestionnodoService {
    constructor(private http: HttpClient) { }

    getAllZonas() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/zonas`);
    }

    getAllSubzonas() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/subzonas`);
    }

    getAllIdiomas() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/idiomas`);
    }

    getAllEntidades() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/entidades`);
    }

    getZonasByNodo(nodo: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/zonas?nodo=${nodo}`);
    }

    getSubzonasByNodo(nodo: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/subzonas?nodo=${nodo}`);
    }

    getIdiomasByNodo(nodo: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/idiomas?nodo=${nodo}`);
    }

    getEntidadesByNodo(nodo: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/entidades?nodo=${nodo}`);
    }

    getSubzonasByZona(zona: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/subzonas?zona=${zona}`);
    }

    postZona(zona: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/zonas`,zona);
    }

    postSubzona(subzona: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/subzonas`,subzona);
    }

    postIdioma(idioma: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/idiomas`,idioma);
    }

    postEntidad(entidad: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/entidades`,entidad);
    }

    deleteZona(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/zonas/${id}`);
    }

    deleteSubzona(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/subzonas/${id}`);
    }

    deleteIdioma(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/idiomas/${id}`);
    }

    deleteEntidad(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/entidades/${id}`);
    }
}