import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NodoService, UserService, AuthenticationService, GruposService, CountService } from '../../../_services';

import { first } from 'rxjs/operators';

//import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as XLSX from 'xlsx'; 
import * as moment from 'moment';

@Component({
  selector: 'kt-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.scss']
})
export class GruposComponent implements OnInit {


  currentUser: any;
  nodos: any = [];
  rows = [];
  grupos = [];
  usersInvestigacion = [];

  constructor(private userService: UserService,
    private GruposService: GruposService,
    private cdr: ChangeDetectorRef,
    private nodoService: NodoService,
    private countService: CountService,
    private authenticationService: AuthenticationService) { }

  loadCentros() {
    this.userService.getByRol('centro')
    .subscribe(users => {
      for(var i=0; i < users.length; i++){
        if(users[i].investigacion == 1){
          this.usersInvestigacion.push(users[i]);
        var grupos1 = JSON.parse(users[i].gruposArray)     
          for ( const obj of grupos1) {
            obj.nombreCentro = users[i].name;
            obj.centroId = users[i].id; 
            this.grupos.push(obj);
          }
        }  
      }
      this.loadParticipantesPre(this.grupos);
      this.filterDatatable();
      this.cdr.detectChanges();
    });
  }  
  
  loadCentrosNodo() {
    this.userService.getByRolNodo('centro', this.currentUser.nodo)
	    .subscribe(users => {
      for(var i=0; i < users.length; i++){
        if(users[i].investigacion == 1){
          this.usersInvestigacion.push(users[i]);
          var grupos1 = JSON.parse(users[i].gruposArray)
          for ( const obj of grupos1) {
            obj.nombreCentro = users[i].name;
            obj.centroId = users[i].id;
            obj.edicion = users[i].edicion;
            this.grupos.push(obj);
          }  
        }
      }
      this.loadParticipantesPre(this.grupos);
      //console.log(this.grupos);  
      this.filterDatatable();
			this.cdr.detectChanges();
		});
  }

  loadParticipantesPre(centros: any) {
    for(const object of centros){
        this.countService.getCountCuestionario1(object.edicion, 'pre', object.nombre, object.centroId)
        .subscribe(count => {
          // console.log(count);
          object.participantesPre = count;
         this.cdr.detectChanges();
          // console.log(centros.participantes);
          this.grupos.push(object.participantesPre);
      });
      this.countService.getCountCuestionario1(object.edicion, 'post', object.nombre, object.centroId)
        .subscribe(count => {
          // console.log(count);
          object.participantesPost = count;
         this.cdr.detectChanges();
          // console.log(centros.participantes);
          this.grupos.push(object.participantesPost);
      });
      this.countService.getCountCuestionario2(object.edicion, 'pre', object.nombre, object.centroId)
      .subscribe(count => {
        // console.log(count);
        object.participantesPre2 = count;
       this.cdr.detectChanges();
        // console.log(centros.participantes);
        this.grupos.push(object.participantesPre2);
    });
    this.countService.getCountCuestionario2(object.edicion, 'post', object.nombre, object.centroId)
      .subscribe(count => {
        // console.log(count);
        object.participantesPost2 = count;
       this.cdr.detectChanges();
        // console.log(centros.participantes);
        this.grupos.push(object.participantesPost2);
    });
    }
  }
  
  // generateLinks(){
  //   this.GruposService.getByCuestionario('cuest_est1s')
  //     .subscribe(cuest_est1s=> {
  //     console.log(cuest_est1s);
  //   })
  // }
  
  exportexcel(): void 
  {
    var datos = [...this.rows];
    for (var i=0; i<datos.length; i++){
      var dato:any; 
      dato = {...datos[i]};//copy in variable dato each object of datos.
      dato.participantes = dato.chicas+dato.chicos;
      delete dato.chicas;
      delete dato.chicos;
      datos[i] = dato;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Centros');

    /* save to file */
    XLSX.writeFile(wb, "Centros_" + moment().format("DDMMYYYY-HHmm") + ".xlsx");
  }
  loadNodos() {
		console.log("loadNodos()");
		this.nodoService.getAllActive()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""] = nodos[i];
      }
			this.cdr.detectChanges();
		});
  }
  
  

  ngOnInit() {
    //this.loadCentros();
    this.currentUser = this.authenticationService.currentUserValue;
    if(this.currentUser.nodo) {
      this.loadCentrosNodo();
    } else {
      this.loadCentros();
      this.loadNodos();
    }
  }

    
  // ------- FILTROS -------------

  filtrosOpen = false;

  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null,
    nombreCentro: null
  }

  filterDatatable(){
    // assign filtered matches to the active datatable
    this.rows = this.grupos.filter(function(item){
      var mostrar = true;
      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre || !item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }
      if(this.filters.nombreCentro && this.filters.nombreCentro != null && this.filters.nombreCentro != "") {
        if(!item.nombreCentro || !item.nombreCentro.toLowerCase().includes(this.filters.nombreCentro.toLowerCase())) mostrar = false;
      }
      return mostrar;
    }.bind(this));
  }

  resetFilters() {
    this.filters = {
      nombre: null,
      nombreCentro: null
    }
    this.filterDatatable();
  }

}
