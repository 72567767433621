import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NodoService, UserService, AuthenticationService, EventoService, InscritoService, CountService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
// RxJS
import { first } from 'rxjs/operators';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'kt-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent

  nodos = {};
  rows = [];
  rowsModal = []
  rowsModalAcep = [];
  rowsModalRech = [];
  users = {};
  currentUser: any;

  loadingVisible = false;

  vistaModal = "aceptado";
  eventForm: FormGroup;

  constructor(private eventoService: EventoService,
    private nodoService: NodoService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private userService: UserService,
    private inscritoService: InscritoService,
    private countService: CountService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
    }

  loadEventos() {
    this.eventoService.getAll()
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fecha = moment(new Date(events[i].fecha)).format("DD/MM/YYYY");
          this.loadAsistencias(events[i]);
        }
        this.rows = events;
        this.rows1 = this.rows;
        this.cdr.detectChanges();
      });
  }  

  loadEventosNodo() {
    this.eventoService.getAllAdmin(this.currentUser.nodo, this.currentUser.edicion, 'reunion')
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fecha = moment(new Date(events[i].fecha)).format("DD/MM/YYYY");
          this.loadAsistencias(events[i]);
        }
        this.rows = events;
        this.cdr.detectChanges();
      });
  }  



  loadAsistencias(evento: any) {
    this.countService.getCountAsistencias(evento.id)
    .subscribe(count => {
      evento.asistentes = count;
      this.cdr.detectChanges();
    });
  }

  loadUsers() {
    this.userService.getAllActive()
	    .subscribe(users => {
        for(var i = 0; i < users.length; i++) {
          this.users[users[i].id+""] = users[i];
        }
		});
  }  

  loadNodos() {
		console.log("loadNodos()");
		this.nodoService.getAll()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""] = nodos[i];
      }
			this.cdr.detectChanges();
		});
	}

  ngOnInit() {
    //this.loadEventos();
    this.loadNodos();
    this.loadUsers();
    this.currentUser = this.authenticationService.currentUserValue;
    if(this.currentUser.nodo) {
      this.loadEventosNodo();
    } else {
      this.loadEventos();
    }
  }

  openCentred(content, id) {
    console.log("OPEN: " + id);
    this.rowsModal = []; 
    this.rowsModalAcep = [];
    this.rowsModalRech = [];
    this.modalService.open(content, { centered: true, size: 'lg' } ); 
    this.inscritoService.getByEvento(id)
    .subscribe(ins => {
      for(var i = 0; i < ins.length; i++) {
        ins[i].fecha = new Date(ins[i].fecha).toLocaleString();
        if(this.users[ins[i].user]) {
          ins[i].nombre = this.users[ins[i].user].name + " " + this.users[ins[i].user].apellidos;
          ins[i].email = this.users[ins[i].user].username;
          if(ins[i].asiste) this.rowsModalAcep.push(ins[i])
          else this.rowsModalRech.push(ins[i]);
        }
        
      }
      this.vistaModal = "aceptado";
      this.rowsModal = this.rowsModalAcep;
      this.cdr.detectChanges();
    });
  }

  changeTab(tab: any){
    if(tab == "aceptado"){
      this.vistaModal = "aceptado";
      this.rowsModal = this.rowsModalAcep;
    } else {
      this.vistaModal = "rechazado";
      this.rowsModal = this.rowsModalRech;
    }
  }

  exportexcel(): void 
  {
    var datos = [...this.rowsModalAcep];
    for(var i = 0; i < datos.length; i++){
      delete datos[i].user;
      delete datos[i].evento;
      delete datos[i].asiste;
      delete datos[i].id;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);

    var datos1 = [...this.rowsModalRech];
    for(var i = 0; i < datos1.length; i++){
      delete datos1[i].user;
      delete datos1[i].evento;
      delete datos1[i].asiste;
      delete datos1[i].id;
    }
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos1);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Asist. Confirmadas');
    XLSX.utils.book_append_sheet(wb, ws1, 'Asist. Rechazadas');

    /* save to file */
    XLSX.writeFile(wb, "Asistencias_" + moment().format("DDMMYYYY-HHmm") + ".xlsx");
  }

  submitted = false;
  loading = false;

  initEventForm() {
		this.eventForm = this.fb.group({
			name: ['', Validators.compose([Validators.required])],
      nameEu: [''],
      fecha: [null, Validators.compose([Validators.required])],
      hora: [null, Validators.compose([Validators.required])],
      visible: [true, Validators.compose([Validators.required])],
      role: ['', Validators.compose([Validators.required])],
      rechazar: [true, Validators.compose([Validators.required])],
      descr: ['', Validators.compose([Validators.required])],
      descrEu: [''],
      descExt: ['', Validators.compose([Validators.required])],
      descExtEu: [''],
      tipo: ['reunion'],
      link: [''],
      linkEu: [''],
      lugar: ['', Validators.compose([Validators.required])],
      lugarEu: [''],
		});
  }

  openNew(content) {
    this.initEventForm();
    this.modalService.open(content, { centered: true, size: 'lg' } ); 
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.eventForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }

  /**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.submitted = true;
		
		const controls = this.eventForm.controls;

		// check form
		if (this.eventForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		
		this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
    var form = this.eventForm.value;
    
    //delete form.fecha;

		form.nodo = currentUser.nodo;
    form.tipo = "reunion";
    form.edicion = currentUser.edicion;

    form.fecha = moment(form.fecha).add(12, 'hours').toDate();

    console.log(form);
		
		this.eventoService.post(form)
      .pipe(first())
      .subscribe(
        data => {
          data.asistentes = 0;
          data.fecha = moment(new Date(data.fecha)).format("DD/MM/YYYY");
          this.rows = [...this.rows, data];
          this.confirmationSwal.fire();
          this.cdr.detectChanges();

          console.log("COMPLETADO");
          this.loading = false;
          this.modalService.dismissAll();
        },
        error => {
          console.error(error);
					this.loading = false;
        });
  }
  
  visibility(visible: any, id: any) {
    if(this.loadingVisible) return;
    this.loadingVisible = true;
    this.cdr.detectChanges();
    var evento = {id: id, visible: visible};
    this.eventoService.update(evento)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          for(var i = 0; i < this.rows.length; i++) {
            if(this.rows[i].id == id) {
              this.rows[i].visible = visible;
              this.loadingVisible = false;
              this.cdr.detectChanges();
              break;
            }
          }
        });
  }

  filtrosOpen = false;
  rows1 = [];
  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null,
    nodoFil: null,
    role: "TODOS"
  }

  filterDatatable(){
    
    // assign filtered matches to the active datatable
    this.rows1 = this.rows.filter(function(item){
      var mostrar = true;
      if(!this.currentUser.nodo && this.nodos[item.nodo]){
        var nodo = this.nodos[item.nodo].name;
        if(this.filters.nodoFil && this.filters.nodoFil != null && this.filters.nodoFil != "") {
          if(!nodo.toLowerCase().includes(this.filters.nodoFil.toLowerCase())) mostrar = false;
        }
      }

      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.name.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.role != "TODOS" && item.role != this.filters.role) mostrar = false;
      
      return mostrar;
    }.bind(this));
    
  }

  resetFilters() {
    
    this.filters = {
      nombre: null,
      nodoFil: null,
      role: "TODOS"
    }
    this.filterDatatable();
  }

}
