import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, EdicionService, NodoService, EventoService, InscritoService, LogService, AsignacionService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';
import * as logoInspira from './inspira.js';
import * as logoDeusto from './deusto.js';
import * as firma from './firma.js';

declare const jsPDF : any;

@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('inscripcionReuSwal', {static: false}) private inscripcionReuSwal: SwalComponent
  @ViewChild('confirmationReuSwal', {static: false}) private confirmationReuSwal: SwalComponent
  @ViewChild('rechazarReuSwal', {static: false}) private rechazarReuSwal: SwalComponent
  @ViewChild('confirmationRechReuSwal', {static: false}) private confirmationRechReuSwal: SwalComponent
  @ViewChild('descargarInformeSwal', {static: false}) private descargarInformeSwal: SwalComponent
  currentUser: any;
  nodo = {id:"", name: "", edicion: null}
  edicionActiva = {id: "", name: ""};
  edicionUser: any;
  reuniones = [];
  reunion = {name: "", nameEu: "", fecha: null, fechaEs: "", fechaEu: "", id: null, hora: "", descExt: "", link: "", lugar: ""};
  loading = false;
  loading1 = false;
  loading2 = false;
  lastAsignacionExiste = false;
  mentoras = [];
  mentorasHash = {};
  centros = [];
  centrosHash = {};
  asignacion: any;
  elementoExportar: any;
  edicionesHash = {};
  ediciones = [];
  loadingExportar = false;
  descargado = false;

  refs = {
    cert4: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2NcsB3s">https://bit.ly/2NcsB3s</a>'},
    cert5: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/36zdZpT">https://bit.ly/36zdZpT</a>'},
    cert7: {
      formulario: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2DRkchs">https://bit.ly/2DRkchs</a>',
      gerencias: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2U28wzz">https://bit.ly/2U28wzz</a>',
    },
    cert9: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2GuW8op">https://bit.ly/2GuW8op</a>'}
  }

  mySubscription: any;

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private translate: TranslateService,
    private logService: LogService,
    private eventoService: EventoService,
    private inscritoService: InscritoService,
    private router: Router,
    private asignacionService : AsignacionService,) { }

  openCentred(content) {
    this.modalService.open(content, { centered: true, size: 'lg' } );
  }



  getNodo(cb){
    this.nodoService.getById(this.currentUser.nodo)
	    .subscribe(nodo => {
      this.nodo = nodo;
      console.log(this.nodo);
      cb(this);
			//this.cdr.detectChanges();
		});
  }

  getEdicionActiva(cb){
    this.edicionService.getById(this.nodo.edicion)
	    .subscribe(edicion => {
      this.edicionActiva = edicion;
      console.log(this.edicionActiva);
      cb(this);
			//this.cdr.detectChanges();
		});
  }

  getEdicionUser(cb){
    if(this.currentUser.edicion != null) {
      this.edicionService.getById(this.currentUser.edicion)
        .subscribe(edicion => {
        this.edicionUser = edicion;
        cb(this);
        //this.cdr.detectChanges();
      });
    } else {
      cb(this);
    }

  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getNodo(function(this1){
      this1.getEdicionUser(function(this1){
        if(this1.nodo.edicion != this1.currentUser.edicion){
          this1.getEdicionActiva(function(this1){
            this1.cdr.detectChanges();

            if(this1.currentUser.nuevoUsuario) this1.inscribirme();
          });
        } else {
          this1.edicionActiva = this1.edicionUser;
          this1.getLastAsignacion();
          this1.cdr.detectChanges();
        }
      });
    });
    this.loadCentrosNodo();
    this.loadMentorasNodo();
    this.loadReuniones();
    this.loadEdiciones();
    this.loadFormaciones();
  }

  loadMentorasNodo() {
    this.userService.getByRolNodo('mentora', this.currentUser.nodo)
	    .subscribe(users => {
        this.mentoras = users;
        for(let user of this.mentoras) {
          this.mentorasHash[user.id+""] = user;
          //this.loadInscripcion(user);
          if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
        }
        this.cdr.detectChanges();
		});
  }

  loadEdiciones(){
    this.edicionService.getAll()
    .subscribe(ediciones => {
      for(let edicion of ediciones){
        this.edicionesHash[edicion.id+""] = edicion;
      }
      this.ediciones = ediciones;
      this.cdr.detectChanges();
    })
  }

  loadCentrosNodo() {
    this.userService.getByRolNodo('centro', this.currentUser.nodo)
	    .subscribe(users => {
        this.centros = users;
        this.centros.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        for(let centro of users){
          this.centrosHash[centro.id+""] = centro;
        }
        this.cdr.detectChanges();
		});
  }

  getLastAsignacion(){
    this.asignacionService.getAll()
    .subscribe(data => {
      let asignacionesUsuario = [];
      for(let i = 0 ; i < data.length; i++){
        if(data[i].mentora == this.currentUser.id && data[i].edicion == parseInt(this.edicionActiva.id) - 1){
          this.lastAsignacionExiste = true;
          asignacionesUsuario.push(data[i]);
          this.cdr.detectChanges();
        }
      }
      this.elementoExportar = asignacionesUsuario.reduce((masReciente, elemento) => {
        if (!masReciente || elemento.fecha_creacion > masReciente.fecha_creacion) {
          return elemento;
        } else {
          return masReciente;
        }
      }, null);
      if(this.lastAsignacionExiste){
        //this.descargarInformeSwal.fire();
      }
    })
  }

  inscBorrar(){
    
  }

  exportar(){
    if(this.loadingExportar) return;
    this.loadingExportar = true;
    this.cdr.detectChanges();
    const doc = new jsPDF();
    doc.addImage(logoInspira.base64, 125, 15, 50, 30);
    doc.addImage(logoDeusto.base64, 30, 30, 60, 15);
    doc.addImage(firma.base64, 95, 230, 30, 20);
    const content = document.getElementById('pdfContent').innerHTML;
    const content1 = document.getElementById('pdfContent1').innerHTML;
    const content4 = document.getElementById('pdfContent4').innerHTML;
    const content5 = document.getElementById('pdfContent5').innerHTML;
    const content6 = document.getElementById('pdfContent6').innerHTML;
    const informa1 = document.getElementById('informa1').innerHTML;
    const informa2 = document.getElementById('informa2').innerHTML;
    const final1 = document.getElementById('final1').innerHTML;
    const final2 = document.getElementById('final2').innerHTML;

    doc.rect(30, 60, 78, 155);
    doc.rect(108, 60, 78, 155);
    doc.fromHTML(content, 34, 63, {
      'width': 70,
      'align': 'justify',
      'lineHeight': 1.5
    });
    doc.fromHTML(content1, 113, 63, {
      'width': 70,
      'align': 'justify',
      'lineHeight': 3
    });

    doc.fromHTML(informa1, 34, 89, {
      'width': 70
    })
    doc.fromHTML(final1, 34, 97, {
      'width': 70
    })

    doc.fromHTML(informa2, 113, 88, {
      'width': 70
    })
    doc.fromHTML(final2, 113, 97, {
      'width': 70
    })

    //FIRMA Y NOMBRE
    doc.fromHTML(content4, 87, 253);
    doc.fromHTML(content5, 71, 260);
    doc.fromHTML(content6, 73, 267);
    
    doc.save(this.mentorasHash[this.elementoExportar.mentora].name + " " + this.mentorasHash[this.elementoExportar.mentora].apellidos + " - " + moment().format("DDMMYYYYHHmmss") + '.pdf');
    this.loadingExportar = false;
    this.descargado = true;
    this.cdr.detectChanges();
  }

  inscribirme() {
    this.loading = true;
    var user = {id: this.currentUser.id, edicion: this.edicionActiva.id, estado: null, formacionNo: null, formacion: null, nuevoUsuario: false};
    if(this.currentUser.role == "mentora") {
      user.estado = "INSCRITA";
      if(this.currentUser.estado == 'ASIGNABLE' || this.currentUser.estado == 'ASIGNADA') {
        var mail = {email: this.currentUser.username, user: this.currentUser.name, edicion: this.edicionActiva.name, link1: "https://cuestionarios.inspirasteam.net/ment1/" + this.currentUser.nodo, link2: "https://cuestionarios.inspirasteam.net/ment2/" + this.currentUser.nodo};

        this.userService.mailEdicion(mail)
          .pipe(first()).subscribe();
      } else {
        var mail2 = {email: this.currentUser.username, user: this.currentUser.name, edicion: this.edicionActiva.name, rol: "mentoras"};

        this.userService.mailEdicionNuevo(mail2)
          .pipe(first()).subscribe();
      }
    } else {
      user.estado = "INSCRITO";

      if(this.currentUser.estado == 'ASIGNABLE' || this.currentUser.estado == 'ASIGNADO') {
        var mail = {email: this.currentUser.username, user: this.currentUser.name, edicion: this.edicionActiva.name, link1: "https://cuestionarios.inspirasteam.net/cent/" + this.currentUser.nodo, link2: "https://cuestionarios.inspirasteam.net/est3/" + this.currentUser.nodo};

        this.userService.mailEdicionCentro(mail)
          .pipe(first()).subscribe();
      } else {
        var mail2 = {email: this.currentUser.username, user: this.currentUser.name, edicion: this.edicionActiva.name, rol: "centros"};

        this.userService.mailEdicionNuevo(mail2)
          .pipe(first()).subscribe();
      }
    }
    this.userService.update(user)
      .pipe(first())
      .subscribe(
        data => {
					console.log("OK", data);
					this.currentUser.edicion = user.edicion;
          this.currentUser.estado = user.estado;
          this.currentUser.nuevoUsuario = false;

          this.logService.post({tipo: "INSCRIPCION", fecha: new Date(), user: this.currentUser.id, details: null, referencia: user.edicion})
            .pipe(first()).subscribe();

          this.loading = false;
          this.authenticationService.changeUser(this.currentUser);

          this.confirmationSwal.fire();
          this.cdr.detectChanges();
          this.router.navigate(['/']);
        },
        error => {
          console.error(error);
        });
  }

  loadReuniones() {
    this.eventoService.getAllHome(this.currentUser.role, this.currentUser.nodo)
    .subscribe(events => {
      console.log(events);
      this.reuniones = events;
      for(var i = 0; i < this.reuniones.length; i++) {
        this.reuniones[i].fechaEs = moment(this.reuniones[i].fecha).format("DD/MM/YYYY");
        this.reuniones[i].fechaEu = moment(this.reuniones[i].fecha).format("YYYY/MM/DD");
      }
      this.loadInscrito();
      this.cdr.detectChanges();
    });
  }

  loadInscrito() {
    //getByEventoUser
    this.inscritoService.getByUser(this.currentUser.id)
    .subscribe(ins => {
      console.log(ins);
      for(var i = 0; i < ins.length; i++) {
        for(var j = 0; j < this.reuniones.length; j++) {
          if(ins[i].evento == this.reuniones[j].id) {
            if(ins[i].asiste) this.reuniones[j].asistencia = 1
            else this.reuniones[j].asistencia = -1;
            break;
          }
        }
      }
      this.cdr.detectChanges();
    });
  }

  formaciones = false;
  formacionesList = null;
  formacion = null;

  loadFormaciones() {
    this.eventoService.getAllFormacionesEdicion(this.currentUser.role, this.currentUser.nodo, this.currentUser.edicion)
    .subscribe(events => {
      this.formacionesList = events;
      console.log(events);
      if(events.length > 0) {
        this.formaciones = true;
      }
      this.loadInscritoForm();
      this.cdr.detectChanges();
    });
  }

  loadInscritoForm() {
    this.inscritoService.getByUserAsiste(this.currentUser.id)
    .subscribe(ins => {
      console.log(ins);
      for(var i = 0; i < ins.length; i++) {
        for(var j = 0; j < this.formacionesList.length; j++) {
          if(ins[i].evento == this.formacionesList[j].id) {
            this.formacionesList[j].fechaEs = moment(this.formacionesList[j].fecha).format("DD/MM/YYYY");
            this.formacionesList[j].fechaEu = moment(this.formacionesList[j].fecha).format("YYYY/MM/DD");
            this.formacion = this.formacionesList[j];
            break;
          }
        }
      }
      this.cdr.detectChanges();
    });
  }

  confirmarAsistencia(reunion: any) {
    this.reunion = reunion;
    if(this.translate.currentLang == "es" || this.translate.currentLang == "cat") this.inscripcionReuSwal.title = this.reunion.name + "<br>" +  this.reunion.fechaEs + " " + this.reunion.hora;
    else if(this.translate.currentLang == "eu") this.inscripcionReuSwal.title = this.reunion.nameEu + "<br>" +  this.reunion.fechaEu + " " + this.reunion.hora;

    this.inscripcionReuSwal.fire();
  }

  confirmarAsistencia1() {
    this.loading1 = true;

    var ins = {user: this.currentUser.id, evento: this.reunion.id, fecha: new Date(), asiste: true};

    this.inscritoService.post(ins)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);

          var mail = {email: this.currentUser.username, user: this.currentUser.name, nombre: this.reunion.name, fecha: this.reunion.fechaEs + " " + this.reunion.hora, descripcion: this.reunion.descExt, lugar: this.reunion.lugar, link: this.reunion.link};

          this.eventoService.mailevento(mail)
            .pipe(first()).subscribe();

          this.logService.post({tipo: "EVENTO", fecha: new Date(), user: this.currentUser.id, details: "SI", referencia: this.reunion.id})
            .pipe(first()).subscribe();

          this.loading1 = false;
          if(this.translate.currentLang == "es" || this.translate.currentLang == "cat") this.confirmationReuSwal.text = this.translate.instant('HOME.EVENTO_CONFIRM_TEXT', {evento: this.reunion.name})
          else if(this.translate.currentLang == "eu") this.confirmationReuSwal.text = this.translate.instant('HOME.EVENTO_CONFIRM_TEXT', {evento: this.reunion.nameEu})

          this.confirmationReuSwal.fire();
          for(var j = 0; j < this.reuniones.length; j++) {
            if(this.reunion.id == this.reuniones[j].id) {
              this.reuniones[j].asistencia = 1;
              this.cdr.detectChanges();
              break;
            }
          }
        },
        error => {
          console.error(error);
          this.loading1 = false;
        });
  }

  rechazarAsistencia(reunion: any) {
    this.reunion = reunion;
    if(this.translate.currentLang == "es" || this.translate.currentLang == "cat") this.rechazarReuSwal.title = this.reunion.name + "<br>" +  this.reunion.fechaEs + " " + this.reunion.hora;
    else if(this.translate.currentLang == "eu") this.rechazarReuSwal.title = this.reunion.nameEu + "<br>" +  this.reunion.fechaEu + " " + this.reunion.hora;
    this.rechazarReuSwal.fire();
  }

  rechazarAsistencia1() {
    this.loading2 = true;

    var ins = {user: this.currentUser.id, evento: this.reunion.id, fecha: new Date(), asiste: false};

    this.inscritoService.post(ins)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);

          this.logService.post({tipo: "EVENTO", fecha: new Date(), user: this.currentUser.id, details: "NO", referencia: this.reunion.id})
            .pipe(first()).subscribe();

          this.loading2 = false;
          if(this.translate.currentLang == "es" || this.translate.currentLang == "cat") this.confirmationRechReuSwal.text = this.translate.instant('HOME.EVENTO_RECH_CONFIRM_TEXT', {evento: this.reunion.name})
          else if(this.translate.currentLang == "eu") this.confirmationRechReuSwal.text = this.translate.instant('HOME.EVENTO_RECH_CONFIRM_TEXT', {evento: this.reunion.nameEu})
          this.confirmationRechReuSwal.fire();
          for(var j = 0; j < this.reuniones.length; j++) {
            if(this.reunion.id == this.reuniones[j].id) {
              this.reuniones[j].asistencia = -1;
              this.cdr.detectChanges();
              break;
            }
          }
        },
        error => {
          console.error(error);
          this.loading2 = false;
        });
  }

  abrirEvento(reunion: any, evento) {
    this.reunion = reunion;
    this.modalService.open(evento, { centered: true } );
  }

}
