import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
 
@Injectable()
export class CountService {
    constructor(private http: HttpClient) { }

    getCountAsistencias(evento: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/countasistencia/${evento}`);
    }

    //countdocumentos

    getCountDocumentos(evento: any, user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/countdocumentos/${evento}/${user}`);
    }
    
    getCountCuestionario1(edicion: any, tipo: any, grupo: any, centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/countparticipantes/${edicion}/'${tipo}'/'${grupo}'/${centro}`);
    }
    getCountCuestionario2(edicion: any, tipo: any, grupo: any, centro: any){
        return this.http.get<any[]>(`${environment.apiUrl}/countparticipantes2/${edicion}/'${tipo}'/'${grupo}'/${centro}`);
    }

    /*getAllPublished(tipo: string) {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1&type=' + tipo);
    }

    getAllPublished1() {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1');
    }
 
    getById(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges/' + id);
    }

    getByCreator(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id);
    }

    getByCreatorOtherPublic(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=public&published=1');
    }

    getByCreatorOtherOfficial(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=official&published=1');
    }
 
    post(challenge: Challenge): Observable<any> {
        return this.http.post(this.api + '/api/challenges', challenge);
    }
 
    update(challenge: Challenge) {
        return this.http.put(this.api + '/api/challenges/' + challenge.id, challenge);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/challenges/' + id);
    }*/
}