import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword(control: AbstractControl) {
		const password = control.get('password').value;

		const confirmPassword = control.get('confirmPassword').value;

		if (password !== confirmPassword && confirmPassword != "") {
			control.get('confirmPassword').setErrors({ConfirmPassword: true});
		} else {
			//return null;
		}

		const email = control.get('username').value;

		const confirmEmail = control.get('confirmUsername').value;

		if (email !== confirmEmail && confirmEmail != "") {
			control.get('confirmUsername').setErrors({ConfirmEmail: true});
		} else {
			//return null;
		}
	}
}
