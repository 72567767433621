// Angular
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, EdicionService, NodoService, DocumentoService, LogService, AuthenticationService } from '../../../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';


@Component({
  selector: 'kt-nodo-selector',
  templateUrl: './nodo-selector.component.html',
  styleUrls: ['./nodo-selector.component.scss']
})

export class NodoSelectorComponent implements OnInit {
  currentUser: any;
  nodos: any;
  private unsubscribe: Subject<any>;

  constructor(private nodoService: NodoService,
  private edicionService: EdicionService,
  private userService: UserService,
  private documentoService: DocumentoService,
  private authenticationService: AuthenticationService,
	private fb: FormBuilder,
	private translate: TranslateService,
  private cdr: ChangeDetectorRef,
	private modalService: NgbModal,
	private logService: LogService,
  private router: Router,
  private route: ActivatedRoute) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.currentUser = null;
    this.route.paramMap.subscribe(params => {
	  this.loadPerfil(params.get("id"));
    this.getNodo();
    });
  }

  loadPerfil(id: any) {
    this.userService.getUser(id)
    .subscribe(data => {
      console.log(data);
      this.currentUser = data;
      this.cdr.detectChanges();
    });
  }

  getNodo(){
    this.nodoService.getAll()
	    .subscribe(nodos => {
      this.nodos = nodos;
			this.cdr.detectChanges();
		});
  }
  
  setNodo(nodo) {
    
    var formNodo = {id: this.currentUser.id, nodo: nodo.id}
    this.userService.update(formNodo)
    .pipe(first())
    .subscribe(
      data => {
       this.currentUser.nodo = formNodo.nodo;
       //this.authenticationService.changeUser(this.currentUser);
       this.cdr.detectChanges();
      },
      error => {
        this.cdr.detectChanges();
        console.error(error);
      });
    }

}
