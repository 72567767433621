// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// Partials
import { PartialsModule } from '../partials/partials.module';
// NGX DATATABLE
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MatProgressSpinnerModule,
} from '@angular/material';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';

import { NgbAlertConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { TranslateModule } from '@ngx-translate/core';
import { StartmentoraComponent } from './startmentora/startmentora.component';
import { StartcentroComponent } from './startcentro/startcentro.component';
import { HomeComponent } from './home/home.component';
import { MaterialsComponent } from './materials/materials.component';
import { FormacionComponent } from './formacion/formacion.component';
import { PerfilComponent } from './perfil/perfil.component';
import { MentorasComponent } from './mentoras/mentoras.component';
import { CentrosComponent } from './centros/centros.component';
import { EventosComponent } from './eventos/eventos.component';
import { FormacionesComponent } from './formaciones/formaciones.component';
import { MentoraComponent } from './mentora/mentora.component';
import { MaterialesComponent } from './materiales/materiales.component';
import { LogsComponent } from './logs/logs.component';
import { GruposComponent } from './grupos/grupos.component';
import { ControlpanelComponent } from './controlpanel/controlpanel.component';
import { GestionnodoComponent } from './gestionnodo/gestionnodo.component';
import { AsignacionesComponent } from './asignaciones/asignaciones.component';



@NgModule({
	declarations: [MyPageComponent, StartmentoraComponent, StartcentroComponent, HomeComponent, MaterialsComponent, FormacionComponent, PerfilComponent, MentorasComponent, CentrosComponent, EventosComponent, FormacionesComponent, MentoraComponent, MaterialesComponent, LogsComponent, GruposComponent, ControlpanelComponent, GestionnodoComponent, AsignacionesComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		TranslateModule.forChild(),
		SweetAlert2Module,
		RouterModule,
		NgbModule,
		NgxDropzoneModule,
		NgxDatatableModule,
		OwlDateTimeModule, 
		OwlNativeDateTimeModule,
		MatButtonModule
	],
	providers: []
})
export class PagesModule {
}
