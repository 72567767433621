import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NodoService, UserService, AuthenticationService, EdicionService, InscritoService,EventoService, LogService } from '../../../_services';

import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as XLSX from 'xlsx'; 
import * as moment from 'moment';

import{ GlobalVariables } from '../../../_common/global-variables';
import { createOfflineCompileUrlResolver } from '@angular/compiler';

@Component({
  selector: 'kt-mentoras',
  templateUrl: './mentoras.component.html',
  styleUrls: ['./mentoras.component.scss']
})
export class MentorasComponent implements OnInit {

  @ViewChild('bajaSwal', {static: false}) private bajaSwal: SwalComponent
  @ViewChild('bajaConfirmSwal', {static: false}) private bajaConfirmSwal: SwalComponent

  currentUser: any;
  selectedUser: any;
  nodos = {};
  rows = [];
  nodo = [];
  originalRows = [];
  originalRowsWeb = [];
  loading = [];
  edicionActiva: any;
  ediciones = [];
  edicionesHash = {};
  selectedIndex:any;

  constructor(private userService: UserService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private inscritoService: InscritoService,
    private logService: LogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private eventoService: EventoService,
    private authenticationService: AuthenticationService,) { }



  ngOnInit() {
    if(GlobalVariables.filters[this.router.url]) {
      this.filters = GlobalVariables.filters[this.router.url].filters;
      this.filtrosOpen = GlobalVariables.filters[this.router.url].open;
    } else {
      GlobalVariables.filters[this.router.url] = {
        filters: this.filters,
        open: this.filtrosOpen
      }
    }
    this.currentUser = this.authenticationService.currentUserValue;
    
    this.loadEventos();
  }

  events = [];
  loadEventos(){
    this.eventoService.getAll()
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fechaObj = new Date(events[i].fecha);
          events[i].fecha = moment(new Date(events[i].fecha)).format("DD/MM/YYYY");
          //this.loadAsistencia(events[i]);
        }
        this.events = events;
        
        this.cdr.detectChanges();

        if(this.currentUser.nodo) {
          this.loadMentorasNodo();
          this.getEdicionActiva();
        } else { //super-user doesn't have nodo
          this.loadMentoras();
          this.loadNodos();
          this.getEdicionActiva();
        }
      });
  }

  loadMentoras() {
    this.userService.getByRol('mentora')
	    .subscribe(users => {
      this.originalRows = users;
      this.originalRowsWeb = users;
      this.loadAsistencia();
			this.cdr.detectChanges();
		});
  }

  loadMentorasNodo() {
    this.userService.getByRolNodo('mentora', this.currentUser.nodo)
	    .subscribe(users => {
        //console.log(users);
        this.originalRows = users;

        for(let user of this.originalRows) {
          //this.loadInscripcion(user);
          if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
        }
        this.originalRowsWeb = users;
        this.loadAsistencia();
        this.cdr.detectChanges();
		});
  }
  
  userAsiste = [];
  fechaAsiste = [];

  loadAsistencia(){
    
    for(let user of this.originalRows) {
      if(user.lastFormacion) {
        for(let eventoAsist of this.events){
          if(eventoAsist.id == user.lastFormacion){
            user.evento = eventoAsist;
            
            break;
          }
        }
      }



      //this.loadInscripcion(user); 
      //this.inscripcionesLoading = false;
      if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
    }
    
    this.filterDatatable();
    this.cdr.detectChanges();

    return;



    //var usuarios = [...this.originalRows];
    for(var i = 0; i < this.originalRows.length; i++){
      var usuario:any;
      usuario = {...this.originalRows[i].id};
    }
    console.warn(usuario);
    this.inscritoService.getByUserAsistencia(usuario)
      .subscribe (usuariosAsist =>{
        for(let usuarioAsist of usuariosAsist){
          this.userAsiste[usuarioAsist.user] = true;
          for(let eventoAsist of this.events){
            if(usuarioAsist.evento == eventoAsist.id){
              this.fechaAsiste[usuarioAsist.user] = eventoAsist.fecha;
            }
          }
      }
        
      this.filterDatatable();
      this.cdr.detectChanges();

      for(let user of this.originalRows) {
        //this.loadInscripcion(user); 
        //this.inscripcionesLoading = false;
        if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
      }
    });


  }

  inscripcionesCargadas = 0;
  inscripcionesLoading = false;

  /*loadInscripcion(mentora: any) {
    this.logService.getLastInscripcion(mentora.id)
    .subscribe(events => {
      if(events.length > 0) mentora.lastInscripcion = moment.utc(events[events.length - 1].fecha).local().format("DD/MM/YYYY HH:mm");
      
      this.inscripcionesCargadas++;
      if(this.inscripcionesCargadas >= this.originalRows.length) this.inscripcionesLoading = false;
      this.cdr.detectChanges();
    });
  }*/

  getEdicionActiva(){
    this.edicionService.getActive()
	    .subscribe(ediciones => {
        for(let edicion of ediciones) {
          this.edicionActiva = edicion.id;
          this.edicionesHash[edicion.id+""] = edicion;
        }
        this.ediciones = ediciones;
		});
  }

  changeEstado(row){
   this.selectedIndex = row.id;
   this.loading[this.selectedIndex] = true;
   this.selectedUser = row;
   if(this.selectedUser.estado != 'PRE-INSCRITA' && this.selectedUser.estado != 'FORMULARIO'){
    var formEstado = {id: this.selectedUser.id, estado: this.selectedUser.estado, edicion: this.edicionActiva}
   } else {
     var formEstado = {id: this.selectedUser.id, estado: this.selectedUser.estado, edicion: null}
   }
		this.userService.update(formEstado)
		.pipe(first())
		.subscribe(
			data => {
        this.loading[this.selectedIndex] = false;
        this.filterDatatable();
        this.cdr.detectChanges();
      },
      
			error => {
        this.cdr.detectChanges();
				console.error(error);
			});
    }
  
  // edicionesStr = [];
  
  exportexcel(): void 
  {
    var datos = [...this.originalRows];
    for(var i = 0; i < datos.length; i++){

      var dato:any;
      dato = {...datos[i]};//copy in variable dato each object of datos.

      
    //   if (dato.ediciones){
    //     var edicionesStr = "";
    //   for(let edicion2 of JSON.parse(dato.ediciones)) {
    //     edicionesStr += edicion2 + ", ";
    //   }
    //   dato.edicionesStr = edicionesStr.substring(0, edicionesStr.length-2); //quitar últimos dos carácteres(comma y vacio)
    // }
      delete dato.avatar;
      delete dato.lang;
      delete dato.role;
      delete dato.formacion;
      delete dato.poblacion;
      delete dato.personaContacto;
      delete dato.nombreContacto;
      delete dato.emailContacto;
      delete dato.grupos;
      delete dato.gruposArray;
      delete dato.curso;
      if(this.currentUser.nodo){
        delete dato.nodo;
      }
      //delete dato.formacionNo;
      delete dato.isActive;
      delete dato.provincia;
      delete dato.webBio;
      delete dato.webFoto;
      delete dato.webSocial;
      delete dato.webName;
      delete dato.expCentro;
      delete dato.compLogo;
      delete dato.compWeb;
      delete dato.compromiso;
      delete dato.investigacion;
      delete dato.codFormacion;
      dato.ultimaInscripcion = dato.lastInscripcionStr;
      delete dato.lastInscripcion;
      delete dato.lastInscripcionStr;
      if(dato.certificado) dato.certificado = true;
      if(dato.subidaCertificado) dato.subidaCertificado = moment.utc(dato.subidaCertificado.date).local().format("DD/MM/YYYY HH:mm");
      dato.registrationDate = moment.utc(dato.registrationDate.date).local().format("DD/MM/YYYY HH:mm");
      if(dato.evento) dato.fechaAsiste = dato.evento.fecha;

      datos[i] = dato;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Mentoras');

    /* save to file */
    XLSX.writeFile(wb, "Mentoras_" + moment().format("DDMMYYYY-HHmm") + ".xlsx");
  }

  exportexcelweb(comp:any): void
  {
    var datosweb = [...this.originalRowsWeb];
    for(var i = 0; i < datosweb.length; i++){
      var datoweb:any;
      datoweb = {...datosweb[i]};
      delete datoweb.avatar;
      delete datoweb.lang;
      delete datoweb.role;
      delete datoweb.poblacion;
      delete datoweb.personaContacto;
      delete datoweb.nombreContacto;
      delete datoweb.emailContacto;
      delete datoweb.grupos;
      delete datoweb.gruposArray;
      delete datoweb.curso;
      delete datoweb.db_timestamp;
      if(this.currentUser.nodo){
        delete datoweb.nodo;
      }
      delete datoweb.isActive;
      delete datoweb.provincia;
      delete datoweb.certificado;
      delete datoweb.subidaCertificado;
      delete datoweb.registrationDate;
      // delete datoweb.username;
      delete datoweb.telefono;
      delete datoweb.idiomaEu;
      delete datoweb.idiomaEn;
      delete datoweb.idiomaEs;
      delete datoweb.idiomaCat;
      delete datoweb.comentarios;
      // delete datoweb.estado;
      delete datoweb.estudios;
      delete datoweb.zona;
      delete datoweb.sesionesNinas;
      delete datoweb.sesionesNinos;
      delete datoweb.masGrupos;
      delete datoweb.expMentora;
      // delete datoweb.ediciones;
      delete datoweb.edicion;
      delete datoweb.leidoKit;
      delete datoweb.genero;
      if (this.currentUser.nodo != 1 || comp == 1) //export datos compromiso
        {
          delete datoweb.provBiz;
          delete datoweb.provAra;
          delete datoweb.provGip;
        }
    if (comp == 1)
      {           //export datos compromiso
        delete datoweb.webBio;
        delete datoweb.webFoto;
        delete datoweb.webSocial;
        delete datoweb.webName;
        delete datoweb.ediciones;
        delete datoweb.estado;
      }
      delete datoweb.provTen;
      delete datoweb.provGca;
      delete datoweb.provMad;
      delete datoweb.provBcn;
      delete datoweb.formacionNo;
      delete datoweb.formacion;
      delete datoweb.localidades;
      if(comp == 0)
        {        //export datos web
          delete datoweb.entidad;
          delete datoweb.entidadopt;
          delete datoweb.compLogo;
          delete datoweb.compWeb;
          delete datoweb.compromiso;
        }
      delete datoweb.expCentro;
      delete datoweb.investigacion;
      delete datoweb.codFormacion;
      delete datoweb.preFin;
      delete datoweb.repCent;
      delete datoweb.dirCentro;
      delete datoweb.webCentro;
      //if(datoweb.certificado) datoweb.certificado = true;
      //if(datoweb.subidaCertificado) datoweb.subidaCertificado = moment.utc(datoweb.subidaCertificado.date).local().format("DD/MM/YYYY HH:mm");
      //datoweb.registrationDate = moment.utc(datoweb.registrationDate.date).local().format("DD/MM/YYYY HH:mm");
      datosweb[i] = datoweb;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datosweb);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'MentorasInfo');

    /* save to file */
    XLSX.writeFile(wb, "Mentoras_Info_" + moment().format("DDMMYYYY-HHmm") + ".xlsx");
  }
  
  loadNodos() {
		console.log("loadNodos()");
		this.nodoService.getAll()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""] = nodos[i];
      }
      function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }
      this.nodo = nodos.sort(compare);
			this.cdr.detectChanges();
		});
	}

  mentora: any;

  baja(mentora: any){
    this.mentora = mentora;
		this.bajaSwal.fire()
  }

  baja1(){
		var form = {id: this.mentora.id, isActive: false, username: this.mentora.username + "-" + moment().unix()}
		this.userService.update(form)
		.pipe(first())
		.subscribe(
			data => {
				//console.log("OK", data);

        this.bajaConfirmSwal.fire();
        for(var i = 0; i < this.originalRows.length; i++) {
          if(this.originalRows[i].id == this.mentora.id){
            this.originalRows.splice(i, 1);
            var datos = [...this.originalRows];
            this.originalRows = [];
            this.originalRows = datos;
            this.cdr.detectChanges();
            break;
          }
        }
        this.filterDatatable();
				//this.authenticationService.logout();
			},
			error => {
				console.error(error);
			});
    }
    
  // ------- FILTROS -------------

  filtrosOpen = false;

  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    GlobalVariables.filters[this.router.url].open = this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null,
    email: null,
    estado: "TODOS",
    certificado: "TODOS",
    nodoFil: "TODOS",
    edicion: "TODOS"
  }
  

  filterDatatable(){
      GlobalVariables.filters[this.router.url].filters = this.filters;
      GlobalVariables.listadoPagina = "mentora";
      GlobalVariables.listado = this.originalRows;
    
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      var nombre = item.name + " " + item.apellidos;
      if(!this.currentUser.nodo && this.nodos[item.nodo]){
        var nodo = this.nodos[item.nodo].name;
        if(this.filters.nodoFil != "TODOS" && nodo != this.filters.nodoFil) mostrar = false;
      }
      
      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!nombre.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.email && this.filters.email != null && this.filters.email != "") {
        if(!item.username.toLowerCase().includes(this.filters.email.toLowerCase())) mostrar = false;
      }

      if(this.filters.edicion != "TODOS" && item.edicion != this.filters.edicion) mostrar = false;

      if(this.filters.estado != "TODOS" && item.estado != this.filters.estado) mostrar = false;

      if(this.filters.certificado != "TODOS") {
        if(this.filters.certificado == "SI" && !item.certificado) mostrar = false
        else if(this.filters.certificado == "NO" && item.certificado) mostrar = false;
      }

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
      GlobalVariables.listado = this.rows;
  }

  resetFilters() {
    this.filters = {
      nombre: null,
      email: null,
      estado: "TODOS",
      certificado: "TODOS",
      nodoFil: "TODOS",
      edicion: "TODOS"
    }
    this.filterDatatable();
  }

}
