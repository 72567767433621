import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, EventoService, InscritoService, CountService, LogService, EdicionService, NodoService, DocumentoService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'kt-formacion',
  templateUrl: './formacion.component.html',
  styleUrls: ['./formacion.component.scss']
})
export class FormacionComponent implements OnInit {

  @ViewChild('formacionSwal', {static: false}) private formacionSwal: SwalComponent
  @ViewChild('confirmationFormSwal', {static: false}) private confirmationFormSwal: SwalComponent
  @ViewChild('inscripcionFormSwal', {static: false}) private inscripcionFormSwal: SwalComponent
  @ViewChild('inscripcionConfirmSwal', {static: false}) private inscripcionConfirmSwal: SwalComponent
  @ViewChild('inscripcionErrorSwal', {static: false}) private inscripcionErrorSwal: SwalComponent
  @ViewChild('cancelFormacionSwal', {static: false}) private cancelFormacionSwal: SwalComponent
  @ViewChild('cancelFormacionConfirmSwal', {static: false}) private cancelFormacionConfirmSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('confirmationFileSwal', {static: false}) private confirmationFileSwal: SwalComponent
  @ViewChild('questionDeleteFileSwal', {static: false}) private questionDeleteFileSwal: SwalComponent
  @ViewChild('confirmationDeleteFileSwal', {static: false}) private confirmationDeleteFileSwal: SwalComponent

  currentUser: any;
  loading = false;
  showDocs = false;
  formaciones = [];
  formacionesFilter = [];
  formacionesFilterMod2 = [];
  formacionesFilterOtro = [];
  
  asistencia = null;
  asistenciaMod2 = null;
  asistenciaMod12 = null;
  asistenciaOtro = null;
  inscrito = [];

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private modalService: NgbModal,
    private eventoService: EventoService,
    private logService: LogService,
    private inscritoService: InscritoService,
    private countService: CountService,
    private documentoService: DocumentoService,
    private router: Router) { }
  
  
 
  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    if(this.currentUser.entidad && this.currentUser.entidad != "") this.loadFormacionesEntidad()
    else this.loadFormaciones();
  }

  formacionNo(){
    if(this.loading) return;
    if(this.currentUser.role == "mentora") this.formacionSwal.fire()
    else this.formacionSwal.fire();
  }

  formacionNo1(){
    if(this.loading) return;
    this.loading = true;
    var user = {id: this.currentUser.id, formacionNo: true};
    this.userService.update(user)
      .pipe(first())
      .subscribe(
        data => {
					console.log("OK", data);
					this.currentUser.formacionNo = user.formacionNo;
					
          this.authenticationService.changeUser(this.currentUser);
          this.loading = false;
          this.cdr.detectChanges();
          this.confirmationFormSwal.fire();
        },
        error => {
          console.error(error);
        });
  }
 
  loadFormaciones() {
    this.eventoService.getAllByNodo(this.currentUser.nodo)
    .subscribe(eventsNodo=> {
      console.log("----->", eventsNodo);
        this.eventoService.getAllFormacionesSinId(this.currentUser.role)
        .subscribe(events => {
          for(let event of events){
            for(let eventNodo of eventsNodo) {    
              if(eventNodo.evento == event.id) {
                this.formaciones = [...this.formaciones,event];
              }
              
            
            }
          }
          for(var i = 0; i < this.formaciones.length; i++) {
            this.formaciones[i].fechaEs = moment(this.formaciones[i].fecha).add(12, 'hours').format("DD/MM/YYYY");
            this.formaciones[i].fechaEu = moment(this.formaciones[i].fecha).add(12, 'hours').format("YYYY/MM/DD");
            this.loadCountInscritos(this.formaciones[i]);
          }
          this.loadInscrito();
          this.filterDatatable();
          this.cdr.detectChanges();
          this.loadDocumentosAsistencia();
        });
    });
    // this.eventoService.getAllFormacionesEdicion(this.currentUser.role, this.currentUser.nodo, this.currentUser.edicion)
    // .subscribe(events => {
    //   console.log(events);
    //   this.formaciones = events;
    //   for(var i = 0; i < this.formaciones.length; i++) {
    //     this.formaciones[i].fechaEs = moment(this.formaciones[i].fecha).format("DD/MM/YYYY");
    //     this.formaciones[i].fechaEu = moment(this.formaciones[i].fecha).format("YYYY/MM/DD");
    //     this.loadCountInscritos(this.formaciones[i]);
    //   }
    //   this.loadInscrito();
    //   this.filterDatatable();
    //   this.cdr.detectChanges();
    // });
  }

  loadFormacionesEntidad() {
    this.eventoService.getEntidadFormacionesEdicion(this.currentUser.role, this.currentUser.entidad, this.currentUser.edicion)
    .subscribe(events => {
      console.log(events);
      if(events.length == 0) {
        console.log("NO HAY EVENTOS");
        this.loadFormaciones();
      } else {
        this.formaciones = events;
        for(var i = 0; i < this.formaciones.length; i++) {
          this.formaciones[i].fechaEs = moment(this.formaciones[i].fecha).add(12, 'hours').format("DD/MM/YYYY");
          this.formaciones[i].fechaEu = moment(this.formaciones[i].fecha).add(12, 'hours').format("YYYY/MM/DD");
          this.loadCountInscritos(this.formaciones[i]);
        }
        this.loadInscrito();
        this.cdr.detectChanges();
      }      
    });
  }

  
  loadDocumentosAsistencia(){
  var asisteMod1 = false;
  var asisteMod2 = false;
  var asisteMod12 = false;
    this.inscritoService.getByUserAsistencia(this.currentUser.id)
      .pipe(first())
        .subscribe(
          data => {
           for(let dato of data){
            if(dato.codigoForm == 'MOD1'){
              asisteMod1 = true;
            }
            if(dato.codigoForm == 'MOD2'){
              asisteMod2 = true;
            }
            if(dato.codigoForm == 'MOD12'){
              asisteMod12 = true;
            }
           }
           if((asisteMod1 && asisteMod2) || asisteMod12) {
             this.showDocs = true;
             this.loadDocumentos();
           }
          this.cdr.detectChanges();
        });
  }

  loadCountInscritos(evento: any) {
    this.countService.getCountAsistencias(evento.id)
    .subscribe(count => {
      evento.asistentes = count;
      this.cdr.detectChanges();
    });
  }

  loadInscrito() {
    this.inscritoService.getByUser(this.currentUser.id)
    .subscribe(ins => {
      console.log(ins);
      for(var i = 0; i < ins.length; i++) {
        for(var j = 0; j < this.formaciones.length; j++) {
          if(ins[i].evento == this.formaciones[j].id) {
            if(ins[i].asiste) {
              this.formaciones[j].asistencia = 1;
              if(this.formaciones[j].codFormacion == 'MOD1'){
              this.asistencia = this.formaciones[j];
              }else if(this.formaciones[j].codFormacion == 'MOD2'){
                this.asistenciaMod2 = this.formaciones[j];
              }else if(this.formaciones[j].codFormacion == 'MOD12'){
                this.asistenciaMod12 = this.formaciones[j];
              }else this.asistenciaOtro = this.formaciones[j];
              
              //this.loadDocumentos();
              this.inscrito = [...this.inscrito,ins[i]];
            } else this.formaciones[j].asistencia = -1;
            break;
          }
        }
      }
      this.cdr.detectChanges();
    });
  }

  formacion = null;

  confirmarAsistencia(formacion: any) {
    if(this.loading) return;
    this.formacion = formacion;
    if(this.translate.currentLang == "es" || this.translate.currentLang == "cat") this.inscripcionFormSwal.title = this.formacion.fechaEs + " " + this.formacion.hora + " (" + this.formacion.lugar + ")";
    else if(this.translate.currentLang == "eu") this.inscripcionFormSwal.title = this.formacion.fechaEu + " " + this.formacion.hora + " (" + this.formacion.lugar + ")";
    
    this.inscripcionFormSwal.fire();
  }
  showCancel = false;
  confirmarAsistencia1() {
    this.loading = true;
    this.showCancel = true;

    this.countService.getCountAsistencias(this.formacion.id)
    .subscribe(count => {
      this.formacion.asistentes = count;
      if(count >= this.formacion.plazas) {
        this.loading = false;
        this.showCancel = false;
        this.inscripcionErrorSwal.fire();
      } else {
        var ins = {user: this.currentUser.id, evento: this.formacion.id, fecha: new Date(), asiste: true, codigoForm: this.formacion.codFormacion};

        this.inscritoService.post(ins)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);

              var user = {id: this.currentUser.id, formacion: this.formacion.lugar + " - " + this.formacion.fechaEs + " " + this.formacion.hora, codFormacion: this.formacion.codFormacion};
              this.userService.update(user)
                .pipe(first())
                .subscribe(
                  data1 => {
                    console.log("OK1", data1);
                    this.currentUser.formacion = user.formacion;
                    this.currentUser.codFormacion = user.codFormacion;
                    
                    this.authenticationService.changeUser(this.currentUser);

                    this.inscrito = data;

                    var mail = {email: this.currentUser.username, user: this.currentUser.name, fecha: this.formacion.fechaEs + " " + this.formacion.hora, lugar: this.formacion.lugar};

                    this.eventoService.mailformacion(mail)
                      .pipe(first()).subscribe();

                    this.logService.post({tipo: "FORMACION", fecha: new Date(), user: this.currentUser.id, details: "SI", referencia: this.formacion.id})
                      .pipe(first()).subscribe();

                    this.loading = false;
                    this.showCancel = false;
                    if(this.translate.currentLang == "es" || this.translate.currentLang == "cat") this.inscripcionConfirmSwal.text = this.translate.instant('HOME.EVENTO_CONFIRM_TEXT', {evento: "Formación Inspira STEAM"})
                    else if(this.translate.currentLang == "eu") this.inscripcionConfirmSwal.text = this.translate.instant('HOME.EVENTO_CONFIRM_TEXT', {evento: "Inspira STEAM Prestakuntza"})
                    
                    this.inscripcionConfirmSwal.fire();
                    for(var j = 0; j < this.formaciones.length; j++) {
                      if(this.formacion.id == this.formaciones[j].id) {
                        this.formaciones[j].asistencia = 1;
                        for(var k = 0; k < this.inscrito.length; k++) {
                          if(this.inscrito[k].codigoForm == 'MOD1'){
                            this.asistencia = this.formaciones[j];
                          }else if(this.inscrito[k].codigoForm == 'MOD2'){
                            this.asistenciaMod2 = this.formaciones[j];
                          }else if(this.inscrito[k].codigoForm == 'MOD12'){
                            this.asistenciaMod12 = this.formaciones[j];
                          } else this.asistenciaOtro = this.formaciones[j];
                        }

                        this.loadCountInscritos(this.formaciones[j]);
                        this.cdr.detectChanges();
                        break;
                      }
                    }


                  },
                  error => {
                    console.error(error);
                  });

              
            },
            error => {
              console.error(error);
              this.loading = false;
              this.showCancel = false;
            }); 



      }
      this.cdr.detectChanges();
    });



    
  }
  formacionCancel: any;
  cancelarAsistencia(formacion: any){
    this.formacionCancel = formacion;
    console.warn(this.formacionCancel);
    if(this.loading) return;
    this.cdr.detectChanges();
    this.cancelFormacionSwal.fire();
  }

  cancelarAsistencia1() {
    this.loading = true;
    for(var i=0; i<this.inscrito.length;i++){
      if(this.formacionCancel.id == this.inscrito[i].evento){
      this.inscritoService.delete(this.inscrito[i].id)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
        
          //this.inscrito[i] = null;
          this.loading = false;

          var user = {id: this.currentUser.id, formacion: null, codFormacion: null};
          this.userService.update(user)
            .pipe(first())
            .subscribe(
              data1 => {
                console.log("OK1", data1);
                this.currentUser.formacion = user.formacion;
                this.currentUser.codFormacion = user.codFormacion;
                this.authenticationService.changeUser(this.currentUser);

                this.cdr.detectChanges();
                this.cancelFormacionConfirmSwal.fire();
            });
        });
        if(this.inscrito[i].codigoForm == 'MOD1'){
          this.asistencia = null;
        
          
        }else if(this.inscrito[i].codigoForm == 'MOD2'){
          this.asistenciaMod2 = null;
          
        }else if(this.inscrito[i].codigoForm == 'MOD12'){
          this.asistenciaMod12 = null;
          
        }else {this.asistenciaOtro = null;}
      }
    }
    this.cdr.detectChanges();
  }

  filterDatatable(){
    console.warn(this.formaciones);
    this.formacionesFilter = this.formaciones.filter(function(item){
      var mostrar = true;
      if(moment(item.fecha).add(12, 'hours') < moment()) mostrar = false;
      if(item.codFormacion != 'MOD1' && item.codFormacion != 'MOD2' && item.codFormacion != 'MOD12') mostrar = false;

      return mostrar;
    }.bind(this));

    this.formacionesFilterMod2 = this.formaciones.filter(function(item){
      var mostrarMod2 = true;
      if(moment(item.fecha).add(12, 'hours') < moment()) mostrarMod2 = false;
      if(item.codFormacion != 'MOD2') mostrarMod2 = false;

      return mostrarMod2;
    }.bind(this));

    this.formacionesFilterOtro = this.formaciones.filter(function(item){
      var mostrarOtro = true;
      if(moment(item.fecha).add(12, 'hours') < moment()) mostrarOtro = false;
      if(item.codFormacion == 'MOD2' || item.codFormacion == 'MOD1' || item.codFormacion == 'MOD12') mostrarOtro = false;

      return mostrarOtro;
    }.bind(this));
  }

  // ------------------- DOCUMENTOS -------------------------

  filesDocs: File[] = [];
  loadingUpload = false;

  onSelectDocs(event) {
    console.log(event);
    //this.files = [];
    this.filesDocs.push(...event.addedFiles);

    setTimeout(()=>{ 
      this.cdr.detectChanges();
    }, 200)
  }
  
  onRemoveDocs(event) {
    console.log(event);
    this.filesDocs.splice(this.filesDocs.indexOf(event), 1);
  }

  subirDocumentos(){
    if(this.loadingUpload) return;
    if(this.filesDocs.length == 0) {
      this.errorNotFileSwal.fire();
      return;
    }
    this.loadingUpload = true;

    for(let file of this.filesDocs) {
      guardarDocumento.bind(this)(file);
    }

    var completados = 0;

    function guardarDocumento(doc: any) {
      var doc = doc;
      var myReader:FileReader = new FileReader();
      myReader.onloadend = function (e:any) {
        console.log(myReader);
      if(this.asistencia){
        var newDoc = {contenido: myReader.result, name: doc.name, fechaSubida: new Date(), user: this.currentUser.id, formacion: this.asistencia.id};
      }else if(this.asistenciaMod2){
        var newDoc = {contenido: myReader.result, name: doc.name, fechaSubida: new Date(), user: this.currentUser.id, formacion: this.asistenciaMod2.id};
      }else if(this.asistenciaMod12){
        var newDoc = {contenido: myReader.result, name: doc.name, fechaSubida: new Date(), user: this.currentUser.id, formacion: this.asistenciaMod12.id};
      }
        this.documentoService.postFormacion(newDoc)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
              moment.locale('es');
              if(doc.fechaSubida) {
                var fecha = doc.fechaSubida.substring(0,doc.fechaSubida.indexOf('+')) + "Z";
                data.fechaStr = moment(fecha).format("DD/MM/YYYY HH:mm");
              }
              this.rowsDoc = [...this.rowsDoc, data];
              completados++;
              if(completados == this.filesDocs.length) {
                this.loadingUpload = false;
                this.confirmationFileSwal.fire();
                this.filesDocs = [];
                this.cdr.detectChanges();
              }
            },
            error => {
              console.error(error);
              this.loadingUpload = false;
            });
      }.bind(this)
      myReader.readAsDataURL(doc);
    }


  }

  rowsDoc = [];
  loadDocumentos(){
    this.showDocs = true;
    this.documentoService.getByUserFormacion(this.currentUser.id)
    .subscribe(data => {
      console.log(data);
      for(let doc of data) {
        var fecha = doc.fechaSubida.substring(0,doc.fechaSubida.indexOf('+')) + "Z";
        doc.fechaStr = moment(fecha).format("DD/MM/YYYY HH:mm");
      }
      this.rowsDoc = data;
      this.cdr.detectChanges();
    });
  }

  verDocumento(doc: any){
    //window.open(doc.contenido, "_blank");
    var element = document.createElement('a');
    element.setAttribute('href', doc.contenido);
    element.setAttribute('download', doc.name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  loadingBorrar = false;
  docBorrar = null;

  borrarDocumento(doc: any) {
    this.docBorrar = doc;
    this.questionDeleteFileSwal.fire();
  }

  borrarDocumento1() {
    this.loadingBorrar = true;
    this.cdr.detectChanges();
    this.documentoService.deleteFormacion(this.docBorrar.id)
    .subscribe(data => {
      console.log("OK", data);
      for(var i = 0; i < this.rowsDoc.length; i++) {
        if(this.rowsDoc[i].id == this.docBorrar.id){
          this.rowsDoc.splice(i, 1);
          var datos = [...this.rowsDoc];
          this.rowsDoc = [];
          this.rowsDoc = datos;
          this.cdr.detectChanges();
          break;
        }
      }
      this.confirmationDeleteFileSwal.fire();
      this.loadingBorrar = false;
      this.cdr.detectChanges();
    });
  }

}
