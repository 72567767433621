import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
 
@Injectable()
export class EventoService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos`);
    }

    getAllHome(role: any, nodo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&tipo=reunion&nodo=${nodo}&role=${role}`);
    }

    getAllFormaciones(role: any, nodo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&tipo=formacion&nodo=${nodo}&role=${role}`);
    }
    
    getAllFormacionesEdicion(role: any, nodo: any, edicion: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&tipo=formacion&nodo=${nodo}&role=${role}&edicion=${edicion}`);
    }
    getAllFormacionesEdicionId(role: any, id: any, edicion: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&id=${id}&role=${role}&edicion=${edicion}`);
    }

    getAllFormacionesEdicionSinId(role: any, edicion: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&role=${role}&edicion=${edicion}`);
    }

    getAllFormacionesSinId(role: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&role=${role}`);
    }

    getEntidadFormacionesEdicion(role: any, entidad: any, edicion: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?visible=1&tipo=formacion&entidad=${entidad}&role=${role}&edicion=${edicion}`);
    }

    getAllAdmin(nodo: any, edicion: any, tipo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?tipo=${tipo}&nodo=${nodo}&edicion=${edicion}`);
    }

    getAllNodo(nodo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/eventos?nodo=${nodo}`);
    }

    getAllByNodo(nodo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/evento_nodos?nodo=${nodo}`);
    }

    getAllByEvent(evento: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/api/evento_nodos?evento=${evento}`);
    }

    getById(id: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/eventos/${id}`);
    }

    mailevento(event: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/mailevento`, event);
    }

    mailformacion(event: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/mailformacion`, event);
    }
    
    // inscripcionedicion(event: any): Observable<any> {
    //     return this.http.post(`${environment.apiUrl}/inscripcionedicion`, event);
    // }

    post(evento: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/eventos`, evento);
    }

    getNodos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/evento_nodos`);
    }

    postNodos(eventoNodo: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/evento_nodos`, eventoNodo);
    }

    update(evento: any) {
        return this.http.put(`${environment.apiUrl}/api/eventos/${evento.id}`, evento);
    }

    // updateNodos(id: any, eventoNodo: any): Observable<any> {
    //     return this.http.put(`${environment.apiUrl}/api/evento_nodos/${id}`, eventoNodo);
    // }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/eventos/${id}`);
    }

    /*getAllPublished(tipo: string) {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1&type=' + tipo);
    }

    getAllPublished1() {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1');
    }
 
    getById(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges/' + id);
    }

    getByCreator(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id);
    }

    getByCreatorOtherPublic(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=public&published=1');
    }

    getByCreatorOtherOfficial(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=official&published=1');
    }
 
    post(challenge: Challenge): Observable<any> {
        return this.http.post(this.api + '/api/challenges', challenge);
    }
 
    update(challenge: Challenge) {
        return this.http.put(this.api + '/api/challenges/' + challenge.id, challenge);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/challenges/' + id);
    }*/
}