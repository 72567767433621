import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NodoService, UserService, AuthenticationService, EventoService, LogService, CountService, DocumentoService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
// RxJS
import { first } from 'rxjs/operators';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'kt-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  originalRows = [];
  rows = [];
  users = {};
  eventos = {};
  currentUser: any;

  constructor(private cdr: ChangeDetectorRef,
    private userService: UserService,
    private logService: LogService,
    private eventService: EventoService,
    private authenticationService: AuthenticationService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
    }

  loadLogs() {
    this.logService.getByNodo(this.currentUser.nodo)
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fechaObj = new Date(events[i].fecha);
          var fecha = events[i].fecha.substring(0,events[i].fecha.indexOf('+')) + "Z";
          events[i].fecha = moment(fecha).format("DD/MM/YYYY HH:mm");
          if(this.users[events[i].user] && this.users[events[i].user].isActive) this.originalRows.push(events[i]);
        }
        //this.rows = events;
        this.filterDatatable();
        this.cdr.detectChanges();
      });
  }

  loadUsers() {
    this.userService.getAllActive()
	    .subscribe(users => {
        for(var i = 0; i < users.length; i++) {
          if(users[i].nodo == this.currentUser.nodo) this.users[users[i].id+""] = users[i];
        }
        this.loadEventos();
		});
  }

  loadEventos() {
    this.eventService.getAll()
	    .subscribe(events => {
        for(var i = 0; i < events.length; i++) {
          events[i].fecha = moment(new Date(events[i].fecha)).format("DD/MM/YYYY");
          this.eventos[events[i].id+""] = events[i];
        }
        this.loadLogs();
		});
  }

  ngOnInit() {
    //this.loadEventos();
    this.currentUser = this.authenticationService.currentUserValue;
    this.loadUsers();
  }

  // ------- FILTROS -------------

  filters = {
    nombre: null,
    email: null,
    evento: "TODOS"
  }

  filterDatatable(){
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;

      var nombre = this.users[item.user].name + " " + this.users[item.user].apellidos;
      
      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!nombre.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.email && this.filters.email != null && this.filters.email != "") {
        if(!this.users[item.user].username.toLowerCase().includes(this.filters.email.toLowerCase())) mostrar = false;
      }

      if(this.filters.evento != "TODOS" && item.tipo != this.filters.evento) mostrar = false;

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }
}
