import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, EdicionService, NodoService, DocumentoService, LogService, GestionnodoService } from '../../../_services';

import { ConfirmPasswordValidator } from './confirm-password.validator';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';

@Component({
  selector: 'kt-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  currentUser: any;
  loading = false;
  nodo: any;
  edicion: any;
  
  plantCompEntEu = "/assets/docs/CompromisoEntidades_Inspira_ES_EUS.docx";
  plantCompEntEs = "/assets/docs/CompromisoEntidades_Inspira_ES.docx";

  startForm: FormGroup;
  errors: any = [];
  submitted = false;
  ediciones = [];
  items: FormArray;
  edicionesItem: FormArray;
  error = {idioma: false, participa: false, sesiones: false, provincia: false, desplaza: false};
  //localidades = ["Avilés", "Campomanes", "Ceceda", "Gijón", "Grado", "Jarrio", "La Barganiza", "La Pereda", "Langreo", "Lugones", "Navia", "Oviedo", "Proaza", "San Cucao", "Trubia", "Urbanización La Fresneda", "Viella"];
  localidadesItems: FormArray;
  sublocalidadesItems: FormArray;
//   localidadesEus = ["Aramaio", "Murgia", "Santa Cruz de Campezo", "Laudio-Llodio", "Vitoria – Gasteiz", "Abanto-Zierbena", "Balmaseda",
//   "Ortuella", "Sodupe", "Amorebieta-Etxano", "Berriz", "Bakio", "Barakaldo", "Portugalete", "Santurtzi", "Sestao", "Berriatua",
//   "Ondarroa", "Bilbao", "Erandio", "Bakio", "Gernika", "Arratzu", "Getxo", "Leioa", "Sopela",
//   "Loiu", "Aduna", "Beasain", "Tolosa", "Urnieta", "Villabona", "Donostia-San Sebastián", "Errenteria", "Irun", "Orio","Zarautz", "Zumaia"];
//   localidadesEusItems: FormArray;
  centroForm: FormGroup;
  passwordForm: FormGroup;
  datoswebForm: FormGroup;
  compromisoForm: FormGroup;
  errorGrupos = [];
  zonas = [];
  subzonas = [];
  idiomas = [];
  entidades = [];
  subzonasArray: FormArray;

  refs = {
    cert4: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2NcsB3s">https://bit.ly/2NcsB3s</a>'},
    cert5: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/36zdZpT">https://bit.ly/36zdZpT</a>'},
    cert7: {
      formulario: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2DRkchs">https://bit.ly/2DRkchs</a>',
      gerencias: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2U28wzz">https://bit.ly/2U28wzz</a>',
    },
    cert9: {web: '<a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2GuW8op">https://bit.ly/2GuW8op</a>'}
  }

  vista = "personal";

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('certificadoSwal', {static: false}) private certificadoSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('compromisoSwal', {static: false}) private compromisoSwal: SwalComponent
  @ViewChild('errorCompFileSwal', {static: false}) private errorCompFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('bajaSwal', {static: false}) private bajaSwal: SwalComponent
  @ViewChild('bajaConfirmSwal', {static: false}) private bajaConfirmSwal: SwalComponent
  @ViewChild('changeSwal', {static: false}) private changeSwal: SwalComponent

  constructor(private authenticationService: AuthenticationService,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private userService: UserService,
    private documentoService: DocumentoService,
	private fb: FormBuilder,
	private translate: TranslateService,
    private cdr: ChangeDetectorRef,
	private modalService: NgbModal,
	private logService: LogService,
	private gestionNodoService: GestionnodoService,
    private router: Router) {
      this.unsubscribe = new Subject();
    }

    openCentred(content) {
      this.modalService.open(content, { centered: true, size: 'lg' } );
    }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getNodo();
	//this.loadZonas();
    this.loadIdiomas();
    this.loadEntidades();
    if(this.currentUser.role == "mentora") this.initStartForm();
	if(this.currentUser.role == "centro") this.initCentroForm();
	this.initPasswordForm();
	this.initDatoswebForm();
	this.initCompromisoForm();
	this.loadEdicion(this.currentUser.edicion);
	this.cdr.detectChanges();
    
  }

  cambioVista(vista: any) {
    this.vista = vista;
  }

  getNodo(){
    this.nodoService.getById(this.currentUser.nodo)
	    .subscribe(nodo => {
      		this.nodo = nodo;
			this.cdr.detectChanges();
		});
  }

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  // ---- MENTORA - INFORMACION PERSONAL ----

  	loadEdiciones() {
		console.log("loadEdiciones()");
		this.edicionService.getAllInactive()
	    .subscribe(ediciones => {
			console.log(ediciones);
			this.ediciones = ediciones;
			//this.cdr.detectChanges();
			this.items = this.startForm.get('items') as FormArray;
			for(var i = 0; i < this.ediciones.length; i++){
				this.items.push(this.createItem(this.ediciones[i]));
      		}
      		this.cdr.detectChanges();
		});
	}

	loadEdicion(id: any) {
		console.log("loadEdicion()");
		this.edicionService.getById(id)
	    .subscribe(edicion => {
			console.log(edicion);
			this.edicion = edicion;
			this.cdr.detectChanges();
			/*this.items = this.startForm.get('items') as FormArray;
			for(var i = 0; i < this.ediciones.length; i++){
				this.items.push(this.createItem(this.ediciones[i]));
      		}
      		this.cdr.detectChanges();*/
		});
	}

//   loadPoblaciones() {
// 		this.localidadesItems = this.startForm.get('localidadesItems') as FormArray;
// 		if(this.localidadesItems)
// 			for(var i = 0; i < this.localidades.length; i++){
// 				this.localidadesItems.push(this.createItemLoc(this.localidades[i]));
// 			}
// 		this.cdr.detectChanges();
// 	}


//   loadPoblacionesEus(){
//     this.localidadesEusItems = this.startForm.get('localidadesEusItems') as FormArray;
// 	console.warn(this.subzonasArray);
//     if(this.localidadesEusItems)
// 	console.warn(this.localidadesEusItems, this.subzonasArray);
//       for(var i = 0; i < this.subzonasArray.length; i++){
//         this.localidadesEusItems.push(this.createItemLoc(this.subzonasArray[i]));
//       }
//       this.cdr.detectChanges();
//   }

	zonasName = [];
	zonasSel = {};
	zonaSeleccionada = false;
  loadZonas(){
	this.gestionNodoService.getZonasByNodo(this.currentUser.nodo)
    .subscribe(zonas => {
      this.zonas = zonas;
      for(var i = 0; i < zonas.length; i++) {
        this.zonasName[zonas[i].id+""] = zonas[i];
      }
      this.localidadesItems = this.startForm.get('localidadesItems') as FormArray;
		if(this.localidadesItems) {
			for(var i = 0; i < this.zonas.length; i++) {
				this.localidadesItems.push(this.createItemLoc(this.zonas[i].nombre, null, this.zonas[i].id));
			}
		}

		var localidadesItems = this.startForm.value.localidadesItems;
		this.zonaSeleccionada = false;
		for(var i = 0; i < localidadesItems.length; i++) {
			this.zonasSel[localidadesItems[i].zonaId + ""] = localidadesItems[i].desplaza;
			if(localidadesItems[i].desplaza ) {
				this.zonaSeleccionada = true;
			}
		}
		this.cdr.detectChanges();

		this.startForm.get("localidadesItems").valueChanges.subscribe(x => {

			this.zonaSeleccionada = false;
			for(var i = 0; i < x.length; i++) {
				this.zonasSel[x[i].zonaId + ""] = x[i].desplaza;
				if(x[i].desplaza ) {
					this.zonaSeleccionada = true;
				}
			}
			this.cdr.detectChanges();
		 })
      	this.cdr.detectChanges();
    });
  }
 
  loadSubzonas(nodo){
	this.gestionNodoService.getSubzonasByNodo(nodo)
    .subscribe(subzonas => {
		this.subzonas = subzonas;
		this.sublocalidadesItems = this.startForm.get('sublocalidadesItems') as FormArray;
		if(this.sublocalidadesItems) {
			for(var i = 0; i < this.subzonas.length; i++) {
				this.sublocalidadesItems.push(this.createItemSubLoc(this.subzonas[i].nombre, this.subzonas[i].zona, null));
			}
		}
      this.cdr.detectChanges();
    });
  }

  loadIdiomas(){
	this.gestionNodoService.getIdiomasByNodo(this.currentUser.nodo)
    .subscribe(idiomas => {
      this.idiomas = idiomas;
      this.cdr.detectChanges();
    });
  }

  loadEntidades(){
	this.gestionNodoService.getEntidadesByNodo(this.currentUser.nodo)
    .subscribe(entidades => {
      this.entidades = entidades;
      this.cdr.detectChanges();
    });
  }

	createItem(edicion: any): FormGroup {
		var participa = false;
		if(this.currentUser.ediciones && this.currentUser.ediciones.includes(edicion.name)){
			participa = true;
		}
		return this.fb.group({
			edicion: edicion.name,
			participa: participa
		});
	}

	createItemLoc(localidad: any, zona: any, zonaId: any): FormGroup {
		var desplaza = false;
		if(this.currentUser.localidades && this.currentUser.localidades.includes(localidad)){
			desplaza = true;
		}
		return this.fb.group({
			nombre: localidad,
			desplaza: desplaza,
			zona: zona,
			zonaId: zonaId
		});
	}

	createItemSubLoc(localidad: any, zona: any, zonaId: any): FormGroup {
		var desplaza = false;
		if(this.currentUser.subzonas && this.currentUser.subzonas.includes(localidad)){
			desplaza = true;
		}
		return this.fb.group({
			nombre: localidad,
			desplaza: desplaza,
			zona: zona,
			zonaId: zonaId
		});
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initStartForm() {
		var masGrupos = "no";
		if(this.currentUser.masGrupos) masGrupos = "si";
		var expMentora = "no";
		if(this.currentUser.expMentora) expMentora = "si";

		if(this.currentUser.nodo == 1) {	//Euskadi
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				idiomaEu: [this.currentUser.idiomaEu],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				provAra: [this.currentUser.provAra],
				provBiz: [this.currentUser.provBiz],
				provGip: [this.currentUser.provGip],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 3) {	//Asturies
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});

		} else if(this.currentUser.nodo == 7 || this.currentUser.nodo == 8|| this.currentUser.nodo == 9 || this.currentUser.nodo == 11 || this.currentUser.nodo == 12) { //Murcie, Jaen, Txile, Pontevedra, Ourense
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 4 || this.currentUser.nodo == 5) {	//Barcelona
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				idiomaCat: [this.currentUser.idiomaCat],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 6) {	//Canarias
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				provTen: [this.currentUser.provTen],
				provGca: [this.currentUser.provGca],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 2) {	//Madrid
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo == 10) {	//Inspira STEAM +
			var provincia = "";
			if(this.currentUser.provMad) provincia = "Madrid"
			else if(this.currentUser.provBcn) provincia = "Barcelona"
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				prov: [provincia, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		} else if(this.currentUser.nodo != 1 && this.currentUser.nodo != 2 &&this.currentUser.nodo != 3 &&this.currentUser.nodo != 4 && this.currentUser.nodo != 5 && 
			this.currentUser.nodo != 6 &&this.currentUser.nodo != 7 && this.currentUser.nodo != 8 && this.currentUser.nodo != 9 && this.currentUser.nodo != 10 && this.currentUser.nodo != 11 && this.currentUser.nodo != 12) {
			this.startForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				apellidos: [this.currentUser.apellidos, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				estudios: [this.currentUser.estudios, Validators.compose([Validators.required])],
				entidad: [this.currentUser.entidad],
				entidadopt: [this.currentUser.entidadopt],
				sesionesNinas: [this.currentUser.sesionesNinas],
				sesionesNinos: [this.currentUser.sesionesNinos],
				//zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				masGrupos: [masGrupos, Validators.compose([Validators.required])],
				expMentora: [expMentora, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				genero: [this.currentUser.genero, Validators.compose([Validators.required])],
				repCent: [this.currentUser.repCent],
				items: this.fb.array([ ]),
				localidadesItems: this.fb.array([ ]),
				sublocalidadesItems: this.fb.array([ ])
			});
		}
		this.loadEdiciones();
		//this.loadPoblaciones();
		this.loadZonas();
		this.loadSubzonas(this.currentUser.nodo);
		//this.loadPoblacionesEus();
  	}

	/**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.submitted = true;

		const controls = this.startForm.controls;

		console.log(controls);

		if(this.currentUser.nodo == 1 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaEu) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if((this.currentUser.nodo == 4 || this.currentUser.nodo == 5) && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs && !this.startForm.value.idiomaCat) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if(this.currentUser.nodo == 2 && !this.startForm.value.idiomaEn && !this.startForm.value.idiomaEs) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		if(!this.startForm.value.sesionesNinas && !this.startForm.value.sesionesNinos) {
			this.error.sesiones = true;
		} else {
			this.error.sesiones = false;
		}

		if(this.currentUser.nodo == 1) {
			if(!this.startForm.value.provAra && !this.startForm.value.provBiz && !this.startForm.value.provGip) {
				this.error.provincia = true;
			} else {
				this.error.provincia = false;
			}
		}

		if(this.currentUser.nodo == 6) {
			if(!this.startForm.value.provTen && !this.startForm.value.provGca) {
				this.error.provincia = true;
			} else {
				this.error.provincia = false;
			}
		}

		var ediciones = [];

		if(this.startForm.value.expMentora == "si") {
			console.log(this.startForm.value);
			var items = this.startForm.value.items;
			var selected = false;
			ediciones = [];
			for(var i = 0; i < items.length; i++) {
				if(items[i].participa) {
					selected = true;
					ediciones.push(items[i].edicion);
				}
			}
			if(!selected) {
				this.error.participa = true;
			} else {
				this.error.participa = false;
			}
		} else {
			this.error.participa = false;
		}

		var localidades = [];

    // if(this.currentUser.nodo == 3) {
	// 		var localidadesItems = this.startForm.value.localidadesItems;
	// 		var selectedLoc = false;
	// 		localidades = [];
	// 		for(var i = 0; i < localidadesItems.length; i++) {
	// 			if(localidadesItems[i].desplaza) {
	// 				selectedLoc = true;
	// 				localidades.push(localidadesItems[i].nombre);
	// 			}
	// 		}
	// 		if(!selectedLoc) {
	// 			this.error.desplaza = true;
	// 		} else {
	// 			this.error.desplaza = false;
	// 		}
	// 	} else {
	// 		this.error.desplaza = false;
	// 	}

		if(this.zonas.length != 0) {
			var localidadesItems = this.startForm.value.localidadesItems;
			var selectedLoc = false;
			localidades = [];
			for(var i = 0; i < localidadesItems.length; i++) {
				if(localidadesItems[i].desplaza) {
					selectedLoc = true;
					localidades.push(localidadesItems[i].nombre);
				}
			}
			if(!selectedLoc) {
				this.error.desplaza = true;
			} else {
				this.error.desplaza = false;
			}
		} else {
			this.error.desplaza = false;
		}

		var subzonas = [];

		if(this.subzonas.length != 0) {
			var sublocalidadesItems = this.startForm.value.sublocalidadesItems;
			subzonas = [];
			for(var i = 0; i < sublocalidadesItems.length; i++) {
				if(sublocalidadesItems[i].desplaza) {
					subzonas.push(sublocalidadesItems[i].nombre);
				}
			}
		}


		// check form
		if (this.startForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if(this.error.idioma || this.error.participa || this.error.sesiones || this.error.provincia){
			console.log(this.error);
			return;
		}

		this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
		var form = this.startForm.value;
		form.localidades = JSON.stringify(localidades);
		form.subzonas = JSON.stringify(subzonas);
		if(form.expMentora == "si") {
			form.expMentora = true;
			form.ediciones = JSON.stringify(ediciones);
		} else {
			form.expMentora = false;
			form.ediciones = null;
		}
		if(form.masGrupos == "si") form.masGrupos = true;
		else form.masGrupos = false;
		// if(form.entidadopt != null && form.entidadopt != "") {
		// 	if(form.entidadopt != "Otra") form.entidad = form.entidadopt;
		// }

		if(form.prov == "Madrid") {
			form.provMad = true;
			form.provBcn = false;
		} else if(form.prov == "Barcelona") {
			form.provBcn = true;
			form.provMad = false;
		}

		form.id = currentUser.id;

		console.log(form);

		this.userService.update(form)
            .pipe(first())
            .subscribe(
                data => {

          			//window.scrollTo(0, 0);
					console.log("OK", data);
					currentUser.name = form.name;
					currentUser.apellidos = form.apellidos;
					currentUser.comentarios = form.comentarios;
					currentUser.ediciones = form.ediciones;
					currentUser.estudios = form.estudios;
					currentUser.expMentora = form.expMentora;
					if(form.idiomaEn) currentUser.idiomaEn = form.idiomaEn;
					if(form.idiomaEs) currentUser.idiomaEs = form.idiomaEs;
					if(form.idiomaEu) currentUser.idiomaEu = form.idiomaEu;
					if(form.idiomaCat) currentUser.idiomaCat = form.idiomaCat;
					if(form.entidad) currentUser.entidad = form.entidad;
					if (form.entidadopt) currentUser.entidadopt = form.entidadopt;
					if(form.provAra) currentUser.provAra = form.provAra;
					if(form.provBiz) currentUser.provBiz = form.provBiz;
					if(form.provGip) currentUser.provGip = form.provGip;
					if(form.provTen) currentUser.provTen = form.provTen;
					if(form.provGca) currentUser.provGca = form.provGca;
					if(form.provMad) currentUser.provMad = form.provMad;
					if(form.provBcn) currentUser.provBcn = form.provBcn;
					currentUser.masGrupos = form.masGrupos;
					currentUser.sesionesNinas = form.sesionesNinas;
					currentUser.sesionesNinos = form.sesionesNinos;
					currentUser.telefono = form.telefono;
					currentUser.genero = form.genero;
					if(form.localidades) currentUser.localidades = form.localidades;
					if(form.subzonas) currentUser.subzonas = form.subzonas;
					//currentUser.localidadesEus = form.localidades;
					if (form.repCent) currentUser.repCent = form.repCent;
					if(form.zona) currentUser.zona = form.zona;

					this.logService.post({tipo: "EDITAR-PERFIL", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
            			.pipe(first()).subscribe();

          			this.currentUser = currentUser;

					this.authenticationService.changeUser(currentUser);

          			this.loading = false;
					this.confirmationSwal.fire();

					this.initStartForm();
        },
        error => {
            console.error(error);
			//this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
			this.loading = false;
        });
	}

	// ------------------- Datos Web -------------------------
	initDatoswebForm(){
		if (this.submitted = false) {
			this.datoswebForm = this.fb.group({
			webName: ['', Validators.compose([Validators.maxLength(400)])],
			webBio: ['', Validators.compose([Validators.maxLength(4000)])],
			webFoto: ['', Validators.compose([Validators.maxLength(400)])],
			webSocial: ['', Validators.compose([Validators.maxLength(400)])],
			items: this.fb.array([ ])
		});
		} else if (this.submitted = true) {
			this.datoswebForm = this.fb.group({
			webName: [this.currentUser.webName, Validators.compose([Validators.maxLength(400)])],
			webBio: [this.currentUser.webBio, Validators.compose([Validators.maxLength(4000)])],
			webFoto: [this.currentUser.webFoto, Validators.compose([Validators.maxLength(400)])],
			webSocial: [this.currentUser.webSocial, Validators.compose([Validators.maxLength(400)])],
			items: this.fb.array([ ])
		});

		}
	}
		
	initCompromisoForm(){
		if (this.submitted = false) {
			this.compromisoForm = this.fb.group({
			compLogo: ['', Validators.compose([Validators.required])],
			compWeb: ['', Validators.compose([Validators.required])],
			items: this.fb.array([ ])
		});
		} else if (this.submitted = true) {
			this.compromisoForm = this.fb.group({
			compLogo: [this.currentUser.compLogo, Validators.compose([Validators.required])],
			compWeb: [this.currentUser.compWeb, Validators.compose([Validators.required])],
			items: this.fb.array([ ])
		});

		}
	}

	/**
 * Form Submit
 */

onSubmitDatosWeb() {
	if(this.loading) return;

	this.submitted = true;
	
	const controls = this.datoswebForm.controls;
	if (this.datoswebForm.invalid) {
		Object.keys(controls).forEach(controlName =>
			controls[controlName].markAsTouched()
		);
		return;
	}

	this.loading = true;
	var currentUser = this.authenticationService.currentUserValue;
	var form = this.datoswebForm.value;
	form.id = currentUser.id;
	console.log(form);

	
	this.userService.update(form)
		.pipe(first())
		.subscribe(
			data => {

				  //window.scrollTo(0, 0);
				console.log("OK", data);
				currentUser.webName = form.webName;
				currentUser.webBio = form.webBio;
				currentUser.webFoto = form.webFoto;
				currentUser.webSocial = form.webSocial;

				this.logService.post({tipo: "EDITAR-PERFIL", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
					.pipe(first()).subscribe();

				  this.currentUser = currentUser;

				this.authenticationService.changeUser(currentUser);

				  this.loading = false;
				this.confirmationSwal.fire();

				this.initDatoswebForm();
	},
	error => {
		console.error(error);
		//this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
		this.loading = false;
	});
}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.startForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  	}
	  isControlDatoswebHasError(controlName: string, validationType: string): boolean {
		const control = this.datoswebForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}
	
		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
	}
	
	// ---- CENTRO - INFORMACION CENTRO ----

	loadEdicionesCentro() {
		console.log("loadEdicionesCentro()");
		this.edicionService.getAllInactive()
		.subscribe(ediciones => {
			console.log(ediciones);
			this.ediciones = ediciones;
			//this.cdr.detectChanges();
			this.edicionesItem = this.centroForm.get('edicionesItem') as FormArray;
			for(var i = 0; i < this.ediciones.length; i++){
				this.edicionesItem.push(this.createItemEdicion(this.ediciones[i]));
			  }
			  this.cdr.detectChanges();
		});
	}

	createItemCentro(grupo: any): FormGroup {
		var grupo = grupo;
		if(grupo == null) grupo = {nombre: '', chicas: null, chicos: null}
		return this.fb.group({
			nombre: [grupo.nombre || "", Validators.compose([Validators.required])],
			chicas: [grupo.chicas, Validators.compose([Validators.required])],
			chicos: [grupo.chicos, Validators.compose([Validators.required])],
		});
	}

		
	createItemEdicion(edicion: any): FormGroup {
		var participa = false;
		if(this.currentUser.ediciones && this.currentUser.ediciones.includes(edicion.name)){
			participa = true;
		}
		return this.fb.group({
			edicion: edicion.name,
			participa: participa
		});
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initCentroForm() {
		try{
			this.currentUser.gruposArray = JSON.parse(this.currentUser.gruposArray);

			var expCentro = "no";
			if(this.currentUser.expCentro) expCentro = "si";
		} catch (e){}
		if(this.currentUser.nodo == 1) {
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				//idiomaEu: [this.currentUser.idiomaEu],
				//idiomaEs: [this.currentUser.idiomaEs],
				//idiomaEn: [this.currentUser.idiomaEn],
				idioma: [this.currentUser.idioma],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 3) {	//Asturies
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 4 || this.currentUser.nodo == 5 || this.currentUser.nodo == 10) {	//Barcelona y Plus
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				idiomaCat: [this.currentUser.idiomaCat],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 6 || this.currentUser.nodo == 7 || this.currentUser.nodo == 8) {	//Canarias, Murtxia, Jaén 
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 11 || this.currentUser.nodo == 12) {	// Pontevedra,Ourense		
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				//provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				zona: [this.currentUser.zona, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 2) {	//Madrid
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				idiomaEs: [this.currentUser.idiomaEs],
				idiomaEn: [this.currentUser.idiomaEn],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo == 9) {	//Txile
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		} else if(this.currentUser.nodo != 1 && this.currentUser.nodo != 2 &&this.currentUser.nodo != 3 &&this.currentUser.nodo != 4 && this.currentUser.nodo != 5 && 
			this.currentUser.nodo != 6 &&this.currentUser.nodo != 7 && this.currentUser.nodo != 8 && this.currentUser.nodo != 9 && this.currentUser.nodo != 10 && this.currentUser.nodo != 11 && this.currentUser.nodo != 12) {
			this.centroForm = this.fb.group({
				name: [this.currentUser.name, Validators.compose([Validators.required])],
				poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
				provincia: [this.currentUser.provincia, Validators.compose([Validators.required])],
				curso:  [this.currentUser.curso, Validators.compose([Validators.required])],
				personaContacto: [this.currentUser.personaContacto, Validators.compose([Validators.required])],
				nombreContacto: [this.currentUser.nombreContacto, Validators.compose([Validators.required])],
				emailContacto: [this.currentUser.emailContacto, Validators.compose([Validators.required])],
				telefono: [this.currentUser.telefono, Validators.compose([Validators.required])],
				grupos: [this.currentUser.grupos, Validators.compose([Validators.required])],
				comentarios: [this.currentUser.comentarios],
				items: this.fb.array([ ]),
				dirCentro: [this.currentUser.dirCentro, Validators.compose([Validators.required])],
				expCentro: [expCentro, Validators.compose([Validators.required])],
				edicionesItem: this.fb.array([]),
				webCentro: [this.currentUser.webCentro]
			});
		}
		//if(this.currentUser.nodo == 11 || this.currentUser.nodo == 12) {
		this.loadEdicionesCentro();
		//}
		this.items = this.centroForm.get('items') as FormArray;
		for(var i = 0; i < this.currentUser.gruposArray.length; i++){
			this.items.push(this.createItemCentro(this.currentUser.gruposArray[i]));
		}
		//this.gruposCambiados();
	}

	gruposCambiados() {
		var grupos = this.centroForm.value.grupos;
		console.log(grupos);
		if(grupos < this.items.length) {
		var size = this.items.length;
		for(var i = size; i > grupos; i--){
			this.items.removeAt(i-1);
		}
		} else if(grupos > this.items.length) {
		for(var i = this.items.length; i < grupos; i++){
			this.items.push(this.createItemCentro(null));
		}
		}


	}

	/**
	 * Form Submit
	 */
	onSubmitCentro() {
		if(this.loading) return;

		this.submitted = true;

		const controls = this.centroForm.controls;

		if(this.currentUser.nodo == 1 && !this.centroForm.value.idioma/*En && !this.centroForm.value.idiomaEs && !this.centroForm.value.idiomaEu*/) {
			this.error.idioma = true;
		} else if((this.currentUser.nodo == 4 || this.currentUser.nodo == 5 || this.currentUser.nodo == 10) && !this.centroForm.value.idiomaEn && !this.centroForm.value.idiomaEs && !this.centroForm.value.idiomaCat) {
			this.error.idioma = true;
		} else if(this.currentUser.nodo == 2 && !this.centroForm.value.idiomaEn && !this.centroForm.value.idiomaEs) {
			this.error.idioma = true;
		} else {
			this.error.idioma = false;
		}

		var ediciones = [];

		if (this.centroForm.value.expCentro == "si") {
			var edicionesItem = this.centroForm.value.edicionesItem;
			var selected = false;
			ediciones = [];
			for(var i = 0; i < edicionesItem.length; i++) {
				if(edicionesItem[i].participa) {
					selected = true;
					ediciones.push(edicionesItem[i].edicion); //ya ha elegido edicion
				}
			}
			if(!selected) {
				this.error.participa = true;
			} else {
				this.error.participa = false;
			}
		} else {
			this.error.participa = false;
		}

		// check form
		if (this.centroForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if(this.error.idioma || this.error.participa){
			return;
		}

    	this.loading = true;

		var currentUser = this.authenticationService.currentUserValue;
		var form = this.centroForm.value;
		form.id = currentUser.id;
		form.grupos = parseInt(form.grupos);
		form.gruposArray = JSON.stringify(form.items);
		
			if(form.expCentro == "si") {
				form.expCentro = true;
				form.ediciones = JSON.stringify(ediciones);
			} else {
				form.expCentro = false;
				form.ediciones = null;
			}

		this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          	console.log("OK", data);

			currentUser.comentarios = form.comentarios;
			if(form.curso) currentUser.curso = form.curso;
			currentUser.grupos = form.grupos;
			if(form.idiomaEn) currentUser.idiomaEn = form.idiomaEn;
			if(form.idiomaEs) currentUser.idiomaEs = form.idiomaEs;
			if(form.idiomaEu) currentUser.idiomaEu = form.idiomaEu;
			if(form.idiomaCat) currentUser.idiomaCat = form.idiomaCat;
			if(form.idioma) currentUser.idioma = form.idioma;
			currentUser.gruposArray = form.gruposArray;
			currentUser.personaContacto = form.personaContacto;
			currentUser.nombreContacto = form.nombreContacto;
			currentUser.emailContacto = form.emailContacto;
			currentUser.poblacion = form.poblacion;
			if(form.provincia) currentUser.provincia = form.provincia;
			currentUser.telefono = form.telefono;
			if(form.ediciones) currentUser.ediciones = form.ediciones;
			if(form.expCentro) currentUser.expCentro = form.expCentro;
			if(form.zona) currentUser.zona = form.zona;
			if(form.dirCentro) currentUser.dirCentro = form.dirCentro;
			if(form.webCentro) currentUser.webCentro = form.webCentro;

			this.currentUser = currentUser;

			this.logService.post({tipo: "EDITAR-PERFIL", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
				.pipe(first()).subscribe();

			this.authenticationService.changeUser(currentUser);

          	this.loading = false;
			this.confirmationSwal.fire();
			this.initCentroForm();
        },
        error => {
          console.error(error);
          //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
          this.loading = false;
        });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasErrorCentro(controlName: string, validationType: string): boolean {
		const control = this.centroForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  	isControlHasErrorGrupos(num: any, controlName: string, validationType: string): boolean {
		const control = this.centroForm.controls.items['controls'][num].controls[controlName];
		if (!control || !this.submitted) {
			return false;
    }

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType);
			return result;
		}
	}


  // ------------------- CERTIFICADOS -------------------------

  files: File[] = [];
  image: any;



  onSelect(event) {
    console.log(event);
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);


    console.log(event.addedFiles[0]);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  subirCertificado() {
	if(this.loading) return;
	if(this.files.length == 0) {
		this.errorNotFileSwal.fire();
		return;
	}
    this.loading = true;
    var myReader:FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;

      var doc = {user: this.currentUser.id, tipo: "CERT_DEL_SEX", contenido: myReader.result, name: this.files[0].name, fechaSubida: new Date(), nodo: this.currentUser.nodo};

      this.documentoService.post(doc)
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);

            var form = {id: this.currentUser.id, certificado: data.id, subidaCertificado: new Date()};

            this.userService.update(form)
              .pipe(first())
              .subscribe(
                data => {
                  console.log("OK", data);

                  this.currentUser.certificado = form.certificado;
                  this.currentUser.subidaCertificado = form.subidaCertificado;

				  this.authenticationService.changeUser(this.currentUser);

				  this.logService.post({tipo: "CERTIFICADO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: form.certificado})
            			.pipe(first()).subscribe();

                  this.loading = false;
                  this.certificadoSwal.fire();
                },
                error => {
                  console.error(error);
                  //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
                  this.loading = false;
                });

          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }
    myReader.readAsDataURL(this.files[0]);
  }

  
  descargarCompromiso() {
    this.documentoService.getById(this.currentUser.compromiso)
    .subscribe(data => {
      console.trace("trace",data);
      var element = document.createElement('a');
      element.setAttribute('href', data.contenido);
      element.setAttribute('download', data.name);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    });
  }

  descargarPlantilla() {
    var url = "";
    var name = "";
    if(this.currentUser.nodo == 1) {
          url = this.plantCompEntEu;
          name = "Plantilla_Compromiso_Entidad";
        } else {
          url = this.plantCompEntEs;
          name = "Plantilla_Compromiso_Entidad";
		}
	this.logService.post({tipo: "DESCARGA", fecha: new Date(), user: this.currentUser.id, details: "Compromiso", referencia: null})
		.pipe(first()).subscribe();

	let link = document.createElement('a');
	link.setAttribute('type', 'hidden');
	link.href = url;
	link.download = name;
	document.body.appendChild(link);
	link.click();
	link.remove();
}


  filesComp: File[] = [];
  imageComp: any;

  onSelectComp(event) {
    console.log(event);
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.filesComp = [];
    this.filesComp.push(...event.addedFiles);


    console.log(event.addedFiles[0]);
  }

  onRemoveComp(event) {
    console.log(event);
    this.filesComp.splice(this.files.indexOf(event), 1);
  }
  
  subirCompromiso() {
	if(this.loading) return;
	if(this.filesComp.length == 0) {
		this.errorNotFileSwal.fire();
		return;
	}

	// const controls = this.compromisoForm.controls;
	// if (this.compromisoForm.invalid) {
	// 	Object.keys(controls).forEach(controlName =>
	// 		controls[controlName].markAsTouched()
	// 	);
	// 	return;
	// }
	this.loading = true;

    var myReaderComp:FileReader = new FileReader();
    myReaderComp.onloadend = (e) => {
      this.imageComp = myReaderComp.result;

      var doc = {user: this.currentUser.id, tipo: "CERT_COMP_ENT", contenido: myReaderComp.result, name: this.filesComp[0].name, fechaSubida: new Date(), nodo: this.currentUser.nodo};

      this.documentoService.post(doc) //post at DB table documento
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);
			var form = this.compromisoForm.value;
			form.compromiso = data.id;
			form.id = this.currentUser.id
            //var form = {id: this.currentUser.id, compromiso: data.id, subidaCompromiso: new Date()};

            this.userService.update(form)
              .pipe(first())
              .subscribe(
                data => {
                  console.log("OK", data);
				//write at the table users
				  this.currentUser.compromiso = form.compromiso;
				//   this.currentUser.compLogo = form.compLogo; 
                //   this.currentUser.compWeb = form.compWeb;

				  this.authenticationService.changeUser(this.currentUser);

				//   this.logService.post({tipo: "CERTIFICADO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: form.certificado})
            	// 		.pipe(first()).subscribe();

                  this.loading = false;
				  this.confirmationSwal.fire();
				  this.cdr.detectChanges();
				//   this.compromisoSwal.fire();
				  this.initCompromisoForm();
                },
                error => {
                  console.error(error);
                  //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
                  this.loading = false;
                });

          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }
    myReaderComp.readAsDataURL(this.filesComp[0]);
  }
  subirCompromiso1() {
	if(this.loading) return;
	// if(this.filesComp.length == 0) {
	// 	this.errorNotFileSwal.fire();
	// 	return;
	// }

	const controls = this.compromisoForm.controls;
	if (this.compromisoForm.invalid) {
		Object.keys(controls).forEach(controlName =>
			controls[controlName].markAsTouched()
		);
		return;
	}
	this.loading = true;

    // var myReaderComp:FileReader = new FileReader();
    // myReaderComp.onloadend = (e) => {
    //   this.imageComp = myReaderComp.result;

    //   var doc = {user: this.currentUser.id, tipo: "CERT_COMP_ENT", contenido: myReaderComp.result, name: this.filesComp[0].name, fechaSubida: new Date(), nodo: this.currentUser.nodo};

      
			var form = this.compromisoForm.value;
			form.id = this.currentUser.id
            //var form = {id: this.currentUser.id, compromiso: data.id, subidaCompromiso: new Date()};

            this.userService.update(form)
              .pipe(first())
              .subscribe(
                data => {
                  console.log("OK", data);
				//write at the table users
				//   this.currentUser.compromiso = form.compromiso;
				  this.currentUser.compLogo = form.compLogo; 
                  this.currentUser.compWeb = form.compWeb;

				  this.authenticationService.changeUser(this.currentUser);

				//   this.logService.post({tipo: "CERTIFICADO", fecha: new Date(), user: this.currentUser.id, details: null, referencia: form.certificado})
            	// 		.pipe(first()).subscribe();

                  this.loading = false;
				  this.confirmationSwal.fire();
				//   this.compromisoSwal.fire();
				  this.initCompromisoForm();
                },
                error => {
                  console.error(error);
                  //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
                  this.loading = false;
                });
    }


  isControlHasErrorComp(controlName: string, validationType: string): boolean {
	const control = this.compromisoForm.controls[controlName];
	if (!control || !this.submitted) {
		return false;
	}

	if(!validationType) {
		const result = control.invalid && (control.dirty || control.touched);
		return result;
	} else {
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
  }

  // --------------------- CAMBIO CONTRASEÑA ---------------------

  initPasswordForm() {
	this.passwordForm = this.fb.group({
		oldpassword: ['', Validators.compose([
			Validators.required
		])
		],
		password: ['', Validators.compose([
			Validators.required,
			Validators.minLength(4),
			Validators.maxLength(100)
		])
		],
		confirmPassword: ['', Validators.compose([
			Validators.required,
			Validators.minLength(4),
			Validators.maxLength(100)
		])
		]
	}, {
		validator: ConfirmPasswordValidator.MatchPassword
	});
}


	errorPass = null;

  changePassword(){
	if(this.loading) return;

	console.log("changePassword()");

	this.submitted = true;

	const controls = this.passwordForm.controls;

	// check form
	if (this.passwordForm.invalid) {
		Object.keys(controls).forEach(controlName =>
			controls[controlName].markAsTouched()
		);
		return;
	}
	var user = this.passwordForm.value;

	if(user.oldpassword == user.password){
		this.errorPass = "La contraseña antigua y la nueva no pueden ser iguales";
		return;
	}

	this.loading = true;

	user.id = this.currentUser.id;

	this.userService.changepassword(user)
		.pipe(first())
		.subscribe(
			data => {
				console.log("OK", data);
				this.loading = false;
				if(data["error"] == "PASSWORD"){
					console.log("ERROR!!");
					this.errorPass = this.translate.instant('PERFIL.PASSWORD.ERROR');
					this.cdr.detectChanges();
				} else {
					this.errorPass = null;
					this.initPasswordForm();
					this.changeSwal.fire();
					this.logService.post({tipo: "PASSWORD-CHANGE", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
            			.pipe(first()).subscribe();
					this.cdr.detectChanges();
				}
			},
			error => {
				console.error(error);
			});
  }

  isControlPasswordHasError(controlName: string, validationType: string): boolean {
	const control = this.passwordForm.controls[controlName];
	if (!control || !this.submitted) {
		return false;
	}

	if(!validationType) {
		const result = control.invalid && (control.dirty || control.touched);
		return result;
	} else {
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}




  // ------------------------- BAJA -------------------------------

  	baja(){
		if(this.loading) return;
		this.bajaSwal.fire()
  	}

  	baja1(){
		this.loading = true;
		var form = {id: this.currentUser.id, isActive: false, username: this.currentUser.username + "-" + moment().unix()}
		this.userService.update(form)
		.pipe(first())
		.subscribe(
			data => {
				console.log("OK", data);

				this.loading = false;
				this.bajaConfirmSwal.fire();

				this.logService.post({tipo: "BAJA", fecha: new Date(), user: this.currentUser.id, details: null, referencia: null})
            			.pipe(first()).subscribe();

				this.authenticationService.logout();
			},
			error => {
				console.error(error);
				//this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
				this.loading = false;
			});
  	}


}
