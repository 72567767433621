import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NodoService, UserService, AuthenticationService,EdicionService, LogService } from '../../../_services';

import { first } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as XLSX from 'xlsx'; 
import * as moment from 'moment';
//import { currentUser } from 'src/app/core/auth';
import{ GlobalVariables } from '../../../_common/global-variables';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-centros',
  templateUrl: './centros.component.html',
  styleUrls: ['./centros.component.scss']
})
export class CentrosComponent implements OnInit {

  @ViewChild('bajaSwal', {static: false}) private bajaSwal: SwalComponent
  @ViewChild('bajaConfirmSwal', {static: false}) private bajaConfirmSwal: SwalComponent

  currentUser: any;
  selectedUser: any;
  nodos = {};
  rows = [];
  nodo = [];
  originalRows = [];
  edicionActiva: any;
  ediciones = [];
  edicionesHash = {}
  loading = [];
  selectedIndex: any;

  constructor(private userService: UserService,
    private cdr: ChangeDetectorRef,
    private nodoService: NodoService,
    private edicionService: EdicionService,
    private logService: LogService,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  loadCentros() {
    this.userService.getByRol('centro')
    .subscribe(users => {
      this.originalRows = users;
      for(let user of this.originalRows) {
        //this.loadInscripcion(user);
        if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
      }
      this.filterDatatable();
      this.cdr.detectChanges();
    });
  }  

  loadCentrosNodo() {
    this.userService.getByRolNodo('centro', this.currentUser.nodo)
	    .subscribe(users => {
      this.originalRows = users;
      console.warn(users);
      for(let user of this.originalRows) {
        if(user.lastInscripcion) user.lastInscripcionStr = moment.utc(user.lastInscripcion.date).local().format("DD/MM/YYYY");
        //this.loadInscripcion(user);
      }
      this.filterDatatable();
			this.cdr.detectChanges();
		});
  }

  inscripcionesCargadas = 0;
  inscripcionesLoading = false;

  /*loadInscripcion(centro: any) {
    this.logService.getLastInscripcion(centro.id)
    .subscribe(events => {
      if(events.length > 0) centro.lastInscripcion = moment.utc(events[events.length - 1].fecha).local().format("DD/MM/YYYY HH:mm");
      this.inscripcionesCargadas++;
      if(this.inscripcionesCargadas >= this.originalRows.length) this.inscripcionesLoading = false;
      this.cdr.detectChanges();
    });
  }*/

  getEdicionActiva(){
    this.edicionService.getActive()
	    .subscribe(ediciones => {
        for(let edicion of ediciones) {
          this.edicionActiva = edicion.id;
          console.log(this.edicionActiva);
          this.edicionesHash[edicion.id+""] = edicion;
        }
        this.ediciones = ediciones;
		});
  }
    
  changeEstado(row){
    this.selectedIndex = row.id;
    this.loading[this.selectedIndex] = true;
    this.selectedUser = row;
    
    if(this.selectedUser.estado != 'PRE-INSCRITO' && this.selectedUser.estado != 'FORMULARIO') {
      var formEstado = {id: this.selectedUser.id, estado: this.selectedUser.estado, edicion: this.edicionActiva}
     } else {
       var formEstado = {id: this.selectedUser.id, estado: this.selectedUser.estado, edicion: null}
     }
    //  var formEstado = {id: this.selectedUser.id, estado: this.selectedUser.estado}
     this.userService.update(formEstado)
     .pipe(first())
     .subscribe(
       data => {
        this.loading[this.selectedIndex] = false;
         this.filterDatatable();
         this.cdr.detectChanges();
       },
       error => {
         this.cdr.detectChanges();
         console.error(error);
       });
     }
  
  // openList = 0;
  exportexcel(): void 
  {
    var datos = [...this.originalRows];
    for(var i = 0; i < datos.length; i++){
      var dato:any;
      var x=0
      dato = {...datos[i]};//copy in variable dato each object of datos.
      if(datos[i].gruposArray){
        for(let obj of JSON.parse(datos[i].gruposArray)){
          dato["nameGr" + x] = obj.nombre; //save dynamically attributes to an object
          dato["chicas" + x] = obj.chicas;
          dato["chicos" + x] = obj.chicos;
          x++;
        }
      }
      delete dato.avatar;
      delete dato.lang;
      delete dato.role;
      delete dato.apellidos;
      delete dato.estudios;
      //delete datos[i].zona;
      delete dato.sesionesNinas;
      delete dato.sesionesNinos;
      delete dato.masGrupos;
      delete dato.expMentora;
      delete dato.isActive;
      if(this.currentUser.nodo){
        delete dato.nodo;
      }
      delete dato.certificado;
      delete dato.subidaCertificado;
      //delete datos[i].formacionNo;
      delete dato.provBiz;
      delete dato.provAra;
      delete dato.provGip;
      delete dato.entidad;
      delete dato.entidadopt;
      delete dato.webBio;
      delete dato.webFoto;
      delete dato.webSocial;
      delete dato.webName;
      delete dato.genero;
      delete dato.provTen;
      delete dato.provMad;
      delete dato.provBcn;
      delete dato.provGca;
      delete dato.repCent;
      if(this.currentUser.nodo !=11 && this.currentUser.nodo != 12){
        delete dato.ediciones;
        delete dato.expCentro;
      }
      delete dato.compLogo;
      delete dato.compWeb;
      delete dato.compromiso;
      if(this.currentUser.nodo !=1 && this.currentUser.nodo != 10){
        delete dato.investigacion;
      }
      dato.registrationDate = moment.utc(datos[i].registrationDate.date).local().format("DD/MM/YYYY HH:mm");
      
      datos[i] = dato;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Centros');

    /* save to file */
    XLSX.writeFile(wb, "Centros_" + moment().format("DDMMYYYY-HHmm") + ".xlsx");
  }
  
  loadNodos() {
		console.log("loadNodos()");
		this.nodoService.getAll()
	    .subscribe(nodos => {
      for(var i = 0; i < nodos.length; i++) {
        this.nodos[nodos[i].id+""] = nodos[i];
      }
      function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }
      this.nodo = nodos.sort(compare);
			this.cdr.detectChanges();
		});
	}

  ngOnInit() {
    if(GlobalVariables.filters[this.router.url]) {
      this.filters = GlobalVariables.filters[this.router.url].filters;
      this.filtrosOpen = GlobalVariables.filters[this.router.url].open;
    } else {
      GlobalVariables.filters[this.router.url] = {
        filters: this.filters,
        open: this.filtrosOpen
      }
    }
    //this.loadCentros();
    this.currentUser = this.authenticationService.currentUserValue;
    if(this.currentUser.nodo) {
      this.loadCentrosNodo();
      this.getEdicionActiva();
    } else {
      this.loadCentros();
      this.loadNodos();
      this.getEdicionActiva();
    }
  }

  centro: any;

  baja(centro: any){
    this.centro = centro;
		this.bajaSwal.fire()
  }

  baja1(){
		var form = {id: this.centro.id, isActive: false, username: this.centro.username + "-" + moment().unix()}
		this.userService.update(form)
		.pipe(first())
		.subscribe(
			data => {
				console.log("OK", data);

        this.bajaConfirmSwal.fire();
        for(var i = 0; i < this.originalRows.length; i++) {
          if(this.originalRows[i].id == this.centro.id){
            this.originalRows.splice(i, 1);
            var datos = [...this.originalRows];
            this.originalRows = [];
            this.originalRows = datos;
            this.cdr.detectChanges();
            break;
          }
        }
        this.filterDatatable();
				//this.authenticationService.logout();
			},
			error => {
				console.error(error);
			});
    }
    
  // ------- FILTROS -------------

  filtrosOpen = false;

  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    GlobalVariables.filters[this.router.url].open = this.filtrosOpen;
    this.cdr.detectChanges();
  }

  filters = {
    nombre: null,
    email: null,
    estado: "TODOS",
    curso: "TODOS",
    nodoFil: "TODOS",
    edicion: "TODOS"
  }

  filterDatatable(){
      GlobalVariables.filters[this.router.url].filters = this.filters;
      GlobalVariables.listadoPagina = "centro";
      GlobalVariables.listado = this.originalRows;
    
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      if(!this.currentUser.nodo && this.nodos[item.nodo]){
        var nodo = this.nodos[item.nodo].name;
        
        if(this.filters.nodoFil != "TODOS" && nodo != this.filters.nodoFil) mostrar = false;
      }

      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.name.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.email && this.filters.email != null && this.filters.email != "") {
        if(!item.username.toLowerCase().includes(this.filters.email.toLowerCase())) mostrar = false;
      }

      if(this.filters.edicion != "TODOS" && item.edicion != this.filters.edicion) mostrar = false;

      if(this.filters.estado != "TODOS" && item.estado != this.filters.estado) mostrar = false;

      if(this.filters.curso != "TODOS" && item.curso != this.filters.curso) mostrar = false;

      return mostrar;
    }.bind(this));
      GlobalVariables.listado = this.rows;
    
  }

  resetFilters() {
    this.filters = {
      nombre: null,
      email: null,
      estado: "TODOS",
      curso: "TODOS",
      nodoFil: "TODOS",
      edicion: "TODOS"
    }
    this.filterDatatable();
  }

}
